






















import Vue from "vue";
import { Component } from "vue-property-decorator";
import { API, Auth } from 'aws-amplify';
import ConfirmEx from "@/components/ConfirmEx.vue";
import CustomLoading from "@/components/CustomLoading.vue";
import store from '@/store'
// import router from 'vue-router'

@Component({
  components: {
    ConfirmEx,
    CustomLoading,
  },
})
export default class Login extends Vue {
  isLoading = false;
  showPassword = false;
  dialog = true;
  loginInfo = {
    userId: "", 
    passWord: ""
  }
  // info = {}
  
  info = [
          {
            loginchrgdepcd: "", 
            loginchrgdepnm: "", 
            loginusercd: "", 
            loginusernm: "", 
            loginathryclsfy: ""
          }
        ];

  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get refs(): any {
    return this.$refs;
  }
  
  async created() {
    store.commit('loginUserEntry', this.info);
  }
  
  async login() {
    // 認証エラー時
    if(!this.loginInfo.userId || !this.loginInfo.passWord){
      if (!(await this.errMes("ログインエラー","ユーザー名、パスワードを入力してください。"))) {
        return;
      }
    }
    
    
    this.isLoading = true;
    console.log("loginXXXXXXXXXXXXXX");
    
    console.log("##### AUTH SINGIN #####");
    try {
      await Auth.signIn(this.$store.state.cognitouser, this.$store.state.cognitopass);
    } catch(err) {
      console.log(err);
      console.log("### AUTH ERROR ###");
    }
    
    try {
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
    
      // 検索
      apiparams.body.manipulate = "SYSLOGIN";
      apiparams.body.sqlparam = this.loginInfo;
      this.info = await API.post("asmapigateway", "/asmLogin", apiparams);
      console.log(this.info);
    } catch (err) {
      console.log("err:::::::::::::::::::::::::::");
      alert("ログインに失敗しました。再度ログインを実施してください");
      await Auth.signOut();
      window.location.assign(process.env.BASE_URL||"");
      console.log(err);
    }
    // await Auth.signOut();
    console.log("loginlengthXXXXXXXXXXXXX");
    console.log(this.info);
    console.log(this.info.length);
    // console.log(this.info[0]);
    // console.log(this.info[0].loginusernm);


    this.isLoading = false;
    
    console.log("loginCheckxxxxxxxxxxxxxxxx");
    // const loginCheck = this.$store.state.loginuser.loginusercd;
    if (this.info.length > 0 && this.info[0].loginusercd.length > 0){
      store.commit('loginUserEntry', this.info);
      this.$router.push('Home')
    } else {
      if (!(await this.errMes("ログインエラー","ユーザー名またはパスワードが異なるためログインできません。"))) {
        await Auth.signOut();
        return;
      }
    }
  }
  
  async onKeyEnterNext(){
    // event.target.nextSibling.focus();
  }
  async onKeyEnterLogin(){
    this.login();
  }
  
  async errMes(ttl: string,cnt: string): Promise<boolean> {
    await this.refs.confirm.open(
      ttl,
      cnt,
      {
        type: "alert",
        color: "red",
        width: 500,
      }
    );
    return false;
  }

}
