












































































import Vue from "vue";
import { Component, Prop, Watch} from "vue-property-decorator";
import StringBox from "@/components/controls/StringBox.vue";
import DropDownList from "@/components/controls/DropDownList.vue";
import { IManegmentParams } from "@/types/IManegmentParams";
import { IFusenParamsSelectList } from "@/types/IFusenParamsSelectList";
import ConfirmEx from "@/components/ConfirmEx.vue";

@Component({
  components: {
    StringBox,
    DropDownList,
    ConfirmEx,
  },
})
export default class ParamFusenEdit extends Vue {

  // パラメータ
  @Prop() fusenParam!: IManegmentParams;
  @Prop() selectList!: IFusenParamsSelectList;
  @Prop() mainRequired!: boolean;
  @Prop() title!: string;
  @Prop() color!: string;
  
  // タブタイプ
  tabType = "FUSEN";
  
  // 活性状態初期
  tgcolorcdReadonly = false;
  tgcolorReadonly = false;
  tgcdReadonly = false;
  tgshtnnmReadonly = false;
  tgnmReadonly = false;
  
  // 新規登録時、付箋色取得
  setTgColor: any = {tgcolorcd: "", tgcolor: ""};
  
  // 活性状態初期
  get refs(): any {
    return this.$refs;
  }
  
  // 新規登録時、付箋色取得
  @Watch("fusenParam.tgcolorcd")
  tgcolorcdChange(value: any) {

    console.log("value：" + value);
    this.setTgColor = this.selectList.fusensyokuList.find((v) => v.tgcolorcd === value)
    this.fusenParam.tgcolor = this.setTgColor.tgcolor;
  }
  
  // 画面ロード
  async created() {
    
    console.log("ParamFusenEdit：created");
    console.log(this.selectList);
    await this.initInfo();
  }
  
  async initInfo(){
    
    console.log("start:ParamFusenEdit_initInfo");
    // 活性状態制御
    if (this.color === "pink"){
      // 登録ダイアログ
      this.tgcolorcdReadonly = false;
      this.tgcolorReadonly = true;
      this.tgcdReadonly = false;
      this.tgshtnnmReadonly = false;
      this.tgnmReadonly = false;
    }
    else if(this.color === "light-blue"){
      // 変更ダイアログ
      this.tgcolorcdReadonly = true;
      this.tgcolorReadonly = true;
      this.tgcdReadonly = true;
      this.tgshtnnmReadonly = false;
      this.tgnmReadonly = false;
    }
    console.log("end:ParamFusenEdit_initInfo");
  }

  /**
   * 入力された値を返す
   */
  get localFusen(): IManegmentParams {
    
    return this.fusenParam;
  }

  // 登録キャンセルイベント
  async clickCancel(): Promise<void> {

    if (
      !(await this.refs.confirm.open("確認", "未登録の内容変更がある場合、情報が破棄されます。よろしいですか？", {
        width: 500,
      }))
    ) {
      return;
    }
    console.log("ParamFusenEdit：clickCancel()");
    this.$emit("dialog-cancel", this.tabType);
  }
  
  // 登録イベント
  clickOk() {
    this.$emit("dialog-ok", this.localFusen, this.tabType, this.color);
  }
}
