










































import Vue from "vue";
import { Component, Prop} from "vue-property-decorator";
import StringBox from "@/components/controls/StringBox.vue";
import { IManegmentParams } from "@/types/IManegmentParams";
import ConfirmEx from "@/components/ConfirmEx.vue";

@Component({
  components: {
    StringBox,
    ConfirmEx,
  },
})
export default class ParamUketukeEdit extends Vue {

  // パラメータ
  @Prop() uketukeParam!: IManegmentParams;
  @Prop() mainRequired!: boolean;
  @Prop() title!: string;
  @Prop() color!: string;
  
  // タブタイプ
  tabType = "UKETUKE";
  
  // 活性状態初期
  accptplcdReadonly = false;
  accptplnmReadonly = false;
  
  // 活性状態初期
  get refs(): any {
    return this.$refs;
  }

  // 画面ロード
  async created() {
    
    console.log("ParamUketukeEdit：created");
    await this.initInfo();
  }
  
  async initInfo(){
    
    console.log("start:ParamUketukeEdit_initInfo");
    // 活性状態制御
    if (this.color === "pink"){
      // 登録ダイアログ
      this.accptplcdReadonly = false;
      this.accptplnmReadonly = false;
    }
    else if(this.color === "light-blue"){
      // 変更ダイアログ
      this.accptplcdReadonly = true;
      this.accptplnmReadonly = false;
    }
    console.log("end:ParamUketukeEdit_initInfo");
  }

  /**
   * 入力された値を返す
   */
  get localUketuke(): IManegmentParams {

    return this.uketukeParam;
  }

  // 登録キャンセルイベント
  async clickCancel(): Promise<void> {
    
    if (
      !(await this.refs.confirm.open("確認", "未登録の内容変更がある場合、情報が破棄されます。よろしいですか？", {
        width: 500,
      }))
    ) {
      return;
    }
    console.log("ParamUketukeEdit：clickCancel()");
    this.$emit("dialog-cancel", this.tabType);
  }
  
  // 登録イベント
  clickOk() {
    this.$emit("dialog-ok", this.localUketuke, this.tabType, this.color);
  }
}
