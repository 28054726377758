















































import Vue from "vue";
import { Component, Watch, Prop, Emit } from "vue-property-decorator";
import { ITextValue } from "@/types/ITextValue";
import { IHeader } from "@/types/IHeader";
import { IMainParams } from "@/types/IMainParams";
import ConfirmEx from "@/components/ConfirmEx.vue";
import CustomLoading from "@/components/CustomLoading.vue";
import AsmVue from "@/views/AsmVue";
import { API, Auth } from 'aws-amplify';

@Component({
 components: {
   ConfirmEx }
})

export default class CopyRun extends AsmVue {
    
    
    isLoading = false;

    //パラメータ
    @Prop() mainParams!: IMainParams;
    @Prop() headers!: IHeader[];
    @Prop() items!: ITextValue[];
    @Prop() selected: any = [];

    get refs(): any {
        return this.$refs;
    }


    // キャンセル
    async clickCancel(): Promise<void> {
        
        this.$emit("dialog-close-click");
    } 

    //実行チェック
    async SaveCheck(): Promise<boolean> {

         // 選択チェック
        if (!Object.keys(this.selected).length) {
             await this.refs.confirm.open(
                "エラー",
                "最低でも1つチェックを入れてください。", 
                {
                type: "alert",
                color: "red",
                width: 500
                }
            );
            return false;
        }

        return true;
    }
    
    //実行
    async clickSave(): Promise<void> {
           
        //入力チェック
        if(!await this.SaveCheck()){
        return;
        }
        console.log(this.selected);
        console.log(this.selected.length);

        if ( !await this.refs.confirm.open(
            "確認", 
            "複写処理を実行します。よろしいですか？",
            {
                width: 500
            })
            ) {
            return;
            }
        
        //登録内容がが渡ってきたか確認用
        // alert(JSON.stringify(this.selected))
        // alert(JSON.stringify(this.mainParams))

        this.isLoading = true;
        
        const to = this.selected.length;
        let depcd;
        for (let i = 0; i < to; i++) {
            if(i === 0){
                depcd = this.selected[0].value;
            } else {
                depcd += "," + this.selected[i].value;
            }
        }
        console.log(depcd);

        //複写処理（パラメータ：this.selectedのvalueの値・複数あり、mainParamsの年度、管理番号）
        try {
          const apiparams = {
            body: {
              manipulate: "",
              sqlparam: {admstnb  :this.mainParams.admstnb,
                         chrgdepcd:depcd,
                         tyr      :this.mainParams.tyr
                        }
            }
          };
        
          // 検索
          apiparams.body.manipulate = "CopyInsert";
          console.log(apiparams);
          const rslt = await API.post("asmapigateway", "/asmMainPage", apiparams);
    
        } catch (e) {
        this.exception(e);
        return;
        }

        this.isLoading = false;

        // 複写完了
        await this.refs.confirm.open(
            "完了",
            "複写処理が完了しました。", 
            {
              type: "alert",
              width: 500
            }
          );
        
        // 選択をクリア
        this.selected = [];

        this.$emit("dialog-close-click");

    }

}

