









































import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";
import { IGengo } from "@/types/IGengo";

@Component
export default class DateJaBox extends Vue {
  isPicker = false;
  @Prop({ type: String, default: "" }) value!: string;
  @Prop({ type: String, default: "" }) label!: string;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop() gengo!: IGengo[];

  @Emit()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  input(value: string) {
    //
  }
  get localValue(): string {
    return this.value;
  }
  set localValue(value: string) {
    this.input(value);
  }

  get localGengo(): IGengo[] {
    return this.gengo;
  }

  backgroundcolor: string = this.color(this.required);
  ret!: string;
  color(required: boolean): string {
    if (required === true) {
      return (this.ret = "#FFF59D");
    } else {
      return (this.ret = "#FFFFFF");
    }
  }

  convertJaText(value: string): string {
    if (!value) {
      return "";
    }

    if (
      this.localGengo === undefined ||
      !this.localGengo ||
      !this.localGengo.length
    ) {
      return `不明(${value})`;
    }

    const [year, month, day] = value.split("-");
    const ymd = new Number(`${year}${month}${day}`);
    const result = this.localGengo.find(
      (v) => Number(v.start) <= ymd && ymd < Number(v.end)
    );
    if (!result) {
      return `不備(${value})`;
    }
    const startYear = Number(result.start).toString().substring(0, 4);
    const gengoNo = +year - +startYear + 1;
    const gengoText =
      gengoNo === 1
        ? "元"
        : gengoNo > 1 && gengoNo < 10
        ? " " + gengoNo.toString()
        : gengoNo.toString();
    const monthText =
      +month > 0 && +month < 10 ? " " + (+month).toString() : month;
    const dayText = +day > 0 && +day < 10 ? " " + (+day).toString() : day;
    return `${result.shortName}${gengoText}.${monthText}.${dayText}`;
  }
  clear() {
    this.localValue = "";
  }
}
