







































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import StringBox from "@/components/controls/StringBox.vue";
import ConfirmEx from "@/components/ConfirmEx.vue";
import { IOutputDataParams } from "@/types/IOutputDataParams";
import { IHeader } from "@/types/IHeader";
import DropDownList from "@/components/controls/DropDownList.vue";
import { IOutputDataSelectList } from "@/types/IOutputDataSelectList";
import DropDownListObject from "@/components/controls/DropDownListObject.vue";

@Component({
  components: {
    StringBox,
    ConfirmEx,
    DropDownList,
    DropDownListObject
  },
})

export default class OutputOrder extends Vue {

  // パラメータ
  @Prop() outputDataParams!: IOutputDataParams;
  @Prop() outputDataSelectList!: IOutputDataSelectList;
  // @Prop() mainRequired!: boolean;
  @Prop() title!: string;
  @Prop() color!: string;
  @Prop() bkOrderColumn1!: string;
  @Prop() bkOrderColumn2!: string;
  @Prop() bkOrderColumn3!: string;
  @Prop() bkOrderColumn4!: string;
  @Prop() bkOrderColumn5!: string;
  @Prop() bkOrderSort1!: string;
  @Prop() bkOrderSort2!: string;
  @Prop() bkOrderSort3!: string;
  @Prop() bkOrderSort4!: string;
  @Prop() bkOrderSort5!: string;

  // 活性状態初期
  sansyouDisable = false;

  // //v-select用
  // outputDataSelectList: IOutputDataSelectList = {
  //   tableList: [],
  //   selectColums: [],
  //   whereConditions: [],
  //   whereAndors: [],
  //   orderSorts: []
  // };
  
  /**
   * refsの定義
   */
  get refs(): any {
    return this.$refs;
  }

  // 画面ロード
  async created() {

    console.log("OutputOrder：created");
    await this.initInfo();
  }

  initInfo() {

    console.log("start:OutputOrder_initInfo");
    // 活性状態制御
    if (this.color === "pink") {
      // 登録ダイアログ
      this.sansyouDisable = true;
      // this.outputHeaders = [
      //   { text: '抽出項目', align: 'start', sortable: false, value: 'param' },
      // ]
      // this.outputDataParams.tyusyutukoumoku = [
      //   { param: '年度' },
      //   { param: '管理番号' },
      //   { param: '要件区分' },
      //   { param: '受付年月日' },
      //   { param: '受付番号' },
      //   { param: '受付場所' },
      //   { param: '行政団体・行政区' },
      // ]
      
      this.outputDataSelectList.orderSorts = [
          { text: '', value: '' },
          { text: '昇順', value: 'asc' },
          { text: '降順', value: 'desc' }
        ]
    }
    
    
  
    console.log("end:OutputOrder_initInfo");
  }

/**
 * 入力された値を返す
 */
get localOutputItem(): IOutputDataParams {

  return this.outputDataParams;
}

  /**
   * 入力された値を返す
   */
  // get localNendo(): IManegmentParams {

  //   // 最新年度サインの制御、true = "1"とみなす
  //   if (this.nendoParam.ltsttyrsgn){
  //     console.log("nendoParam.ltsttyrsgn：true");
  //     this.nendoParam.ltsttyrsgn = "1";
  //   }
  //   else {
  //     console.log("nendoParam.ltsttyrsgn：false");
  //     this.nendoParam.ltsttyrsgn = null;
  //   }
  //   return this.nendoParam;
  // }

  // 登録キャンセルイベント
  async clickCancel(): Promise < void > {

    let flg = true; 
    if( this.outputDataParams.orderColumn1 != this.bkOrderColumn1 ||
        this.outputDataParams.orderColumn2 != this.bkOrderColumn2 ||
        this.outputDataParams.orderColumn3 != this.bkOrderColumn3 ||
        this.outputDataParams.orderColumn4 != this.bkOrderColumn4 ||
        this.outputDataParams.orderColumn5 != this.bkOrderColumn5 ||
        this.outputDataParams.orderSort1 != this.bkOrderSort1 ||
        this.outputDataParams.orderSort2 != this.bkOrderSort2 ||
        this.outputDataParams.orderSort3 != this.bkOrderSort3 ||
        this.outputDataParams.orderSort4 != this.bkOrderSort4 ||
        this.outputDataParams.orderSort5 != this.bkOrderSort5)
    {
    flg = false;
    }
    if(!flg){
      if (!(await this.refs.confirm.open("確認", "未設定の内容変更がある場合、情報が破棄されます。よろしいですか？（OK/キャンセル）", {
          width: 500,
        }))) {
        return;
      } else {
          this.outputDataParams.orderColumn1 = this.bkOrderColumn1;
          this.outputDataParams.orderColumn2 = this.bkOrderColumn2;
          this.outputDataParams.orderColumn3 = this.bkOrderColumn3;
          this.outputDataParams.orderColumn4 = this.bkOrderColumn4;
          this.outputDataParams.orderColumn5 = this.bkOrderColumn5;
          this.outputDataParams.orderSort1 = this.bkOrderSort1;
          this.outputDataParams.orderSort2 = this.bkOrderSort2;
          this.outputDataParams.orderSort3 = this.bkOrderSort3;
          this.outputDataParams.orderSort4 = this.bkOrderSort4;
          this.outputDataParams.orderSort5 = this.bkOrderSort5;
      }
    }
    console.log("OutputOrder：clickCancel()");
    this.$emit("dialog-cancel");
  }

  // 設定イベント
  async clickOk() {
    
    if (!(await this.okcheck())) {
      return;
    }
    
    console.log("OutputOrder：clickOk()");
    this.$emit("dialog-ok-order", this.localOutputItem);
  }
  
  
  async okcheck(): Promise<boolean> {
    if (this.outputDataParams.orderColumn1 == "" &&
        this.outputDataParams.orderColumn2 == "" &&
        this.outputDataParams.orderColumn3 == "" &&
        this.outputDataParams.orderColumn4 == "" &&
        this.outputDataParams.orderColumn5 == "" &&
        this.outputDataParams.orderSort1 == "" &&
        this.outputDataParams.orderSort2 == "" &&
        this.outputDataParams.orderSort3 == "" &&
        this.outputDataParams.orderSort4 == "" &&
        this.outputDataParams.orderSort5 == "")
    {
      await this.refs.confirm.open(
        "エラー",
        "最低でも1つの条件を指定してください。",
        {
          type: "alert",
          color: "red",
          width: 500,
        }
      );
      return false;
    }
    
    let okcheckflg = true;
    if ((this.outputDataParams.orderColumn1 != "" && this.outputDataParams.orderSort1 == "") ||
        (this.outputDataParams.orderColumn1 == "" && this.outputDataParams.orderSort1 != "")){
      okcheckflg = false;
    }
    if ((this.outputDataParams.orderColumn2 != "" && this.outputDataParams.orderSort2 == "") ||
        (this.outputDataParams.orderColumn2 == "" && this.outputDataParams.orderSort2 != "")){
      okcheckflg = false;
    }
    if ((this.outputDataParams.orderColumn3 != "" && this.outputDataParams.orderSort3 == "") ||
        (this.outputDataParams.orderColumn3 == "" && this.outputDataParams.orderSort3 != "")){
      okcheckflg = false;
    }
    if ((this.outputDataParams.orderColumn4 != "" && this.outputDataParams.orderSort4 == "") ||
        (this.outputDataParams.orderColumn4 == "" && this.outputDataParams.orderSort4 != "")){
      okcheckflg = false;
    }
    if ((this.outputDataParams.orderColumn5 != "" && this.outputDataParams.orderSort5 == "") ||
        (this.outputDataParams.orderColumn5 == "" && this.outputDataParams.orderSort5 != "")){
      okcheckflg = false;
    }
    
    if (!okcheckflg) {
      await this.refs.confirm.open(
        "エラー",
        "条件を見直してください。",
        {
          type: "alert",
          color: "red",
          width: 500,
        }
      );
      return false;
    }
    return true;
  }
  
  
  
}
