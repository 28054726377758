<template> 
  <Map /> 
</template> 

<script> 
  import Map from '../components/Map'  
  export default { 
    components: { 
      Map 
    } 
  } 
</script>