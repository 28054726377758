


































import Vue from "vue";
import { Component, Prop, Emit, Watch } from "vue-property-decorator";
import ControlBaseVue from "@/components/controls/base/ControlBaseVue";
import { ITextValue2 } from "@/types/ITextValue2";


@Component
export default class FusenBox extends ControlBaseVue {
  selected = this.localValue;
  text2 = this.selectedFusenName(this.localValue);

  @Prop() fusenitems!: ITextValue2[];
  @Prop() fusencolor!: string;

  // tslint:disable-next-line:typedef
  @Emit()
  input(value: string) {
    console.log(value);
  }

  private get localValue(): string {
    return this.value;
  }

  private set localValue(value: string) {
    this.input(value);
  }

  backgroundcolor: string = this.setcolor(this.required);
  ret!: string;
  setcolor(required: boolean): string {
    if (required === true) {
      return (this.ret = "#FFF59D");
    } else {
      return (this.ret = "#FFFFFF");
    }
  }

  tagcolor: string = this.tagsetcolor(this.fusencolor);
  rettag!: string;
  tagsetcolor(fusencolor: string): string {
    if (fusencolor === "red") {
      return (this.rettag = "#FF0000");
    } else if (fusencolor === "yellow") {
      return (this.rettag = "#FFFF00");
    } else if (fusencolor === "blue") {
      return (this.rettag = "#0000FF");
    } else if (fusencolor === "orange") {
      return (this.rettag = "#FFA500");
    } else if (fusencolor === "purple") {
      return (this.rettag = "#800080");
    } else {
      return (this.rettag = "#FFFFFF");
    }
  }

  //画面ロード
  async mounted(): Promise<void> {
    // 名称取得
    this.selectedChange(this.value);

    // リスト先頭に空欄を追加
    const kuhaku: ITextValue2 = {value:"",text:"",text2:""};
    this.fusenitems.unshift( kuhaku);
  }

  @Watch("selected")
  // tslint:disable-next-line:typedef
  selectedChange(value: string) {
    this.localValue = value;
    this.text2 = this.selectedFusenName(value);

    this.$emit("dialog-fusen-select",value);
  }

  selectedFusenName(value: string): string {
    const selected = this.fusenitems.find((v) => v.value === value);
    if (!selected) {
      return "";
    }
    return selected.text2 || "";
  }

  
}
