


















































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Component, Watch, Prop, Emit } from "vue-property-decorator";
import { ITextValue } from "@/types/ITextValue";
import { IMainSelectList } from "@/types/IMainSelectList";
import { IFusenSelectList } from "@/types/IFusenSelectList";
import { IMainParams } from "@/types/IMainParams";
import { IFusenParams } from "@/types/IFusenParams";
import { IImageItem } from "@/types/IImageItem";
import { IMainDisabled } from "@/types/IMainDisabled";
import { IHeader } from "@/types/IHeader";
import { IGengo } from "@/types/IGengo";
import DropDownList from "@/components/controls/DropDownList.vue";
import StringBox from "@/components/controls/StringBox.vue";
import NumberBox from "@/components/controls/NumberBox.vue";
import DateJaBox from "@/components/controls/DateJaBox.vue";
import MultiBox from "@/components/controls/MultiBox.vue";
import MultiSelectBox from "@/components/controls/MultiSelectBox.vue";
import ConfirmEx from "@/components/ConfirmEx.vue";
import FusenEdit from "@/components/FusenEdit.vue";
import ImageEdit from "@/components/ImageEdit.vue";
import MapDisplay from "@/components/MapDisplay.vue";
import CopyRun from "@/components/CopyRun.vue";
import HistoryDisplay from "@/components/HistoryDisplay.vue";
import AsmVue from "@/views/AsmVue";
import { Storage, API, Auth } from 'aws-amplify';

@Component({
  components: {
    DropDownList,
    StringBox,
    NumberBox,
    DateJaBox,
    MultiBox,
    MultiSelectBox,
    ConfirmEx,
    FusenEdit,
    ImageEdit,
    MapDisplay,
    CopyRun,
    HistoryDisplay,
  },
})
export default class MainPageEdit extends AsmVue {
  isdatereadonly = true;
  chips = true;
  fusendialog = false;
  imagedialog = false;
  mapDisplaydialog = false;
  copyRundialog = false;
  historyDisplaydialog = false;
  showfusen = false;
  showimage = false;

  //パラメータ
  @Prop() title!: string;
  @Prop() color!: string;
  @Prop() loginchrgdepcdauth!: string;
  @Prop() mainParams!: IMainParams;
  @Prop() mainDisabled!: IMainDisabled;
  @Prop() mainRequired!: boolean;
  @Prop() chrgdepcdRequired!: boolean;
  @Prop() selectList!: IMainSelectList;
  @Prop() fusenselectList!: IFusenSelectList;
  @Prop() gengos!: IGengo[];

  //付箋画面
  tgredsplmnsnDisabled = false;
  fusenParams: IFusenParams = {
    tyr: "",
    admstnb: "",
    tgredcd: "",
    tgredname: "",
    tgyllwcd: "",
    tgyllwname: "",
    tgblucd: "",
    tgbluname: "",
    tgorngcd: "",
    tgorngname: "",
    tgprplcd: "",
    tgprplname: "",
    tgredsplmnsn: "",
  };

  //画像画面
  imageitems!: IImageItem[];

  selected: any = [];
  //複写画面 v-data-table用
  copyheaders!: IHeader[];
  copyitems!: ITextValue[];

  //更新履歴画面 v-data-table用
  historyheaders!: IHeader[];
  historyitems!: IMainParams[];

  get refs(): any {
    return this.$refs;
  }
  
  async created() {
    window.addEventListener('beforeunload', this.removepage);
  }
  
  // async mounted(){
  //   console.log('mounted!!!!!!!!!!!!!!!!!!!')
  //   const rmbucket = this.mainParams.admstnb + '/';
  //   Storage.list(rmbucket) // for listing ALL files without prefix, pass '' instead
  //   .then(result => {
  //     console.log('imagedelete!!!!!!!!!!!!!!!!!');
  //     console.log(result);
      
  //     const imacnt = result.length;
  //     for(let i = 0 ; i < imacnt ; i++){
  //       const deletekey = result[i].key;
  //       Storage.remove(deletekey).then(result => {
  //         console.log(result);
  //       }).catch(err => console.log(err));
  //     }
      
  //     Storage.remove(rmbucket).then(result => {
  //       console.log(result);
  //     }).catch(err => console.log(err));

      
      
      
  //   })
  //   .catch(err => console.log(err));
  // }
  
  async removepage(){
    
    const nb = this.mainParams.admstnb;
    // await this.imagedelete(nb);
    await this.datadelete();

  } 

  //管理番号取得
  async clickadmstnb() {
    this.$emit("dialog-admstnb-click");
    // this.checkadmstnb();
  }
  
  //管理番号フォーカスアウト時
  async checkadmstnb() {
    // 入力された管理番号がDBに存在しないかチェック
    try {
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
      apiparams.body.manipulate = "CheckAdmstnb";
      apiparams.body.sqlparam = this.mainParams;
      console.log(apiparams);
      const rslt = await API.post("asmapigateway", "/asmMainPage", apiparams);
      
      console.log(rslt);
      //年度が空欄の場合エラー
      if(rslt[0].cnt > 0 ){
        await this.refs.confirm.open(
            "重複エラー",
            "既に登録された管理番号です。", 
            {
              type: "alert",
              color: "red",
              width: 500
            }
          );
        return false;
      }

    } catch (e) {
        this.exception(e);
        return;
    }
  
  }

  //計算チェック
  async kanswerdysCheck(): Promise<boolean> {
    //受付年月日または回答年月日が空欄の場合エラー
    if (this.mainParams.accptymdt === "" || this.mainParams.answerymdt === "") {
      await this.refs.confirm.open(
        "エラー",
        "受付年月日、回答年月日を入力してください。",
        {
          type: "alert",
          color: "red",
          width: 500,
        }
      );
      return false;
    }
    return true;
  }
  //回答日数 計算
  async clickanswerdys() {
    //入力チェック
    if (!(await this.kanswerdysCheck())) {
      return;
    }

    //日数計算
    //this.mainParams.answerdys = this.mainParams.answerymdt - this.mainParams.accptymdt
    const date1 = new Date(this.mainParams.answerymdt ?? "");
    const date2 = new Date(this.mainParams.accptymdt ?? "");

    const diffTime = date1.getTime() - date2.getTime();
    const diffDay = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    this.mainParams.answerdys = diffDay.toString();
  }

  //入力チェック
  async Check(): Promise<boolean> {
    //年度、管理番号が空欄の場合エラー
    if (this.mainParams.tyr === ""||this.mainParams.admstnb === "") {
      await this.refs.confirm.open("エラー", "年度、管理番号を入力してください。", {
        type: "alert",
        color: "red",
        width: 500,
      });
      return false;
    }
    return true;
  }

  //付箋
  async clickTag() {
    //入力チェック
    if (!(await this.Check())) {
      return;
    }

    //新規
    if (this.mainParams.updateflg === "I") {
      //付箋ダイアログ用にセット
      this.fusenParams = {
        tyr: this.mainParams.tyr,
        admstnb: this.mainParams.admstnb,
      };

      //入力制御
      this.tgredsplmnsnDisabled = true;
    }
    //修正
    else {
      //付箋ダイアログ用にセット
      this.fusenParams = {
        tyr: this.mainParams.tyr,
        admstnb: this.mainParams.admstnb,
        tgredcd: this.mainParams.tgredcd,
        tgredname: this.mainParams.tgredname,
        tgyllwcd: this.mainParams.tgyllwcd,
        tgyllwname: this.mainParams.tgyllwname,
        tgblucd: this.mainParams.tgblucd,
        tgbluname: this.mainParams.tgbluname,
        tgorngcd: this.mainParams.tgorngcd,
        tgorngname: this.mainParams.tgorngname,
        tgprplcd: this.mainParams.tgprplcd,
        tgprplname: this.mainParams.tgprplname,
        tgredsplmnsn: this.mainParams.tgredsplmnsn,
      };

      //入力制御
      //赤付箋なし
      if (this.mainParams.tgredcd === "") {
        this.mainParams.tgredsplmnsn = "";
        this.tgredsplmnsnDisabled = true;
      } else {
        this.tgredsplmnsnDisabled = false;
      }
    }

    //再描画
    this.showfusen = false;
    this.$nextTick(() => (this.showfusen = true));

    //付箋ダイアログ表示
    this.fusendialog = true;
  }

  //画像
  async clickImage() {
    //入力チェック
    if (!(await this.Check())) {
      return;
    }

    //画像情報取得
    try {
      const apiparams = {
        body: {
          manipulate: "",
          modeflg: "",
          sqlparam: {}
        }
      };

      apiparams.body.manipulate = "SELECTIMAGE";
      console.log('this.editItemxxxxxxxxxxx');
      console.log(apiparams.body);
      apiparams.body.sqlparam = this.mainParams;
      this.imageitems = await API.post("asmapigateway", "/asmMainPage", apiparams);
      // const imagetest = await API.post("asmapigateway", "/asmMainPage", apiparams);
      
      console.log(this.imageitems.length);
      console.log('this.imageitemsXXXXXXXXXXXXXXXX');
      
      // 情報セット
      const cnt = this.imageitems.length;
      for(let i = 0 ; i < cnt ; i++ ){
        console.log(i);
        const s3pass = this.mainParams.admstnb + '/thumbnail/' + this.imageitems[i].image;
        
        if(s3pass !== undefined){
        
          // const s3key = this.kanriNo + '/' + this.files.name;
          Storage.get(s3pass, { download: false }).then(res => {
          this.imageitems[i].image = String(res);
          }).catch(err => console.log(err));
          
        }
        
        console.log(this.imageitems[i]);
      }
      
      
    } catch (e) {
      this.exception(e);
      return;
    }

    //再描画
    this.showimage = false;
    this.$nextTick(() => (this.showimage = true));

    //画像ダイアログ表示
    this.imagedialog = true;
  }

  //地図表示
  async clickMap() {
    //入力チェック
    if (!(await this.Check())) {
      return;
    }

    //地図情報取得
    try {
      // const res = await axios.post(
      //   `${process.env.VUE_APP_API_URL}/xxxxxx.json`,
      // {
      //     key1:this.mainParams
      // },
      //   {
      //     headers: {
      //       Authorization: `Bearer ${store.state.keycloak.token}`
      //     }
      //   }
      // );
      //
      // // 情報セット
      //this.xxxxx = res.data;
    } catch (e) {
      this.exception(e);
      return;
    }

    //地図表示ダイアログ表示
    this.mapDisplaydialog = true;
  }

  //複写
  async clickCopy() {
    //担当課情報取得
    this.copyheaders = [{ text: "担当課", value: "text", align: "left" }];

    /**
     * ここでAPIを呼び出し、データを下記項目の形式で取得する
     */
    try {
      // const res = await axios.get(
      //   `${process.env.VUE_APP_API_URL}/xxxxxx.json`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${store.state.keycloak.token}`
      //     }
      //   }
      // );
      // //
      // this.copyitems = res.data;
      this.copyitems = this.selectList.chrgdepcditems;
      // this.copyitems = [selectList.chrgdepcditems
      //   { value: "0001", text: "市民そうだん課" },
      //   { value: "0002", text: "交通対策課" },
      //   { value: "0003", text: "XXXXXXX課" },
      //   { value: "0004", text: "XXXXXXX課" },
      //   { value: "0005", text: "XXXXXXX課" },
      //   { value: "0006", text: "XXXXXXX課" },
      //   { value: "0007", text: "XXXXXXX課" },
      //   { value: "0008", text: "XXXXXXX課" },
      //   { value: "0009", text: "XXXXXXX課" },
      //   { value: "0010", text: "XXXXXXX課" },
      //   { value: "0011", text: "XXXXXXX課" },
      // ];
    } catch (e) {
      this.exception(e);
      return;
    }

    this.selected = [];

    //複写ダイアログ表示
    this.copyRundialog = true;
  }

  //更新履歴
  async clickHistory() {
    //更新履歴情報取得
    try {
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {admstnb:this.mainParams.admstnb}
        }
      };
    
      // 検索
      apiparams.body.manipulate = "SelectRirekiSearch";
      console.log(apiparams);
      const rslt = await API.post("asmapigateway", "/asmMainPage", apiparams);
      // // ヘッダー情報セット
      //this.historyheaders = res.data.headers;
      // // データセット
      //this.historyitems = res.data.Data;

      this.historyheaders = [
        { text: "年度", value: "tyr", align: "left", width: 100 },
        { text: "管理番号", value: "admstnb", align: "left", width: 100 },
        { text: "要件区分", value: "cndtcd", align: "left", width: 100 },
        { text: "受付年月日", value: "accptymdt", align: "left", width: 100 },
        { text: "受付番号", value: "accptnb", align: "left", width: 100 },
        { text: "受付場所", value: "accptplcd", align: "left", width: 200 },
        { text: "行政区・団体", value: "admar", align: "left", width: 200 },
        { text: "代表者", value: "rprsnone", align: "left", width: 200 },
        { text: "肩書", value: "pstnm", align: "left", width: 150 },
        { text: "件名", value: "pttntitle", align: "left", width: 250 },
        { text: "内容", value: "pttncont", align: "left", width: 250 },
        { text: "採択等", value: "adptcd", align: "left", width: 100 },
        { text: "種別", value: "kndscd", align: "left", width: 100 },
        { text: "担当課", value: "chrgdepcd", align: "left", width: 100 },
        { text: "見積事業費", value: "estmtsep", align: "right", width: 100 },
        { text: "全体事業費", value: "whlep", align: "right", width: 100 },
        { text: "実績事業費", value: "actvtep", align: "right", width: 100 },
        { text: "進捗率", value: "prgrssrate", align: "left", width: 100 },
        { text: "進捗状況", value: "prgrssapprccd", align: "left", width: 100 },
        { text: "着工年月日", value: "crrpdymdt", align: "left", width: 100 },
        { text: "取下年月日", value: "abandymdt", align: "left", width: 100 },
        { text: "工種別", value: "worktyp", align: "left", width: 100 },
        { text: "完了年月日", value: "cptymdt", align: "left", width: 100 },
        { text: "回答年月日", value: "answerymdt", align: "left", width: 100 },
        { text: "回答方法", value: "answermthdcd", align: "left", width: 100 },
        { text: "回答日数", value: "answerdys", align: "left", width: 100 },
        {
          text: "備考（担当課用）",
          value: "chrgrmrks",
          align: "left",
          width: 250,
        },
        {
          text: "備考（行政センター用）",
          value: "othsrmrks",
          align: "left",
          width: 250,
        },
        { text: "集計対象外", value: "ttlgotsdflg", align: "left", width: 100 },
        { text: "更新月日", value: "supdtdttm", align: "left", width: 100 },
        { text: "更新課", value: "supdtdep", align: "left", width: 150 },
        { text: "更新者", value: "supdtnm", align: "left", width: 150 },
        { text: "ＳＥＱ", value: "SEQ", align: "left", width: 100 },
        { text: "更新状態", value: "updtst", align: "left", width: 100 },
      ]
      
      this.historyitems = rslt;
    } catch (e) {
      this.exception(e);
      return;
    }

    this.selected = [];

    //更新履歴ダイアログ表示
    this.historyDisplaydialog = true;
    //alert("更新履歴ダイアログ表示")
  }

  // キャンセル
  async clickCancel(): Promise<void> {

    // if(this.mainParams.updateflg==="U"){
    
    if(this.mainParams.updateflg !="S"){
      if (
        !(await this.refs.confirm.open(
          "確認",
          "未登録の内容変更がある場合、情報が破棄されます。よろしいですか？",
          {
            width: 500,
          }
        ))
      ) {
        return;
      }
    }
    
    this.datadelete();
    // if(this.mainParams.updateflg==="I" && this.mainParams.admstnb){
    //   try {
    //     const apiparams = {
    //       body: {
    //         manipulate: "",
    //         sqlparam: {}
    //       }
    //     };
    //     apiparams.body.manipulate = "DeleteMast";
    //     apiparams.body.sqlparam = this.mainParams;
    //     console.log(apiparams);
    //     const damy = await API.post("asmapigateway", "/asmMainPage", apiparams);
        
    //   } catch (e) {
    //       this.exception(e);
    //       return;
    //   }
    // }
    this.$emit("dialog-cancel-click", this.mainParams);
  }
  
  async datadelete(): Promise<void> {
    // 新規登録中にキャンセル、べーじ移動があった場合にデータを削除する
    if(this.mainParams.updateflg==="I" && this.mainParams.admstnb){
      try {
        const apiparams = {
          body: {
            manipulate: "",
            sqlparam: {}
          }
        };
        apiparams.body.manipulate = "DeleteMast";
        apiparams.body.sqlparam = this.mainParams;
        console.log(apiparams);
        const damy = await API.post("asmapigateway", "/asmMainPage", apiparams);

          
        // const rmbucket = this.mainParams.admstnb + '/';
        // Storage.list(rmbucket) // for listing ALL files without prefix, pass '' instead
        //   .then(result => {
        //     console.log(result);
        //     const imacnt = result.length;
        //     for(let i = 0 ; i < imacnt ; i++) {
        //       if(result[i].key !== undefined){
              
        //         const path = result[i].key;
        //         Storage.remove(path).then(result => {
        //           console.log(result);
        //         }).catch(err => console.log(err));
        //       }
        //     }
            
        //   })
        //   .catch(err => console.log(err));
      } catch (e) {
          this.exception(e);
          return;                                                 
      }
    }
  }
    
  // async imagedelete(nb: any){
    
  //   const rmbucket = nb + '/';
  //   Storage.list(rmbucket) // for listing ALL files without prefix, pass '' instead
  //   .then(result => {
  //     console.log('imagedelete!!!!!!!!!!!!!!!!!');
  //     console.log(result);
      
  //     const imacnt = result.length;
  //     for(let i = 0 ; i < imacnt ; i++){
  //       const deletekey = result[i].key;
  //       Storage.remove(deletekey).then(result => {
  //         console.log(result);
  //       }).catch(err => console.log(err));
  //     }
      
  //     Storage.remove(rmbucket).then(result => {
  //       console.log(result);
  //     }).catch(err => console.log(err));

  //   })
  //   .catch(err => console.log(err));

    
  // }
    
  

  // 削除
  async clickDel(): Promise<void> {
    if (
      !(await this.refs.confirm.open("確認", "削除します。よろしいですか？", {
        width: 500,
      }))
    ) {
      return;
    }

    this.mainParams.updateflg = "D";

    this.$emit("dialog-delete-click", this.mainParams);
  }

  //登録チェック
  async SaveCheck(): Promise<boolean> {
    //必須項目の場合エラー
    if (
      this.loginchrgdepcdauth === "0" ||
      this.loginchrgdepcdauth === "1" ||
      this.loginchrgdepcdauth === "2"
    ) {
      if (
        this.mainParams.tyr === "" ||
        this.mainParams.admstnb === "" ||
        this.mainParams.cndtcd === "" ||
        this.mainParams.chrgdepcd?.length === 0 ||
        this.mainParams.accptplcd === "" ||
        this.mainParams.accptymdt === "" ||
        this.mainParams.kndscd === "" ||
        this.mainParams.prgrssapprccd === ""
      ) {
        await this.refs.confirm.open("エラー", "必須項目を入力してください。", {
          type: "alert",
          color: "red",
          width: 500,
        });
        return false;
      }
    } else {
      if (
        this.mainParams.tyr === "" ||
        this.mainParams.admstnb === "" ||
        this.mainParams.cndtcd === "" ||
        this.mainParams.accptplcd === "" ||
        this.mainParams.accptymdt === "" ||
        this.mainParams.kndscd === "" ||
        this.mainParams.prgrssapprccd === ""
      ) {
        await this.refs.confirm.open("エラー", "必須項目を入力してください。", {
          type: "alert",
          color: "red",
          width: 500,
        });
        return false;
      }
    }

    //管理番号9桁以外エラー
    if (this.mainParams.admstnb != "" && this.mainParams.admstnb?.length != 9) {
      await this.refs.confirm.open(
        "エラー",
        "管理番号は9桁で入力してください。",
        {
          type: "alert",
          color: "red",
          width: 500,
        }
      );
      return false;
    }

    //管理番号、受付番号、回答日数半角数値以外エラー
    if (
      (this.mainParams.admstnb != "" &&
        this.mainParams.admstnb?.match(/[^0-9]/g)) ||
      (this.mainParams.accptnb != "" &&
        this.mainParams.accptnb?.match(/[^0-9]/g)) ||
      (this.mainParams.answerdys != "" &&
        this.mainParams.answerdys?.match(/[^0-9]/g))
    ) {
      await this.refs.confirm.open(
        "エラー",
        "管理番号、受付番号、回答日数は半角数値のみ入力できます。",
        {
          type: "alert",
          color: "red",
          width: 500,
        }
      );
      return false;
    }

    return true;
  }

  // 登録
  async clickSave(): Promise<void> {
    //入力チェック
    if (!(await this.SaveCheck())) {
      return;
    }

    if (
      !(await this.refs.confirm.open("確認", "登録します。よろしいですか？", {
        width: 500,
      }))
    ) {
      return;
    }

    //登録内容がが渡ってきたか確認用
    //alert(JSON.stringify(this.mainParams))

    this.$emit("dialog-save-click", this.mainParams);
  }

  //*********************************************
  // 付箋画面
  // *********************************************

  //キャンセル
  async clickFusenCancel(): Promise<void> {
    //ダイアログクローズ処理
    this.closefusen();
  }

  //登録
  async clickFusenSave(Params: IFusenParams): Promise<void> {
    //新規/修正ダイアログに戻す
    this.mainParams.tgredcd = Params.tgredcd;
    this.mainParams.tgredname = Params.tgredname;
    this.mainParams.tgyllwcd = Params.tgyllwcd;
    this.mainParams.tgyllwname = Params.tgyllwname;
    this.mainParams.tgblucd = Params.tgblucd;
    this.mainParams.tgbluname = Params.tgbluname;
    this.mainParams.tgorngcd = Params.tgorngcd;
    this.mainParams.tgorngname = Params.tgorngname;
    this.mainParams.tgprplcd = Params.tgprplcd;
    this.mainParams.tgprplname = Params.tgprplname;
    this.mainParams.tgredsplmnsn = Params.tgredsplmnsn;

    // 付箋活性状態設定
    if(this.mainParams.tgredcd!=""){ 
      this.mainDisabled.tgredcdDisabled = true;
    }
    else{
      this.mainDisabled.tgredcdDisabled = false;
    }
    if(this.mainParams.tgyllwcd!=""){ 
      this.mainDisabled.tgyllwcdDisabled = true;
    }
    else{
      this.mainDisabled.tgyllwcdDisabled = false;
    }
    if(this.mainParams.tgblucd!=""){ 
      this.mainDisabled.tgblucdDisabled = true;
    }
    else{
      this.mainDisabled.tgblucdDisabled = false;
    }
    if(this.mainParams.tgorngcd!=""){ 
      this.mainDisabled.tgorngcdDisabled = true;
    }
    else{
      this.mainDisabled.tgorngcdDisabled = false;
    }
    if(this.mainParams.tgprplcd!=""){ 
      this.mainDisabled.tgprplcdDisabled = true;
    }
    else{
      this.mainDisabled.tgprplcdDisabled = false;
    }

    // 登録完了
        await this.refs.confirm.open(
            "完了",
            "登録が完了しました。", 
            {
              type: "alert",
              width: 500
            }
          );
    
    //付箋表示
    if(this.mainParams.tgredsplmnsn !=""){
       await this.refs.confirm.open(
            "付箋特記事項",
            this.mainParams.tgredsplmnsn, 
            {
              type: "alert",
              width: 500
            }
          );
    }

    //ダイアログクローズ処理
    this.closefusen();
  }

  //ダイアログクローズ処理
  closefusen() {
    this.fusendialog = false;
    this.$nextTick(() => {
      this.fusenParams = Object.assign(
        {},
        {
          tyr: "",
          admstnb: "",
          tgredcd: "",
          tgredname: "",
          tgyllwcd: "",
          tgyllwname: "",
          tgblucd: "",
          tgbluname: "",
          tgorngcd: "",
          tgorngname: "",
          tgprplcd: "",
          tgprplname: "",
          tgredsplmnsn: "",
        }
      );
    });
  }

  //*********************************************
  // 画像画面
  // *********************************************

  //キャンセル
  async clickImageCancel(): Promise<void> {
    this.$nextTick(() => {
      this.imageitems = Object.assign([], []);
    });
    this.imagedialog = false;
  }

  //決定
  async clickImagSave(): Promise<void> {
    this.imagedialog = false;
  }

  //*********************************************
  // 地図表示画面
  // *********************************************

  //閉じる
  async clickMapDisplayClose(): Promise<void> {
    this.mapDisplaydialog = false;
  }

  //*********************************************
  // 複写実行画面
  // *********************************************

  //閉じる
  async clickCopyRunClose(): Promise<void> {
    this.copyRundialog = false;
  }

  //*********************************************
  // 更新履歴確認画面
  // *********************************************

  //キャンセル
  async clickHistoryDisplayCancel(): Promise<void> {
    this.historyDisplaydialog = false;
  }

  //登録
  async clickhistoryDisplaySave(): Promise<void> {
    this.historyDisplaydialog = false;
  }
}
