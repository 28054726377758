
















import Vue from "vue";
import { Component, Prop, Emit, Watch } from "vue-property-decorator";
import { ITextValue } from "@/types/ITextValue";

@Component
export default class MultiSelectBox extends Vue {
  selected: string[] = [];

  @Prop() value!: string[];
  @Prop({ type: String, default: "" }) label!: string;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop() items!: ITextValue[];

  @Emit()
  input(value: string[]) {
    //
  }

  get localValue(): string[] {
    return this.value;
  }

  set localValue(value: string[]) {
    this.input(value);
  }

  backgroundcolor: string = this.color(this.required);
  ret!: string;
  color(required: boolean): string{
    if (required === true ) {
      return this.ret = "#FFF59D";
    }
    else{
      return this.ret = "#FFFFFF";
    }
  }
}
