




























































































































































































































































































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { API, graphqlOperation, Auth } from 'aws-amplify';
// import DropDownListTwo from "@/components/controls/DropDownListTwo.vue";
import DropDownList from "@/components/controls/DropDownList.vue";
import StringBox from "@/components/controls/StringBox.vue";
import NumberBox from "@/components/controls/NumberBox.vue";
import ConfirmEx from "@/components/ConfirmEx.vue";
import DateJaBox from "@/components/controls/DateJaBox.vue";
import CustomLoading from "@/components/CustomLoading.vue";
import { IReportSearchParams } from "@/types/IReportSearchParams";
import { ITextValue } from "@/types/ITextValue";
import { ITextValue2 } from "@/types/ITextValue2";
import { IOutputFormSelectList } from "@/types/IOutputFormSelectList";
import { IMainSelectList } from "@/types/IMainSelectList";
import { Parser } from "json2csv";

@Component({
  components: {
    // DropDownListTwo,
    DropDownList,
    StringBox,
    NumberBox,
    ConfirmEx,
    DateJaBox,
    CustomLoading,
  },
})
export default class OutputForm extends Vue {
  isGrid = false;
  isLoading = false;
  userClsfy = false;
  
  conditions: IReportSearchParams = {};
  selectList: IMainSelectList = {
    tyritems: [],                     //nenndo
    cndtcditems: [],                  //youken
    chrgdepcditems: [],               //tantoka
    prgrssapprccditems: [],           //shinchoku-jokyo
    kndscditems: [],                  //syubetu
    accptplcditems: [],               //uketuke-basyo
    answermthdcditems: [],            //kaito-hoho
    adptcditems: [],                  //saitaku
    gengos: [],                       //gengo
    reditems: [],                     //aka-fusen
    blueitems: [],                    //ao-fusen
    yellowitems: [],                  //kiiro-fusen
    orangeitems: [],                  //daidai-fusen
    purpleitems: [],                  //murasaki-fusen
  }
  
  headers = [
        {text: "年度", value: "tyr", align: "left", width:80 },
        {text: "管理番号",  value: "admstnb", align: "left", width:100 },
        {text: "要件区分", value: "cndtcd", align: "left", width:100  },
        {text: "受付年月日", value: "accptymdt", align: "left", width:120 },
        {text: "受付番号", value: "accptnb", align: "left", width:100 },
        {text: "受付場所", value: "accptplcd", align: "left" , width:180},
        {text: "行政区・団体", value: "admar", align: "left", width:180 },
        {text: "代表者", value: "rprsnone", align: "left", width:180 },
        {text: "肩書", value: "pstnm", align: "left", width:150 },
        {text: "件名", value: "pttntitle", align: "left", width:250 },
        {text: "内容", value: "pttncont", align: "left", width:250 },
        {text: "採択等", value: "adptcd", align: "left", width:100 },
        {text: "種別", value: "kndscd", align: "left", width:100 },
        {text: "担当課", value: "chrgdepcd", align: "left", width:100 },
        {text: "見積事業費", value: "estmtsep", align: "right", width:120 },
        {text: "全体事業費", value: "whlep", align: "right", width:120 },
        {text: "実績事業費", value: "actvtep", align: "right", width:120 },
        {text: "進捗率", value: "prgrssrate", align: "left", width:100 },
        {text: "進捗状況", value: "prgrssapprccd", align: "left", width:100 },
        {text: "着工年月日", value: "crrpdymdt", align: "left", width:120 },
        {text: "取下年月日", value: "abandymdt", align: "left", width:120 },
        {text: "工種別", value: "worktyp", align: "left", width:100 },
        {text: "完了年月日", value: "cptymdt", align: "left", width:120 },
        {text: "回答年月日", value: "answerymdt", align: "left", width:120 },
        {text: "回答方法", value: "answermthdcd", align: "left", width:100 },
        {text: "回答日数", value: "answerdys", align: "left", width:100 },
        {text: "備考（担当課用）", value: "chrgrmrks", align: "left", width:250 },
        {text: "備考（行政センター用）", value: "othsrmrks", align: "left", width:250 },
        {text: "集計対象外", value: "ttlgotsdflg", align: "left", width:120 },
        {text: "更新月日", value: "supdtdttm", align: "left", width:100 },
        {text: "更新課", value: "supdtdep", align: "left", width:150 },
        {text: "更新者", value: "supdtnm", align: "left", width:150 },
  ]
  items: IOutputFormSelectList[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get refs(): any {
    return this.$refs;
  }
    
  async created() {
    if(!(this.$store.state.loginuser.loginusercd.length > 0)){
      await alert("ログイン情報が取得できません。再度ログインを実施してください。");
      window.location.assign(process.env.BASE_URL||"");
    }
    // サインインは後で消す
    // await Auth.signIn(this.$store.state.cognitouser, this.$store.state.cognitopass);
    await this.getMasters();
  }

  async mounted() {
    await this.default();
  }

  async clickSearch() {
    this.isLoading = true;
    
    console.log("clickSearch-logXXXXXXXXXXXXXX");
    console.log(this.conditions);
    console.log(this.conditions.tyrfrom);
    
    try {
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
    
      // 検索
      apiparams.body.manipulate = "SELECTSearch";
      apiparams.body.sqlparam = this.conditions;
      console.log(apiparams);
      this.items = await API.post("asmapigateway", "/asmOutputForm", apiparams);
      console.log(this.items);
      
      // 件数チェック
      
      console.log("this.items.lengthXXXXXXXXXXXXXX");
      console.log(this.items.length);
      if (this.items.length === 0) {
        await this.rsltCheck();
        return;
      }
      
    } catch (err) {
      console.log("err:::::::::::::::::::::::::::");
      console.log(err);
    }
    // await Auth.signOut();

    this.isGrid = true;
    this.isLoading = false;
  }

  async clickClear() {
    await this.default();
  }

  async clickIchiranhyo() {
    //
    this.isLoading = true;
    console.log("clickIchiranhyoxxxxxxxxxxxx");
    
    try {
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
    
      // 一覧表抽出
      apiparams.body.manipulate = "OutputList";
      apiparams.body.sqlparam = this.conditions;
      const rslt = await API.post("asmapigateway", "/asmOutputForm", apiparams);
      console.log("rsltxxxxxxxxxxxxxxxxxxxxxxxx");
      console.log(rslt);
      console.log(rslt.length);
      
      // 件数チェック
      if (rslt.length === 0) {
        await this.rsltCheck();
        return;
      }
      
      // const fields = ['tyr','accptymdt','admar','pstnm','rprsnone','pttntitle','chrgdepcd','adptcd','estmtsep','answerymdt','prgrssapprccd','actvtep','cptymdt'];
      const fields = ['年度','管理番号','受付年月日','行政区','肩書','代表者','件名','担当課','採択','見積事業費','回答年月日','進捗状況','実績事業費','完了年月日'];
      // const jsontocsv = parse(csvtest,fields);
      const json2csvParser = new Parser({ fields,withBOM: true });
      const list = json2csvParser.parse(rslt);
      console.log("jsontocsvxxxxxxxxxxxxx");
      console.log(list);
      
      const blob = new Blob([list],{type:"text/csv"});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = '一覧表.csv';
      link.click();
      
    } catch (err) {
      console.log("err:::::::::::::::::::::::::::");
      console.log(err);
    }
    
    // await Auth.signOut();
      
    this.isLoading = false;
  }

  
  async clickShukeihyoGokei() {
    if (!(await this.validation())) {
      return;
    }
    this.isLoading = true;
    
    console.log("clickShukeihyoGokeixxxxxxxxxxxx");
    
    try {
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
    
      // 集計表抽出
      apiparams.body.manipulate = "OutputSpreadSheet";
      apiparams.body.sqlparam = this.conditions;
      const rslt = await API.post("asmapigateway", "/asmOutputForm", apiparams);
      console.log(rslt);
      
      console.log("rslt.lengthXXXXXXXXXXXXXXXXXXXXXXXXXXXXX");
      console.log(rslt.length);
      
      // 件数チェック
      if (rslt.length === 1) {
        await this.rsltCheck();
        return;
      }
      
      const fields = ['種別','申請件数','完了件数','一部完了件数','未着手件数','不採択件数','取下件数','未定件数','見積事業費','全体事業費','実績事業費'];
      // const jsontocsv = parse(csvtest,fields);
      const json2csvParser = new Parser({ fields,withBOM: true });
      const list = json2csvParser.parse(rslt);
      console.log("jsontocsvxxxxxxxxxxxxx");
      console.log(list);
      
      const blob = new Blob([list],{type:"text/csv"});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = '集計表（合計）.csv';
      link.click();
      
    } catch (err) {
      console.log("err:::::::::::::::::::::::::::");
      console.log(err);
    }
    
    // await Auth.signOut();
    this.isLoading = false;
  }

  async clickShukeihyoNendo() {
    if (!(await this.validation())) {
      return;
    }
    this.isLoading = true;
    
    console.log("clickShukeihyoNendoxxxxxxxxxxxx");
    
    try {
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
    
      // 集計表抽出
      apiparams.body.manipulate = "OutputSpreadSheetNendo";
      apiparams.body.sqlparam = this.conditions;
      const rslt = await API.post("asmapigateway", "/asmOutputForm", apiparams);
      console.log("rslt:::::::::::::::::::::::::::");
      console.log(rslt);
      
      const fields = ['種別','申請件数','完了件数','一部完了件数','未着手件数','不採択件数','取下件数','未定件数','見積事業費','全体事業費','実績事業費'];
      const to = Object.keys(rslt).length;
      const nend = this.selectList.tyritems;
      console.log(nend);
      let cnt = 0;
      for (let i = 0; i < to; i++) {
        
        const elmnt = Object.keys(rslt)[i];
        console.log("elmnt:::::::::::::::::::::::::::");
        console.log(elmnt);
        const slctRslt = rslt[elmnt];
        
        if(slctRslt.length === 1){
          cnt ++;
        }
        
        console.log("cnt:::::::::::::::::::::::::::");
        console.log(cnt);
        console.log("to:::::::::::::::::::::::::::");
        console.log(to);
      }
      
        //件数チェック
      if(cnt === to){
        await this.rsltCheck();
        return;
      }
      
      for (let i = 0; i < to; i++) {
        
        const elmnt = Object.keys(rslt)[i];
        console.log("elmnt:::::::::::::::::::::::::::");
        console.log(elmnt);
        const slctRslt = rslt[elmnt];
        if(slctRslt.length === 1){
          continue;
        }
        const json2csvParser = new Parser({ fields,withBOM: true });
        const list = json2csvParser.parse(slctRslt);
        // let shtNm = elmnt;
        const nendFind = nend.find(e => e.value === elmnt);
        let shtNm;
        if(nendFind!== undefined){
          shtNm = nendFind.text;
        }
        
        const blob = new Blob([list],{type:"text/csv"});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = '集計表（年度）_' + shtNm + '.csv';
        link.click();
      }
      
    } catch (err) {
      console.log("err:::::::::::::::::::::::::::");
      console.log(err);
    }
    
    // await Auth.signOut();
    this.isLoading = false;
  }
  

  async default() {
    this.conditions = {};
    // 年度パラ 初期設定
    const result = this.selectList.tyritems.find(e => e.text2 === "1");
    if(result!== undefined){
      this.conditions.tyrfrom = result.value;
      this.conditions.tyrto = result.value;
    }
    // 付箋設定条件を初期値でセット
    this.conditions.fusenusetype = "1";
    this.conditions.fusenandor = "1";
    
    // ログインユーザによって、担当課を固定する
    if(this.$store.state.loginuser.loginathryclsfy != "0"){
      this.conditions.chrgdepcd = this.$store.state.loginuser.loginchrgdepcd;
      this.userClsfy = true;  
    }
    
    
  }

  async getMasters() {
    /**
     * ここでAPIを呼び出し、マスターデータを下記項目の形式で取得する
     */
    this.isLoading = true;
    try {
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
    
      // paratest
      apiparams.body.manipulate = "SELECTPARA";
      this.selectList = await API.post("asmapigateway", "/asmparameter", apiparams);
      console.log(this.selectList);
      
      // 年度パラ 初期設定
      // const result = this.selectList.tyritems.find(e => e.text2 === "1");
      // if(result!== undefined){
      //   this.conditions.tyrfrom = result.value;
      //   this.conditions.tyrto = result.value;
      // }
      
      // // 付箋設定条件を初期値でセット
      // this.conditions.fusenusetype = "1";
      // this.conditions.fusenandor = "1";
      this.default();
      
      // 権限により担当課コンボを制御
      


    } catch (err) {
      console.log("err:::::::::::::::::::::::::::");
      console.log(err);
    }
    
    // await Auth.signOut();
    this.isLoading = false;

  }

  async validation(): Promise<boolean> {
    if (this.conditions.fusen !== undefined && this.conditions.fusen) {
      await this.refs.confirm.open(
        "エラー",
        "集計表には付箋情報を条件として指定できません。付箋指定有のチェックを外してください。",
        {
          type: "alert",
          color: "red",
          width: 500,
        }
      );
      return false;
    }
    return true;
  }
  
  async rsltCheck(){
    this.isLoading = false;
    await this.refs.confirm.open(
      "",
      "該当するデータがありません。",
      {
        type: "alert",
        color: "red",
        width: 500,
      }
    );
  }
  
}
