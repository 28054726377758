










































































































































































































































































































































































































































































import Vue from 'vue';
import { API, Auth } from 'aws-amplify';
import { Component } from 'vue-property-decorator';
import { IHeader } from "@/types/IHeader";
import { IManegmentParams } from "@/types/IManegmentParams";
import { IUserParamsSelectList } from "@/types/IUserParamsSelectList";
import { IFusenParamsSelectList } from "@/types/IFusenParamsSelectList";
import ParamNendoEdit from "@/components/ParamNendoEdit.vue";
import ParamYoukenEdit from "@/components/ParamYoukenEdit.vue";
import ParamSyubetuEdit from "@/components/ParamSyubetuEdit.vue";
import ParamSaitakuEdit from "@/components/ParamSaitakuEdit.vue";
import ParamTantoukaEdit from "@/components/ParamTantoukaEdit.vue";
import ParamUketukeEdit from "@/components/ParamUketukeEdit.vue";
import ParamFusenEdit from "@/components/ParamFusenEdit.vue";
import ParamKaitouEdit from "@/components/ParamKaitouEdit.vue";
import ParamSintyokuEdit from "@/components/ParamSintyokuEdit.vue";
import ParamUserEdit from "@/components/ParamUserEdit.vue";
import ParamGengoEdit from "@/components/ParamGengoEdit.vue";
import ConfirmEx from "@/components/ConfirmEx.vue";
import CustomLoading from "@/components/CustomLoading.vue";

@Component({
  components: {
    ParamNendoEdit,
    ParamYoukenEdit,
    ParamSyubetuEdit,
    ParamSaitakuEdit,
    ParamTantoukaEdit,
    ParamUketukeEdit,
    ParamFusenEdit,
    ParamKaitouEdit,
    ParamSintyokuEdit,
    ParamUserEdit,
    ParamGengoEdit,
    ConfirmEx,
    CustomLoading
  }
})

export default class ManegmentParam extends Vue{

  /**
   * 初期設定・定義 
   */
  // ダイアログ制御
  dialogNendo = false;
  dialogYouken = false;
  dialogSyubetu = false;
  dialogSaitaku = false;
  dialogTantouka = false;
  dialogUketuke = false;
  dialogFusen = false;
  dialogKaitou = false;
  dialogSintyoku = false;
  dialogUser = false;
  dialogGengo = false;
  title!: string;
  color!: string;
  
  // 処理待機中描画
  isLoading = false;
  
  // タブ
  tabs: any = null;
  items: any = [
    { tab: "年度", content: "Tab 1 Content" },
    { tab: "要件区分", content: "Tab 2 Content" },
    { tab: "種別", content: "Tab 3 Content" },
    { tab: "採択", content: "Tab 4 Content" },
    { tab: "担当課", content: "Tab 5 Content" },
    { tab: "受付場所", content: "Tab 6 Content" },
    { tab: "付箋設定", content: "Tab 7 Content" },
    { tab: "回答方法", content: "Tab 8 Content" },
    { tab: "進捗状況", content: "Tab 9 Content" },
    { tab: "ユーザー", content: "Tab 10 Content" },
    { tab: "元号", content: "Tab 11 Content" },
  ];
  
  // タブ一覧の選択行番号の初期位置
  editIndex = -1;
  
  // 年度タブの定義
  headersNendo: IHeader[] = [];
  dessertsNendo: IManegmentParams[] = [];
  
  // 要件区分タブの定義
  headersYouken: IHeader[] = [];
  dessertsYouken: IManegmentParams[] = [];
  
  // 種別タブの定義
  headersSyubetu: IHeader[] = [];
  dessertsSyubetu: IManegmentParams[] = [];
  
  // 採択タブの定義
  headersSaitaku: IHeader[] = [];
  dessertsSaitaku: IManegmentParams[] = [];
  
  // 採択タブの定義
  headersTantouka: IHeader[] = [];
  dessertsTantouka: IManegmentParams[] = [];
  
  // 受付タブの定義
  headersUketuke: IHeader[] = [];
  dessertsUketuke: IManegmentParams[] = [];
  
  // 付箋タブの定義
  headersFusen: IHeader[] = [];
  dessertsFusen: IManegmentParams[] = [];
  
  // 回答タブの定義
  headersKaitou: IHeader[] = [];
  dessertsKaitou: IManegmentParams[] = [];
  
  // 進捗タブの定義
  headersSintyoku: IHeader[] = [];
  dessertsSintyoku: IManegmentParams[] = [];
  
  // ユーザータブの定義
  headersUser: IHeader[] = [];
  dessertsUser: IManegmentParams[] = [];
  selectUserList: IUserParamsSelectList = {
    userParams: [],
    tantoukaList: [],
  };
  selectFusenList: IFusenParamsSelectList = {
    fusenParams: [],
    fusencdList: [],
    fusensyokuList: [],
  };
  
  // 元号ータブの定義
  headersGengo: IHeader[] = [];
  dessertsGengo: IManegmentParams[] = [];
  
  // ダイアログに渡すデータ格納
  editData: IManegmentParams = {};
  
  // 重複チェックデータ格納
  doubleCheck!: IManegmentParams[];
  
  // 修正前データ格納
  keepSelectData: IManegmentParams = {};
  
  // タブナンバー格納
  LoadingNum = 0;
  
  // 必須
  mainRequired= true;
  
  
  // 正規表現
  checkNum =  /^[0-9]+$/;
  checkCapital = /^[A-Z]+$/;
  checkLower = /^[a-z]+$/;
  checkKanji = /^[一-龠]+$/;
  // checkKanji = /^([\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)+$/mu;
  checkSymbol = /^[!"#$%&'()*+\-.,/:;<=>?\\@[\]^_`{|}~]+$/;
  checkSymbolOK = /^[!"#$%&'*+\-,:;<=>?@_~]+$/;
  checkUser = /^[a-zA-Z0-9!"#$%&'*+\-,:;<=>?@_~]+$/;
  // 各1文字以上制約
  checkUserSymbolOK = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])[a-zA-Z0-9!"#$%&'*+\-,:;<=>?@_~]+$/;
  checkSymbolNG = /^((?![()^[\]\\./|`{}]).)*$/;
  // checkSymbolNG = /^[()^[\]\\./|`{}]+$/;
  
  /**
   * 画面ロード
   */
  async created() {
    
    if(!(this.$store.state.loginuser.loginusercd.length > 0)){   
      await alert("ログイン情報が取得できません。再度ログインを実施してください。");
      window.location.assign(process.env.BASE_URL||"");
    }

  }
  
   /**
   * refsの定義
   */
  get refs(): any {
    return this.$refs;
  }

  /**
   * タブのデータ操作
   * タブ操作時に、各タブへデータを反映する
   * tabはnumber型：0, 1, …の順（@changeの仕様）
   */
  async tabDataLoding(tab: number) {
    
    if (tab === 0) {
      // 年度タブ
      this.headersNendo = [
        { text: "年度", align: "start", sortable: false, value: "tyr" },
        { text: "表示年度", sortable: false, value: "dsplytyr" },
        { text: "最新年度サイン", sortable: false, value: "ltsttyrsgn" },
        { text: "更新処理", value: "actions", sortable: false },
      ],
      // データ取得API呼び出し
      await this.getDbData("NENDO");
    }
    else if (tab === 1) {
      // 要件区分タブ
      this.headersYouken =  [
        { text: '要件区分コード', align: 'start', sortable: false, value: 'cndtcd' },
        { text: '要件区分名', sortable: false, value: 'cndtnm' },
        { text: '更新処理', value: 'actions', sortable: false },
      ],
      await this.getDbData("YOUKEN");
    }
    else if (tab === 2) {
      // 種別区分タブ
      this.headersSyubetu =  [
        { text: '種別コード', align: 'start', sortable: false, value: 'kndscd' },
        { text: '種別名', sortable: false, value: 'kndsnm' },
        { text: '更新処理', value: 'actions', sortable: false },
      ],
      await this.getDbData("SYUBETU");
    }
    else if (tab === 3) {
        // 採択タブ
      this.headersSaitaku =  [
        { text: '採択コード', align: 'start', sortable: false, value: 'adptcd' },
        { text: '採択名', sortable: false, value: 'adptnm' },
        { text: '更新処理', value: 'actions', sortable: false },
      ],
      await this.getDbData("SAITAKU");
    }
    else if (tab === 4) {
      // 担当課タブ
      this.headersTantouka =  [
        { text: '担当課コード', align: 'start', sortable: false, value: 'chrgdepcd' },
        { text: '担当課名', sortable: false, value: 'chrgdepnm' },
        { text: '権限区分', sortable: false, value: 'athryclsfy' },
        { text: '更新処理', value: 'actions', sortable: false },
      ],
      await this.getDbData("TANTOUKA");
    }
    else if (tab === 5) {
      // 受付場所タブ
      this.headersUketuke =  [
        { text: '受付コード', align: 'start', sortable: false, value: 'accptplcd' },
        { text: '受付場所名', sortable: false, value: 'accptplnm' },
        { text: '更新処理', value: 'actions', sortable: false },
      ],
      await this.getDbData("UKETUKE");
    }
    else if (tab === 6) {
      // 付箋タブ
      this.headersFusen =  [
        { text: '付箋色コード', align: 'start', sortable: false, value: 'tgcolorcd' },
        { text: '付箋色', sortable: false, value: 'tgcolor' },
        { text: '付箋コード', sortable: false, value: 'tgcd' },
        { text: '付箋短縮名', sortable: false, value: 'tgshtnnm' },
        { text: '付箋名称', sortable: false, value: 'tgnm' },
        { text: '更新処理', value: 'actions', sortable: false },
      ],
      await this.getDbData("FUSEN");
    }
    else if (tab === 7) {
      // 付箋タブ
      this.headersKaitou =  [
        { text: '回答方法コード', align: 'start', sortable: false, value: 'answrmthdcd' },
        { text: '回答方法', value: 'answrmthdnm' },
        { text: '更新処理', value: 'actions', sortable: false },
      ],
      await this.getDbData("KAITOU");
    }
    else if (tab === 8) {
      // 付箋タブ
      this.headersSintyoku =  [
        { text: '進捗状況コード', align: 'start', sortable: false, value: 'prgrssapprccd' },
        { text: '進捗状況', sortable: false, value: 'prgrssapprcnm' },
        { text: '更新処理', value: 'actions', sortable: false },
      ],
      console.log("getDbDataxxxxxxxxcall");      
      console.log(this.dessertsSintyoku);
      await this.getDbData("SINTYOKU");
      console.log("getDbDataxxxxxxxxcallend");
    }
    else if (tab === 9) {
      // 付箋タブ
      this.headersUser =  [
        { text: 'ユーザーコード', align: 'start', sortable: false, value: 'usercd' },
        { text: '担当課コード', sortable: false, value: 'chrgdepcd' },
        { text: 'ユーザー名', sortable: false, value: 'usernm' },
        { text: 'パスワード', sortable: false, value: 'pswd' },
        { text: '更新処理', value: 'actions', sortable: false },
      ],
      await this.getDbData("USER");
    }
    else if (tab === 10) {
      // 付箋タブ
      this.headersGengo =  [
        { text: '元号区分', align: 'start', sortable: false, value: 'nmercd' },
        { text: '開始年月日', sortable: false, value: 'strtymdt' },
        { text: '終了年月日', sortable: false, value: 'endymdt' },
        { text: '元号英半角', sortable: false, value: 'nmeralph' },
        { text: '元号短縮', sortable: false, value: 'nmershtn' },
        { text: '元号', sortable: false, value: 'nmer' },
        { text: '更新処理', value: 'actions', sortable: false },
      ],
      await this.getDbData("GENGO");
    }
    else {
      await this.refs.confirm.open(
                  "エラー",
                  "タブ情報が取得できませんでした。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
    }
  }
  
  /**
   * Data取得API呼び出し
   */
  async getDbData(tab: string) {
    this.isLoading = true;
    
    // パラメータなどの初期設定
    try {
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: ""
        }
      };
      if(tab === "NENDO") {
        console.log("tab=NENDO");
        apiparams.body.manipulate = "SELECTNENDO";
        
        // 検索API呼び出し
        this.dessertsNendo = await API.post("asmapigateway", "/asmManegmentParam", apiparams);
        console.log(this.dessertsNendo);
      }
      else if(tab === "YOUKEN") {
        console.log("tab=YOUKEN");
        apiparams.body.manipulate = "SELECTYOUKEN";
        this.dessertsYouken = await API.post("asmapigateway", "/asmManegmentParam", apiparams);
        console.log(this.dessertsYouken);
      }
      else if(tab === "SYUBETU") {
        console.log("tab=SYUBETU");
        apiparams.body.manipulate = "SELECTSYUBETU";
        this.dessertsSyubetu = await API.post("asmapigateway", "/asmManegmentParam", apiparams);
        console.log(this.dessertsSyubetu);
      }
      else if(tab === "SAITAKU") {
        console.log("tab=SAITAKU");
        apiparams.body.manipulate = "SELECTSAITAKU";
        this.dessertsSaitaku = await API.post("asmapigateway", "/asmManegmentParam", apiparams);
        console.log(this.dessertsSaitaku);
      }
      else if(tab === "TANTOUKA") {
        console.log("tab=TANTOUKA");
        apiparams.body.manipulate = "SELECTTANTOUKA";
        this.dessertsTantouka = await API.post("asmapigateway", "/asmManegmentParam", apiparams);
        console.log(this.dessertsTantouka);
      }
      else if(tab === "UKETUKE") {
        console.log("tab=UKETUKE");
        apiparams.body.manipulate = "SELECTUKETUKE";
        this.dessertsUketuke = await API.post("asmapigateway", "/asmManegmentParam", apiparams);
        console.log(this.dessertsUketuke);
      }
      else if(tab === "FUSEN") {
        apiparams.body.manipulate = "SELECTFUSEN";
        this.selectFusenList = await API.post("asmapigateway", "/asmManegmentParam", apiparams);
        this.dessertsFusen = this.selectFusenList.fusenParams;
        console.log(this.selectFusenList);
      }
      else if(tab === "KAITOU") {
        apiparams.body.manipulate = "SELECTKAITOU";
        this.dessertsKaitou = await API.post("asmapigateway", "/asmManegmentParam", apiparams);
        console.log(this.dessertsKaitou);
      }
      else if(tab === "SINTYOKU") {
        apiparams.body.manipulate = "SELECTSINTYOKU";
        this.dessertsSintyoku = await API.post("asmapigateway", "/asmManegmentParam", apiparams);
        console.log(this.dessertsSintyoku);
      }
      else if(tab === "USER") {
        apiparams.body.manipulate = "SELECTUSER";
        // this.dessertsUser = await API.post("asmapigateway", "/asmManegmentParam", apiparams);
        this.selectUserList = await API.post("asmapigateway", "/asmManegmentParam", apiparams);
        this.dessertsUser = this.selectUserList.userParams;
        console.log(this.selectUserList.tantoukaList);
      }
      else if(tab === "GENGO") {
        apiparams.body.manipulate = "SELECTGENGO";
        this.dessertsGengo = await API.post("asmapigateway", "/asmManegmentParam", apiparams);
        console.log(this.dessertsGengo);
      }
    }catch (err) {
      this.isLoading = false;
      console.log("err:++++++++++++++");
      console.log(err);
    }
    this.isLoading = false;
  }
  
  /**
   * 新規追加登録イベント
   * 
   */
  async createNewDbData(tabType: string) {
    
    // データ格納オブジェクトの初期化
    this.$nextTick(() => {
      this.editIndex = -1;
      this.editData = {};
    });
    
    // ツールバー制御（登録）
    this.title = "パラメータ登録";
    this.color = "pink";
    // this.editTool.toolbartitle = "パラメータ登録";
    // this.editTool.toolbarcolor = "pink";
    // this.editTool.toolbaredittype = "I";
    console.log("tabType：" + tabType);
    
    // タブごとにダイアログを分岐
    if (tabType === "NENDO") {
      
      this.dialogNendo = true;
    }
    else if (tabType === "YOUKEN") {

      this.dialogYouken = true;
    }
    else if (tabType === "SYUBETU") {

      this.dialogSyubetu = true;
    }
    else if (tabType === "SAITAKU") {

      this.dialogSaitaku = true;
    }
    else if (tabType === "TANTOUKA") {

      this.dialogTantouka = true;
    }
    else if (tabType === "UKETUKE") {

      this.dialogUketuke = true;
    }
    else if (tabType === "FUSEN") {

      this.dialogFusen = true;
    }
    else if (tabType === "KAITOU") {

      this.dialogKaitou = true;
    }
    else if (tabType === "SINTYOKU") {

      this.dialogSintyoku = true;
    }
    else if (tabType === "USER") {

      this.dialogUser = true;
    }
    else if (tabType === "GENGO") {

      this.dialogGengo = true;
    }
  }

  /**
   * 変更処理イベント
   */
  editDbData(selected: IManegmentParams, tabType: string) {
    
    // ツールバー制御(変更)
    this.title = "パラメータ変更";
    this.color = "light-blue";
    // this.editTool.toolbartitle = "パラメータ変更";
    // this.editTool.toolbarcolor = "light-blue";
    // this.editTool.toolbaredittype = "U";
    console.log("tabType：" + tabType);
    
    // 修正前データを格納
    // Delete処理時に使用するため
    this.keepSelectData = Object.assign({}, selected);
    
    // タブごとにダイアログを分岐
    if (tabType === "NENDO") {

      this.editIndex = this.dessertsNendo.indexOf(selected);
      this.editData = Object.assign({}, selected);
    
      // 最新年度サインの制御、1のみtrue、それ以外はすべてfalse
      if (this.editData.ltsttyrsgn === "1"){
        this.editData.ltsttyrsgn = "1";
      }else {
        this.editData.ltsttyrsgn = null;
      }
      this.dialogNendo = true;
    }
    else if (tabType === "YOUKEN") {

      this.editIndex = this.dessertsYouken.indexOf(selected);
      this.editData = Object.assign({}, selected);
      this.dialogYouken = true;
    }
    else if (tabType === "SYUBETU") {

      this.editIndex = this.dessertsSyubetu.indexOf(selected);
      this.editData = Object.assign({}, selected);
      this.dialogSyubetu = true;
    }
    else if (tabType === "SAITAKU") {

      this.editIndex = this.dessertsSaitaku.indexOf(selected);
      this.editData = Object.assign({}, selected);
      this.dialogSaitaku = true;
    }
    else if (tabType === "TANTOUKA") {

      this.editIndex = this.dessertsTantouka.indexOf(selected);
      this.editData = Object.assign({}, selected);
      this.dialogTantouka = true;
    }
    else if (tabType === "UKETUKE") {

      this.editIndex = this.dessertsUketuke.indexOf(selected);
      this.editData = Object.assign({}, selected);
      this.dialogUketuke = true;
    }
    else if (tabType === "FUSEN") {

      this.editIndex = this.dessertsFusen.indexOf(selected);
      this.editData = Object.assign({}, selected);
      this.dialogFusen = true;
    }
    else if (tabType === "KAITOU") {

      this.editIndex = this.dessertsKaitou.indexOf(selected);
      this.editData = Object.assign({}, selected);
      this.dialogKaitou = true;
    }
    else if (tabType === "SINTYOKU") {

      this.editIndex = this.dessertsSintyoku.indexOf(selected);
      this.editData = Object.assign({}, selected);
      this.dialogSintyoku = true;
    }
    else if (tabType === "USER") {

      this.editIndex = this.dessertsUser.indexOf(selected);
      this.editData = Object.assign({}, selected);
      this.dialogUser = true;
    }
    else if (tabType === "GENGO") {

      this.editIndex = this.dessertsGengo.indexOf(selected);
      this.editData = Object.assign({}, selected);
      this.dialogGengo= true;
    }
  }

  /**
   * ダイアログ登録処理イベント
   */
  async saveDbData(returnEditData: IManegmentParams, tabType: string, editType: string) {
    
    
    // 入力チェック
    if(!await this.CheckData(returnEditData, tabType, editType)){
        return;
    }
    
    // 登録確認メッセージ
    if (
        !(await this.refs.confirm.open("確認", "登録します。よろしいですか？", {
          width: 500,
        }))
    ) {
      return;
    }
    this.cancelDialog(tabType);
    
    this.isLoading = true;
    console.log("登録処理実行：" + JSON.stringify(returnEditData));
    
    // 登録処理(変更)
    if (editType === "light-blue"){
      
      // 削除処理(※変更処理からの削除API呼出時：削除確認メッセージなし)
      await this.deleteDbData(this.keepSelectData, tabType, editType);
    }
    
    try {
      
      // 登録処理実行(新規登録)
      const apiparams = {
        body: {
          manipulate: "",
          manipulate2: "",
          sqlparam: {}
        }
      };
      apiparams.body.manipulate = "INSERT";
      if(tabType === "NENDO") {
        this.LoadingNum = 0;
        apiparams.body.manipulate2 = "INSERTNENDO";
      }
      else if(tabType === "YOUKEN") {
        this.LoadingNum = 1;
        apiparams.body.manipulate2 = "INSERTYOUKEN";
      }
      else if(tabType === "SYUBETU") {
        this.LoadingNum = 2;
        apiparams.body.manipulate2 = "INSERTSYUBETU";
      }
      else if(tabType === "SAITAKU") {
        this.LoadingNum = 3;
        apiparams.body.manipulate2 = "INSERTSAITAKU";
      }
      else if(tabType === "TANTOUKA") {
        this.LoadingNum = 4;
        apiparams.body.manipulate2 = "INSERTTANTOUKA";
      }
      else if(tabType === "UKETUKE") {
        this.LoadingNum = 5;
        apiparams.body.manipulate2 = "INSERTUKETUKE";
      }
      else if(tabType === "FUSEN") {
        this.LoadingNum = 6;
        apiparams.body.manipulate2 = "INSERTFUSEN";
      }
      else if(tabType === "KAITOU") {
        this.LoadingNum = 7;
        apiparams.body.manipulate2 = "INSERTKAITOU";
      }
      else if(tabType === "SINTYOKU") {
        this.LoadingNum = 8;
        apiparams.body.manipulate2 = "INSERTSINTYOKU";
      }
      else if(tabType === "USER") {
        this.LoadingNum = 9;
        apiparams.body.manipulate2 = "INSERTUSER";
      }
      else if(tabType === "GENGO") {
        this.LoadingNum = 10;
        apiparams.body.manipulate2 = "INSERTGENGO";
      }
      apiparams.body.sqlparam = returnEditData;
      console.log("returnEditData" + returnEditData);
      this.doubleCheck = await API.post("asmapigateway", "/asmManegmentParam", apiparams);
      console.log(this.doubleCheck);
      
    }catch (err) {
      this.isLoading = false;
      console.log("errInsertDbData+++++++++++++++");
      console.log(err);
    }
    console.log("endInsertDbData+++++++++++++++");
    await this.tabDataLoding(this.LoadingNum);
    
    this.isLoading = false;
    // 登録完了
    await this.refs.confirm.open(
      "完了",
      "登録が完了しました。", 
      {
        type: "alert",
        width: 500
      }
    );
  }
  
  /**
   * 入力チェックイベント
   */
  async CheckData(checkValue: IManegmentParams, tabType: string, editType: string): Promise<boolean> {
    
    console.log("checkTabType = " + tabType);

    // 入力チェック
    // nullと空白のチェック
    if (tabType === "NENDO") {
      if (!checkValue.tyr || !checkValue.dsplytyr) {
        await this.refs.confirm.open(
                  "エラー",
                  "すべての項目を入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // バリデーションチェック
      // 年度
      if(!checkValue.tyr.match(this.checkNum)){
        await this.refs.confirm.open(
                  "エラー",
                  "年度は半角数字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.tyr.length != 4){
        await this.refs.confirm.open(
                  "エラー",
                  "年度は4文字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 表示年度
      if(checkValue.dsplytyr.length >= 9){
        await this.refs.confirm.open(
                  "エラー",
                  "表示年度は9文字以上は登録できません。8文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(!checkValue.dsplytyr.match(this.checkSymbolNG)){
        await this.refs.confirm.open(
                  "エラー",
                  "使用できない記号が含まれています。使用可能な記号　! \" # $ % & ' * + , - : ; < = > ? @ _ ~", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }

      // 最新年度サインが1の該当チェック（※編集中の該当以外に最新年度1がないか）
      const ltsttyrsgnCheck = this.dessertsNendo.find(e => e.ltsttyrsgn === "1" && checkValue.tyr !== e.tyr);
      if( checkValue.ltsttyrsgn && ltsttyrsgnCheck){
        await this.refs.confirm.open(
                  "エラー",
                  // TODO 改行
                  "最新年度サインにチェックが有るパラメータが既に登録されています。登録済みパラメータの最新年度サインのチェックを外してから登録してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
    }
    else if (tabType === "YOUKEN") {
      if (!checkValue.cndtcd || !checkValue.cndtnm) {
        await this.refs.confirm.open(
                  "エラー",
                  "すべての項目を入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 要件区分コード
      if(!checkValue.cndtcd.match(this.checkNum)){
        await this.refs.confirm.open(
                  "エラー",
                  "要件区分コードは半角数字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.cndtcd.length >= 3){
        await this.refs.confirm.open(
                  "エラー",
                  "要件区分コードは3文字以上は登録できません。2文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 要件区分名
      if(checkValue.cndtnm.length >= 11){
        await this.refs.confirm.open(
                  "エラー",
                  "要件区分名は11文字以上は登録できません。10文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(!checkValue.cndtnm.match(this.checkSymbolNG)){
        await this.refs.confirm.open(
                  "エラー",
                  "使用できない記号が含まれています。使用可能な記号　! \" # $ % & ' * + , - : ; < = > ? @ _ ~", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
    }
    else if (tabType === "SYUBETU") {
      if (!checkValue.kndscd || !checkValue.kndsnm) {
        await this.refs.confirm.open(
                  "エラー",
                  "すべての項目を入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 種別コード
      if(!checkValue.kndscd.match(this.checkNum)){
        await this.refs.confirm.open(
                  "エラー",
                  "種別コードは半角数字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.kndscd.length >= 6){
        await this.refs.confirm.open(
                  "エラー",
                  "種別コードは6文字以上は登録できません。5文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 種別名
      if(checkValue.kndsnm.length >= 51){
        await this.refs.confirm.open(
                  "エラー",
                  "種別名は51文字以上は登録できません。50文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(!checkValue.kndsnm.match(this.checkSymbolNG)){
        await this.refs.confirm.open(
                  "エラー",
                  "使用できない記号が含まれています。使用可能な記号　! \" # $ % & ' * + , - : ; < = > ? @ _ ~", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
    }
    else if (tabType === "SAITAKU") {
      if (!checkValue.adptcd || !checkValue.adptnm) {
        await this.refs.confirm.open(
                  "エラー",
                  "すべての項目を入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 採択コード
      if(!checkValue.adptcd.match(this.checkNum)){
        await this.refs.confirm.open(
                  "エラー",
                  "採択コードは半角数字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.adptcd.length >= 6){
        await this.refs.confirm.open(
                  "エラー",
                  "採択コードは6文字以上は登録できません。5文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 採択名
      if(checkValue.adptnm.length >= 51){
        await this.refs.confirm.open(
                  "エラー",
                  "採択名は51文字以上は登録できません。50文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(!checkValue.adptnm.match(this.checkSymbolNG)){
        await this.refs.confirm.open(
                  "エラー",
                  "使用できない記号が含まれています。使用可能な記号　! \" # $ % & ' * + , - : ; < = > ? @ _ ~", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
    }
    else if (tabType === "TANTOUKA") {
      if (!checkValue.chrgdepcd || !checkValue.chrgdepnm || !checkValue.athryclsfy) {
        await this.refs.confirm.open(
                  "エラー",
                  "すべての項目を入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 担当課コード
      if(!checkValue.chrgdepcd.match(this.checkNum)){
        await this.refs.confirm.open(
                  "エラー",
                  "担当課コードは半角数字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.chrgdepcd.length >= 6){
        await this.refs.confirm.open(
                  "エラー",
                  "担当課コードは6文字以上は登録できません。5文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 担当課名
      if(checkValue.chrgdepnm.length >= 51){
        await this.refs.confirm.open(
                  "エラー",
                  "担当課名は51文字以上は登録できません。50文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(!checkValue.chrgdepnm.match(this.checkSymbolNG)){
        await this.refs.confirm.open(
                  "エラー",
                  "使用できない記号が含まれています。使用可能な記号　! \" # $ % & ' * + , - : ; < = > ? @ _ ~", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 権限区分
      if(!checkValue.athryclsfy.match(this.checkNum)){
        await this.refs.confirm.open(
                  "エラー",
                  "権限区分は半角数字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.athryclsfy.length >= 3){
        await this.refs.confirm.open(
                  "エラー",
                  "権限区分は3文字以上は登録できません。2文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
    }
    else if (tabType === "UKETUKE") {
      if (!checkValue.accptplcd || !checkValue.accptplnm) {
        await this.refs.confirm.open(
                  "エラー",
                  "すべての項目を入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 受付コード
      if(!checkValue.accptplcd.match(this.checkNum)){
        await this.refs.confirm.open(
                  "エラー",
                  "受付コードは半角数字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.accptplcd.length >= 6){
        await this.refs.confirm.open(
                  "エラー",
                  "受付コードは6文字以上は登録できません。5文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 受付場所名
      if(checkValue.accptplnm.length >= 51){
        await this.refs.confirm.open(
                  "エラー",
                  "受付場所名は51文字以上は登録できません。50文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(!checkValue.accptplnm.match(this.checkSymbolNG)){
        await this.refs.confirm.open(
                  "エラー",
                  "使用できない記号が含まれています。使用可能な記号　! \" # $ % & ' * + , - : ; < = > ? @ _ ~", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
    }
    else if (tabType === "FUSEN") {
      if (!checkValue.tgcolorcd || !checkValue.tgcd || !checkValue.tgshtnnm || !checkValue.tgnm) {
        await this.refs.confirm.open(
                  "エラー",
                  "すべての項目を入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 付箋コード
      if(!checkValue.tgcd.match(this.checkNum)){
        await this.refs.confirm.open(
                  "エラー",
                  "付箋コードは半角数字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.tgcd.length >= 3){
        await this.refs.confirm.open(
                  "エラー",
                  "付箋コードは3文字以上は登録できません。2文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
      return false;
      }
      
      // 付箋短縮名
      if(checkValue.tgshtnnm.length >= 4){
        await this.refs.confirm.open(
                  "エラー",
                  "付箋短縮名は4文字以上は登録できません。3文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
      return false;
      }
      else if(!checkValue.tgshtnnm.match(this.checkSymbolNG)){
        await this.refs.confirm.open(
                  "エラー",
                  "使用できない記号が含まれています。使用可能な記号　! \" # $ % & ' * + , - : ; < = > ? @ _ ~", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 付箋名称
      if(checkValue.tgnm.length >= 101){
        await this.refs.confirm.open(
                  "エラー",
                  "付箋名称は101文字以上は登録できません。100文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
      return false;
      }
      else if(!checkValue.tgnm.match(this.checkSymbolNG)){
        await this.refs.confirm.open(
                  "エラー",
                  "使用できない記号が含まれています。使用可能な記号　! \" # $ % & ' * + , - : ; < = > ? @ _ ~", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
    }
    else if (tabType === "KAITOU") {
      if (!checkValue.answrmthdcd || !checkValue.answrmthdnm) {
        await this.refs.confirm.open(
                  "エラー",
                  "すべての項目を入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 回答方法コード
      if(!checkValue.answrmthdcd.match(this.checkNum)){
        await this.refs.confirm.open(
                  "エラー",
                  "回答方法コードは半角数字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.answrmthdcd.length >= 6){
        await this.refs.confirm.open(
                  "エラー",
                  "回答方法コードは6文字以上は登録できません。5文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
      return false;
      }
      
      // 回答方法
      if(checkValue.answrmthdnm.length >= 51){
        await this.refs.confirm.open(
                  "エラー",
                  "回答方法は51文字以上は登録できません。50文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
      return false;
      }
      else if(!checkValue.answrmthdnm.match(this.checkSymbolNG)){
        await this.refs.confirm.open(
                  "エラー",
                  "使用できない記号が含まれています。使用可能な記号　! \" # $ % & ' * + , - : ; < = > ? @ _ ~", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
    }
    else if (tabType === "SINTYOKU") {
      if (!checkValue.prgrssapprccd || !checkValue.prgrssapprcnm) {
        await this.refs.confirm.open(
                  "エラー",
                  "すべての項目を入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 進捗状況コード
      if(!checkValue.prgrssapprccd.match(this.checkCapital)){
        await this.refs.confirm.open(
                  "エラー",
                  "進捗状況コードは大文字の半角英字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.prgrssapprccd.length >= 5){
        await this.refs.confirm.open(
                  "エラー",
                  "進捗状況コードは5文字以上は登録できません。4文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
      return false;
      }
      
      // 進捗状況
      if(checkValue.prgrssapprcnm.length >= 51){
        await this.refs.confirm.open(
                  "エラー",
                  "進捗状況は51文字以上は登録できません。50文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
      return false;
      }
      else if(!checkValue.prgrssapprcnm.match(this.checkSymbolNG)){
        await this.refs.confirm.open(
                  "エラー",
                  "使用できない記号が含まれています。使用可能な記号　! \" # $ % & ' * + , - : ; < = > ? @ _ ~", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
    }
    else if (tabType === "USER") {
      if (!checkValue.usercd || !checkValue.chrgdepcd || !checkValue.usernm || !checkValue.pswd) {
        await this.refs.confirm.open(
                  "エラー",
                  "すべての項目を入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
      return false;
      }
      
      // ユーザーコード
      if(!checkValue.usercd.match(this.checkUser)){
        await this.refs.confirm.open(
                  "エラー",
                  "ユーザーコードには半角数字、半角英字、記号のみ使用できます。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.usercd.length <= 5 || checkValue.usercd.length  >= 21){
        await this.refs.confirm.open(
                  "エラー",
                  "ユーザーコードは6文字以上20文字以下で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(!checkValue.usercd.match(this.checkSymbolNG)){
        await this.refs.confirm.open(
                  "エラー",
                  "使用できない記号が含まれています。使用可能な記号　! \" # $ % & ' * + , - : ; < = > ? @ _ ~", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // ユーザー名
      if(checkValue.usernm.length >= 101){
        await this.refs.confirm.open(
                  "エラー",
                  "ユーザー名は101文字以上は登録できません。100文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(!checkValue.usernm.match(this.checkSymbolNG)){
        await this.refs.confirm.open(
                  "エラー",
                  "使用できない記号が含まれています。使用可能な記号　! \" # $ % & ' * + , - : ; < = > ? @ _ ~", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // パスワード
      if(!checkValue.pswd.match(this.checkUser)){
        await this.refs.confirm.open(
                  "エラー",
                  "パスワードには半角数字、半角英字、記号のみ使用できます。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.pswd.length <= 7 || checkValue.pswd.length >= 21){
        await this.refs.confirm.open(
                  "エラー",
                  "パスワードは8文字以上20文字以下で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(!checkValue.pswd.match(this.checkUserSymbolOK)){
        await this.refs.confirm.open(
                  "エラー",
                  "パスワードには半角数字、半角英字（大文字）、半角英字（小文字）のそれぞれを1文字以上設定してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(!checkValue.pswd.match(this.checkSymbolNG)){
        await this.refs.confirm.open(
                  "エラー",
                  "使用できない記号が含まれています。使用可能な記号　! \" # $ % & ' * + , - : ; < = > ? @ _ ~", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
    }
    else if (tabType === "GENGO") {
      if (!checkValue.nmercd || !checkValue.strtymdt || !checkValue.endymdt || !checkValue.nmeralph || !checkValue.nmershtn || !checkValue.nmer) {
        await this.refs.confirm.open(
                  "エラー",
                  "すべての項目を入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 元号区分
      if(!checkValue.nmercd.match(this.checkNum)){
        await this.refs.confirm.open(
                  "エラー",
                  "元号区分は半角数字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.nmercd.length >= 3){
        await this.refs.confirm.open(
                  "エラー",
                  "元号区分は3文字以上は登録できません。2文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 開始年月日
      const maxlength = this.dessertsGengo.length - 1;
      const maxEndymdt = Number(this.dessertsGengo[maxlength].endymdt);
      console.log("maxEndymdt：" + maxEndymdt);
      if(!checkValue.strtymdt.match(this.checkNum)){
        await this.refs.confirm.open(
                  "エラー",
                  "開始年月日は半角数字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.strtymdt.length != 8){
        await this.refs.confirm.open(
                  "エラー",
                  "開始年月日は8文字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(Number(checkValue.strtymdt) < maxEndymdt && checkValue.nmercd != this.keepSelectData.nmercd){
        await this.refs.confirm.open(
                  "エラー",
                  "開始年月日には、登録済みのパラメータの終了年月日以降の日付を設定してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 終了年月日
      if (!checkValue.endymdt.match(this.checkNum)){
        await this.refs.confirm.open(
                  "エラー",
                  "終了年月日は半角数字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.endymdt.length != 8){
        await this.refs.confirm.open(
                  "エラー",
                  "終了年月日は8文字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.endymdt < checkValue.strtymdt){
        await this.refs.confirm.open(
                  "エラー",
                  "終了年月日には、開始年月日より後の日付を設定してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 元号英半角
      if(!checkValue.nmeralph.match(this.checkCapital)){
        await this.refs.confirm.open(
                  "エラー",
                  "元号英半角は大文字の半角英字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.nmeralph.length != 1){
        await this.refs.confirm.open(
                  "エラー",
                  "元号英半角は1文字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 元号短縮
      if(!checkValue.nmershtn.match(this.checkKanji)){
         await this.refs.confirm.open(
                  "エラー",
                  "元号短縮は漢字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.nmershtn.length != 1){
        await this.refs.confirm.open(
                  "エラー",
                  "元号短縮は1文字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      
      // 元号
      if(!checkValue.nmer.match(this.checkKanji)){
         await this.refs.confirm.open(
                  "エラー",
                  "元号は漢字で入力してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
      else if(checkValue.nmer.length >= 6){
        await this.refs.confirm.open(
                  "エラー",
                  "元号は6文字以上は登録できません。5文字以下に変更してください。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
    }
    
  
    // 重複チェック 新規登録の時のみ
    if (editType === "pink") {

      // パラメータなどの初期設定
      try {
        const apiparams = {
          body: {
            manipulate: "",
            sqlparam: {}
          }
        };
        // manipulateセット
        if(tabType === "NENDO") {
          apiparams.body.manipulate = "SELECTCHECKNENDO";
        }
        else if(tabType === "YOUKEN") {
          apiparams.body.manipulate = "SELECTCHECKYOUKEN";
        }
        else if(tabType === "SYUBETU") {
          apiparams.body.manipulate = "SELECTCHECKSYUBETU";
        }
        else if(tabType === "SAITAKU") {
          apiparams.body.manipulate = "SELECTCHECKSAITAKU";
        }
        else if(tabType === "TANTOUKA") {
          apiparams.body.manipulate = "SELECTCHECKTANTOUKA";
        }
        else if(tabType === "UKETUKE") {
          apiparams.body.manipulate = "SELECTCHECKUKETUKE";
        }
        else if(tabType === "FUSEN") {
          apiparams.body.manipulate = "SELECTCHECKFUSEN";
        }
        else if(tabType === "KAITOU") {
          apiparams.body.manipulate = "SELECTCHECKKAITOU";
        }
        else if(tabType === "SINTYOKU") {
          apiparams.body.manipulate = "SELECTCHECKSINTYOKU";
        }
        else if(tabType === "USER") {
          apiparams.body.manipulate = "SELECTCHECKUSER";
        }
        else if(tabType === "GENGO") {
          apiparams.body.manipulate = "SELECTCHECKGENGO";
        }
        
        // sqlparamセット
        apiparams.body.sqlparam = checkValue;
        // 重複件数取得API
        this.doubleCheck = await API.post("asmapigateway", "/asmManegmentParam", apiparams);
      }catch (err) {
        console.log("err:::::::::::::::::::::::::::");
        console.log(err);
      }
      console.log(this.doubleCheck);
      
      if(this.doubleCheck.length > 0){
        await this.refs.confirm.open(
                  "エラー",
                  "既存の情報と重複しているため登録できません。", 
                  {
                  type: "alert",
                  color: "red",
                  width: 500
                  }
        );
        return false;
      }
    }
    
    return true;
  }
  
  /**
   * 削除録処理イベント
   */
  async deleteDbData(returnEditData: IManegmentParams, tabType: string, editType: string) {
    
    console.log( "returnEditData" + returnEditData, "editType: "+ editType);
    
    if(editType === "delete"){
      // 削除確認メッセージ(削除ボタンクリック時のみ)
      if (
          !(await this.refs.confirm.open("確認", "削除します。よろしいですか？", {
            width: 500,
          }))
      ) {
        return;
      }
      this.isLoading = true;
    }

    // パラメータなどの初期設定
    try {
      const apiparams = {
        body: {
          manipulate: "",
          manipulate2: "",
          sqlparam: {}
        }
      };
      // delete用のAPIへタブ情報セット
      apiparams.body.manipulate = "DELETE";
      if(tabType === "NENDO") {
        this.LoadingNum = 0;
        apiparams.body.manipulate2 = "DELETENENDO";
      }
      else if(tabType === "YOUKEN") {
        this.LoadingNum = 1;
        apiparams.body.manipulate2 = "DELETEYOUKEN";
      }
      else if(tabType === "SYUBETU") {
        this.LoadingNum = 2;
        apiparams.body.manipulate2 = "DELETESYUBETU";
      }
      else if(tabType === "SAITAKU") {
        this.LoadingNum = 3;
        apiparams.body.manipulate2 = "DELETESAITAKU";
      }
      else if(tabType === "TANTOUKA") {
        this.LoadingNum = 4;
        apiparams.body.manipulate2 = "DELETETANTOUKA";
      }
      else if(tabType === "UKETUKE") {
        this.LoadingNum = 5;
        apiparams.body.manipulate2 = "DELETEUKETUKE";
      }
      else if(tabType === "FUSEN") {
        this.LoadingNum = 6;
        apiparams.body.manipulate2 = "DELETEFUSEN";
      }
      else if(tabType === "KAITOU") {
        this.LoadingNum = 7;
        apiparams.body.manipulate2 = "DELETEKAITOU";
      }
      else if(tabType === "SINTYOKU") {
        this.LoadingNum = 8;
        apiparams.body.manipulate2 = "DELETESINTYOKU";
      }
      else if(tabType === "USER") {
        this.LoadingNum = 9;
        apiparams.body.manipulate2 = "DELETEUSER";
      }
      else if(tabType === "GENGO") {
        this.LoadingNum = 10;
        apiparams.body.manipulate2 = "DELETEGENGO";
      }
      apiparams.body.sqlparam = returnEditData;
      // 削除処理API
      await API.post("asmapigateway", "/asmManegmentParam", apiparams);
      
    }catch (err) {
      this.isLoading = false;
      console.log("err:::::::::::::::::::::::::::");
      console.log(err);
    }

    if(editType === "delete"){
      this.isLoading = false;
      // 削除完了
      await this.refs.confirm.open(
        "完了",
        "削除が完了しました。", 
        {
          type: "alert",
          width: 500
        }
      );
      await this.tabDataLoding(this.LoadingNum);
    }
  } 
  
  /**
   * 各ダイアログのキャンセルイベント
   */
  cancelDialog(tabType: string) {
    
    console.log("cancelDialog：" + tabType);
    
    // データ格納オブジェクトの初期化
    this.$nextTick(() => {
      this.editIndex = -1;
      this.editData = {};
    });
    
    // タブを閉じる
    this.dialogNendo = false;
    this.dialogYouken = false;
    this.dialogSyubetu = false;
    this.dialogSaitaku = false;
    this.dialogTantouka = false;
    this.dialogUketuke = false;
    this.dialogFusen = false;
    this.dialogKaitou = false;
    this.dialogSintyoku = false;
    this.dialogUser = false;
    this.dialogGengo = false;
    
  }
}
