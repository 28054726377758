













































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import StringBox from "@/components/controls/StringBox.vue";
import ConfirmEx from "@/components/ConfirmEx.vue";
import CustomLoading from "@/components/CustomLoading.vue";
import { IImageItem } from "@/types/IImageItem";
import NumberBox from "@/components/controls/NumberBox.vue";
import loadImage from "blueimp-load-image";
import { Storage, API } from "aws-amplify";
import AsmVue from "@/views/AsmVue";
import sharp from "sharp";
import Jimp from "jimp";
import EXIF from "exif-js";
import MapDisplay from "@/components/MapDisplay.vue";

@Component({
  components: {
    StringBox,
    ConfirmEx,
    CustomLoading,
    NumberBox,
    MapDisplay,
  },
})
export default class ImageEdit extends AsmVue {
  dialog = false;
  imagedialog = false;
  editItem: IImageItem = {};
  dustDate: IImageItem = {};
  editIndex = -1;
  files = { name: "" };
  fileDisabled = false;
  originalimage ="";
  mode = 'I' //登録：I、更新：U
  dustdialog = false;
  mapDisplaydialog = false;
  

  /**
   * 年度
   */
  @Prop({ type: String, default: "" }) nendo!: string;
  /**
   * 管理番号
   */
  @Prop({ type: String, default: "" }) kanriNo!: string;
  /**
   * 画像一覧データ
   */
  @Prop({ default: [] }) items!: IImageItem[];
  // items: IImageItem[] = [
  //   {
  //     id: 1,
  //     title: "画像タイトル",
  //     latitude: 0.000001,
  //     longitude: 123.656593121,
  //     address: "群馬県桐生市広沢町3-4025",
  //     image: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
  //   },
  // ];

  get refs(): any {
    return this.$refs;
  }
  
  async mounted(){
    // 画像描画のために苦肉の策
    // 1秒後にダイアログを表示・非表示
    window.setTimeout(this.dust, 1000);
  }
  
  dust(){
    this.dustdialog = true;
    this.dustdialog = false;
  }

  getImage(imageItems: IImageItem){
    console.log(String(imageItems.image));
    const imagedir = String(imageItems.image);
    return imagedir;
    // return "https://cdn.vuetifyjs.com/images/lists/1.jpg";
  }

  
  /**
   * 親画面-一覧行編集
   */
  edit(item: IImageItem) {
    this.mode = 'U';
    this.fileDisabled = true;
    this.editIndex = this.items.indexOf(item);
    this.editItem = Object.assign({}, item);
    this.dialog = true;
  }
  
  
  // 画像データ確認
  async uploadFile(e: any){
   
    const selectfile = e;
    console.log("uploadFile");
    
    let lat: any;
    let lon: any;

    // exif情報を取得
    // await EXIF.getData(selectfile, function(a:any) {
    EXIF.getData(selectfile, (a:any) => {
      let result = '';
  
      // 全項目取得
      // const abc = JSON.stringify(EXIF.getAllTags(selectfile), null, "\t");
      // console.log(abc);
      // 個別に取得する場合 EXIF.getTag(this, "[exifのタグ名]")で、値を取得
      result += 'GPSLatitude:' + EXIF.getTag(selectfile, "GPSLatitude");
      result += '<br>';
      result += 'GPSLongitude:' + EXIF.getTag(selectfile, "GPSLongitude");
  
      // const div_exif = document.getElementById("exif");
      // div_exif.innerHTML = result;
      console.log("E$IFてすと");
      console.log(result);
      
      let gpslat = EXIF.getTag(selectfile, "GPSLatitude");
      let gpslon = EXIF.getTag(selectfile, "GPSLongitude");
      // let lat = 0.0;
      // let lon = 0.0;
      console.log("gpslat" + gpslat);
      console.log(typeof(gpslat));

      try {
        
      console.log(String(gpslat));
          let gpsary = String(gpslat).split(',');
          gpsary.map((val:any, idx:any, ary:any) => {
              let digminsec = [1, 60, 3600];
              // let valary = val.split('/');
              ary[idx] = val / digminsec[idx];
          });
          
          lat = gpsary.reduce((pre:any, cur:any) => {return pre + cur});

          // longatitude
          gpsary = String(gpslon).split(',');
          gpsary.map((val:any, idx:any, ary:any) => {
              let digminsec = [1, 60, 3600];
              // let valary = val.split('/');
              ary[idx] = val  / digminsec[idx];
          });
          lon = gpsary.reduce((pre:any, cur:any) => {return pre + cur});
          
          console.log("lat:"+lat);
          console.log("lon:"+lon);
          
          if(!isNaN(lat)){
            
            // 画面更新
            this.editItem = Object.assign(
              {},
              {
                id: this.editItem.id,
                title: this.editItem.title,
                latitude: lat, // サンプルコード
                longitude: lon, // サンプルコード
                address: "", // サンプルコード
                image:"",
                isMap: this.editItem.isMap,
              }
            );
          } else {
            this.coordinate(1);
            // alert("画像から位置情報を取得できませんでした");
          }
          
      }
      catch (e) {
          console.log('*** BLOCK ERROR GETLATLON ***');
          console.log(e);
          // RS本社latlon
          // lat = 36.3863888888888889;
          // lon = 139.3205555555555556;
      }
      
    });
    
    // const canvas = this.$refs.canvas;
    const canvas = <HTMLCanvasElement> document.getElementById('canvas');
    const context = canvas.getContext("2d");
    
    const img = new Image();
    // img.src = "https://www.kunaicho.go.jp/about/shisetsu/kokyo/img/07gr-01.gif";
    // img.src = selectfile;
    // img.src = e.target.result;
    console.log("eeeeeeeeeeeeeeeeeeeeee");
    const objectURL = window.URL.createObjectURL(selectfile);
    img.src = objectURL;
    console.log(e);
    console.log(objectURL);
    
    img.onload = function(){
      if(context === null) return;
      const imgWidth = img.width; //画像の元の幅を取得
      const imgHeight = img.height; //画像の元の高さを取得
			const imgRate = imgWidth / imgHeight; //画像の比率を取得
			let imgPos = 0; //Canvas上での画像の位置を初期化
			const max = 100;
      context.clearRect(0, 0, max, max); //canvasをクリア
      
      // <説明>
      // context . drawImage(image, sx, sy, sw, sh, dx, dy, dw, dh) 
      // image:描画するイメージ（指定できるのは<img>要素・ <canvas>要素・ <video>要素 のいずれか）
      // sx:元イメージ使用範囲の矩形のx座標（初期値は0）
      // sy:元イメージ使用範囲の矩形のy座標（初期値は0）
      // sw:元イメージ使用範囲の矩形の幅（初期値はイメージ本来の幅）
      // sh:元イメージ使用範囲の矩形の高さ（初期値はイメージ本来の高さ）
      // dx:描画イメージ矩形のx座標
      // dy:描画イメージ矩形のy座標
      // dw:イメージを描画する幅（初期値はイメージ本来の幅）
      // dh:イメージを描画する高さ（初期値はイメージ本来の高さ）
			//画像が横長のとき
			if(imgRate >= 1){ 
				imgPos = (max - (max * imgRate)) / 2; //横方向の画像位置を計算
				// context.drawImage(img, 0, 0, imgWidth, imgHeight, 0, imgPos, 100 * imgRate, 100); //Canvasに幅を基準に画像を描画
				context.drawImage(img, 0, 0, max * imgRate, max);
				//画像が縦長のとき
			}else{ 
				imgPos = (max - (max / imgRate)) / 2; //縦方向の画像位置を計算
				// context.drawImage(img, 0, 0, imgWidth, imgHeight, imgPos, 0, 100, 100 / imgRate); //Canvasに高さを基準に画像を描画
				context.drawImage(img, 0, imgPos, max, max / imgRate);
			}
      
      // context.clearRect(0, 0, 800, 600); // canvasをクリア
      // context.drawImage(img, 0, 0, 200, 200); // 画像を設定
    }
    
  }
  
  async imageClick(item: IImageItem){
    

    try {
      const apiparams = {
        body: {
          manipulate: "",
          admstnb: "",
          modeflg: "",
          sqlparam: {}
        }
      };
      
      apiparams.body.sqlparam = item;
      apiparams.body.admstnb = this.kanriNo;
      apiparams.body.modeflg = 'D';
      apiparams.body.manipulate = "SELECTIMAGE";
      const imageInfo = await API.post("asmapigateway", "/asmMainPage", apiparams);
      
        console.log("imageInfoxxxxxxxxxxxxx");
        console.log(apiparams);
        console.log(imageInfo);
      
      // if(imageInfo[0].image !== undefined){
      if(imageInfo.length > 0){
        this.imagedialog = true;
        const originalpath = this.kanriNo + '/' +imageInfo[0].image;
          
        
        console.log("originalpathxxxxxxxxxxxxxxx");
        console.log(originalpath);
        
        Storage.get(originalpath).then(res => {
          const imgpass = String(res);
        const imagecanvas = <HTMLCanvasElement> document.getElementById('imagecanvas');
        const context = imagecanvas.getContext("2d");
        
        const img = new Image();
        console.log("eeeeeeeeeeeeeeeeeeeeee");
        // const objectURL = window.URL.createObjectURL(imgpass);
        const objectURL = imgpass;
        img.src = objectURL;
        // this.originalimage = objectURL
        console.log(objectURL);
        // originalvimg
        img.onload = function(){
          if(context === null) return;
          const imgWidth = img.width; //画像の元の幅を取得
          const imgHeight = img.height; //画像の元の高さを取得
          
          const imgRate = imgWidth / imgHeight; //画像の比率を取得
          let imgPos = 0; //Canvas上での画像の位置を初期化
          const max = 600;
          
          // <説明>
          // context . drawImage(image, sx, sy, sw, sh, dx, dy, dw, dh) 
          // image:描画するイメージ（指定できるのは<img>要素・ <canvas>要素・ <video>要素 のいずれか）
          // sx:元イメージ使用範囲の矩形のx座標（初期値は0）
          // sy:元イメージ使用範囲の矩形のy座標（初期値は0）
          // sw:元イメージ使用範囲の矩形の幅（初期値はイメージ本来の幅）
          // sh:元イメージ使用範囲の矩形の高さ（初期値はイメージ本来の高さ）
          // dx:描画イメージ矩形のx座標
          // dy:描画イメージ矩形のy座標
          // dw:イメージを描画する幅（初期値はイメージ本来の幅）
          // dh:イメージを描画する高さ（初期値はイメージ本来の高さ）
          //画像が横長のとき
          if(imgRate >= 1){ 
            // imgPos = (max - (max * imgRate)) / 2; //横方向の画像位置を計算
            imagecanvas.width = max * imgRate;
            imagecanvas.height = max;
             context.clearRect(0, 0, max * imgRate, max); //canvasをクリア
            context.drawImage(img, 0, 0, imgWidth, imgHeight, 0, 0, max * imgRate, max); //Canvasに幅を基準に画像を描画
            // context.drawImage(img, 0, 0, max * imgRate, max);
            //画像が縦長のとき
            }else{
            // imgPos = (max - (max / imgRate)) / 2; //縦方向の画像位置を計算
            imagecanvas.width = max;
            imagecanvas.height =  max / imgRate;
            context.clearRect(0, 0, max, max / imgRate); //canvasをクリア
            context.drawImage(img, 0, 0, imgWidth, imgHeight, 0, 0, max, max / imgRate); //Canvasに高さを基準に画像を描画
            // context.drawImage(img, 0, 0, max, max / imgRate);
          }
        }
          
          
        }).catch(err => console.log(err));
          
      }
    } catch (e) {
      this.exception(e);
      return;
    }
  }

  /**
   * 親画面-一覧行削除処理
   */
  async remove(item: IImageItem) {
    if (
      !(await this.refs.confirm.open("確認", "削除します。よろしいですか？", {
        width: 500,
      }))
    ) {
      return;
    }
    
    try {
      const apiparams = {
        body: {
          manipulate: "",
          admstnb: "",
          modeflg: "",
          sqlparam: {}
        }
      };
      
      console.log('this.editItemxxxxxxxxxxx');
      console.log(apiparams.body);
      apiparams.body.sqlparam = item;
      apiparams.body.admstnb = this.kanriNo;
      apiparams.body.modeflg = 'D';
      apiparams.body.manipulate = "SELECTIMAGE";
      const deleteinfo = await API.post("asmapigateway", "/asmMainPage", apiparams);
      
      // updateと記載あるが、中身はDelete
      apiparams.body.manipulate = "UPDATEIMAGE";
      await API.post("asmapigateway", "/asmMainPage", apiparams);
      
      console.log(deleteinfo[0]);
      if(deleteinfo[0].image !== undefined){
        const originalpath = this.kanriNo + '/' +deleteinfo[0].image;
        Storage.remove(originalpath)
        .then(result => {
        }).catch(err => console.log(err));
        
        const thumbnailpath = this.kanriNo + '/' + 'thumbnail/' +deleteinfo[0].image;
        Storage.remove(thumbnailpath)
        .then(result => {
        }).catch(err => console.log(err));
      }
    } catch (e) {
      this.exception(e);
      return;
    }
    

    const index = this.items.indexOf(item);
    console.log("あああああああああああああああああああああ");
    console.log(index);
    this.items.splice(index, 1);
    this.closeDialog();
  }

  /**
   * 現在地取得
   */
  async coordinate(flg:any) {
    
    let str = "現在地の情報を取得します。よろしいですか？";
    if(flg === 1){
      str = "画像ファイルより位置情報を取得できませんでした。現在値の情報を取得します。よろしいですか？"
    }
    
    
    
    console.log(JSON.stringify(this.files));
    // if (!this.files || !this.files.name) {
    //   await this.refs.confirm.open("エラー", "ファイルを選択してください。", {
    //     type: "alert",
    //     color: "red",
    //     width: 500,
    //   });
    //   return;
    // }
    if (
      !(await this.refs.confirm.open(
        "確認",
        str,
        {
          width: 500,
        }
      ))
    ) {
      return;
    }
    // 取得処理
    // 座標変換できなかった場合のエラーメッセージは未実装
    

    // 現在地を取得
    navigator.geolocation.getCurrentPosition(
      // 取得成功した場合
      (position) => {
          // alert("緯度:"+position.coords.latitude+",経度"+position.coords.longitude);
              // 画面更新
          if(this.editItem.address === undefined){
            this.editItem.address = '';
          }
          this.editItem = Object.assign(
            {},
            {
              id: this.editItem.id,
              title: this.editItem.title,
              latitude: position.coords.latitude, // サンプルコード
              longitude: position.coords.longitude, // サンプルコード
              address: this.editItem.address, // サンプルコード
              image:
                "https://www.kunaicho.go.jp/about/shisetsu/kokyo/img/07gr-01.gif",
              isMap: this.editItem.isMap,
            }
          );
          console.log('this.editItem.isMapxxxxxxxxxxxxxx');
          console.log(this.editItem.isMap);
          
      },
      // 取得失敗した場合
      (error) => {
        switch(error.code) {
          case 1: //PERMISSION_DENIED
            alert("位置情報の利用が許可されていません");
            break;
          case 2: //POSITION_UNAVAILABLE
            alert("現在位置が取得できませんでした");
            break;
          case 3: //TIMEOUT
            alert("タイムアウトになりました");
            break;
          default:
            alert("その他のエラー(エラーコード:"+error.code+")");
            break;
        }
      }
    );
  }

  @Watch("files")
  changeFiles(files: any) {
    this.editItem = Object.assign({}, {});
  }

  /**
   * 子画面-保存処理
   */
  async saveDialog() {
    if (!(await this.dialogValid())) {
      return;
    }
    if (
      !(await this.refs.confirm.open("確認", "保存します。よろしいですか？", {
        width: 500,
      }))
    ) {
      return;
    }

    if(this.mode === 'I'){
      const inputImage:any = this.files;
      console.log("aaaaabbccc");
      console.log(inputImage);
      
      if(inputImage == undefined){
        return;
      }
  
      const inputPath = this.kanriNo + '/' + inputImage.name;
      Storage.put(inputPath, inputImage)
      .then(result => {
        console.log(result);
        console.log('filePath2zzzz')
        console.log(inputPath);
      }).catch(err => console.log(err));
              
      const dir = 'thumbnail/';
      const inputPathThumbnail = this.kanriNo + '/' + dir + inputImage.name;
      const canvas = <HTMLCanvasElement> document.getElementById('canvas');
      const context = canvas.getContext("2d");
      if(context === null) return;
      // const dataUrl = context.getImageData(0, 0, 100, 100);
      
      const dataUrl = canvas.toDataURL("image/jpeg", 0.75);
      const blob = this.dataURItoBlob(dataUrl);
      
      Storage.put(inputPathThumbnail, blob)
      .then(result => {
        console.log(result);
      }).catch(err => console.log(err));
      
      // データベースへの登録
      try {
        const apiparams = {
          body: {
            manipulate: "",
            admstnb: "",
            filePath: "",
            sqlparam: {}
          }
        };
        
        // 桁整形
        this.editItem.latitude = Math.floor(Number(this.editItem.latitude) * 1000000) / 1000000;
        this.editItem.longitude = Math.floor(Number(this.editItem.longitude) * 1000000) / 1000000;
        
        // const s3key = this.kanriNo + '/' + this.files.name;
        // サムネ取得とオリジナル取得を可能とするため、ファイル名のみを保持する
        const s3key = this.kanriNo + '/' + 'thumbnail/' + this.files.name;
        Storage.get(s3key, { download: false }).then(res => {
          this.editItem.image = String(res);
          }).catch(err => console.log(err));
          
        // paratest
        apiparams.body.manipulate = "INSERTIMAGE";
        console.log('this.editItemxxxxxxxxxxx');
        console.log(apiparams.body);
        apiparams.body.sqlparam = this.editItem;
        apiparams.body.admstnb = this.kanriNo;
        apiparams.body.filePath = this.files.name;
        const seq = await API.post("asmapigateway", "/asmMainPage", apiparams);
        console.log("seqxxxxxxxxxxxxxxxxxxxxxxxx");
        console.log(seq);
        if(seq[0].id !== undefined){
          this.editItem.id = seq[0].id;
        }
        
      } catch (e) {
        this.exception(e);
        return;
      }
    
    }

    // 更新の場合
    if(this.mode === 'U'){
      
      try {
        const apiparams = {
          body: {
            manipulate: "",
            admstnb: "",
            modeflg: "",
            filePath: "",
            sqlparam: {}
          }
        };
        
        // 桁整形
        this.editItem.latitude = Math.floor(Number(this.editItem.latitude) * 1000000) / 1000000;
        this.editItem.longitude = Math.floor(Number(this.editItem.longitude) * 1000000) / 1000000;
        this.editItem.isMap = Boolean(this.editItem.isMap);
        

        console.log('this.editItemxxxxxxxxxxx');
        console.log(apiparams.body);
        apiparams.body.sqlparam = this.editItem;
        apiparams.body.admstnb = this.kanriNo;
        apiparams.body.modeflg = 'D';
        apiparams.body.manipulate = "SELECTIMAGE";
        const imageitem = await API.post("asmapigateway", "/asmMainPage", apiparams);
        
        const s3key = this.kanriNo + '/' + 'thumbnail/' + String(imageitem[0].image);
        Storage.get(s3key, { download: false }).then(res => {
          this.editItem.image = String(res);
        }).catch(err => console.log(err));
          
          
        // this.editItem.image = String(imageitem[0].image);
        console.log('imagexxxxxxxxxxx');
        console.log(this.editItem.image);

        apiparams.body.manipulate = "UPDATEIMAGE";
        console.log('this.editItemxxxxxxxxxxx');
        console.log(apiparams.body);
        apiparams.body.sqlparam = this.editItem;
        apiparams.body.filePath = String(imageitem[0].image);
        // apiparams.body.admstnb = this.kanriNo;
        apiparams.body.modeflg = 'U';
        await API.post("asmapigateway", "/asmMainPage", apiparams);
        
      } catch (e) {
        this.exception(e);
        return;
      }
      
      
    }
    
    this.mode = 'I';
    this.fileDisabled = false;
    
    if (this.editIndex > -1) {
      Object.assign(this.items[this.editIndex], this.editItem);
    } else {
      this.items.push(this.editItem);
    }
    
    this.closeDialog();
  }
  
  //DataURL（Base64形式）をBlobに変換
  dataURItoBlob(dataURI: any) {
    const contentType = 'image/jpeg';
    //DataURLのデータ部分をBase64からデコード
    let binary = atob(dataURI.split(',')[1]);
    let array = [];
    // Arrayに 1 バイトずつ値を埋める
    for(var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    //BLOB形式に変換
    return new Blob([new Uint8Array(array)], {type: contentType});
  }

  /**
   * 地図検索
   */
  async mapsearch(){
    
    
    //地図表示ダイアログ表示
    this.mapDisplaydialog = true;
    
    window.setTimeout(this.dust, 5000);
    
  }

  async clickMapDisplayClose(geoinfo: any): Promise<void> {
    //地図表示ダイアログ非表示
    this.mapDisplaydialog = false;
    this.editItem.address = geoinfo.addr;
    
    
    
    
  }
  
  

  /**
   * 子画面-入力チェック
   */
  async dialogValid(): Promise<boolean> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let msg = "";
    if (!this.editItem.title) {
      msg = "画像タイトルを入力してください";
    } else if (!this.editItem.latitude) {
      msg = "緯度を入力してください";
    } else if (!this.editItem.longitude) {
      msg = "経度を入力してください";
    // } else if (!this.editItem.address) {
    //   msg = "アドレスを入力してください";
    }

    if (msg) {
      await this.refs.confirm.open("エラー", msg, {
        type: "alert",
        color: "red",
        width: 500,
      });
    }
    return !msg;
  }

  /**
   * 子画面-ダイアログを閉じる
   */
  closeDialog() {
    
    this.fileDisabled = false;
    
    
    this.dialog = false;
    this.$nextTick(() => {
      this.files = Object.assign({}, { name: "" });
      this.editItem = Object.assign({}, {});
      this.editIndex = -1;
    });
    
    // 画像リセット
    const canvas = <HTMLCanvasElement> document.getElementById('canvas');
    const context = canvas.getContext("2d");
    if(context === null) return;
    context.clearRect(0, 0, 100, 100);
    
  }
  
  
  closeImageDialog(){
    
    // 画像リセット
    const imagecanvas = <HTMLCanvasElement> document.getElementById('imagecanvas');
    const context = imagecanvas.getContext("2d");
    if(context === null) return;
    context.clearRect(0, 0, 600, 600);
    this.fileDisabled = false;
    
    
    this.imagedialog = false;
  }

  /**
   * 親画面-キャンセルボタンクリック
   */
  async clickCancel(): Promise<void> {
    if (
      !(await this.refs.confirm.open(
        "確認",
        "未登録の内容変更がある場合、情報が破棄されます。よろしいですか？",
        {
          width: 500,
        }
      ))
    ) {
      return;
    }
    
    
    this.fileDisabled = false;
    this.mode = 'I';
    
    
    this.$emit("dialog-cancel-click", this.items);
  }

  /**
   * 親画面-登録ボタンクリック
   */
  async clickSave(): Promise<void> {
    // //入力チェック
    // if (!(await this.SaveCheck())) {
    //   return;
    // }
    if (
      !(await this.refs.confirm.open(
        "確認",
        "決定します。よろしいですか？新規/修正画面で登録を行わない場合は情報が破棄されます。",
        {
          width: 500,
        }
      ))
    ) {
      return;
    }
    
    this.fileDisabled = false;
    this.mode = 'I';
    
    this.$emit("dialog-save-click");
  }

  //   disabled = true;
  //   isLoading = false;
  //   //パラメータ
  //   @Prop() mainParams!: IMainParams;
  //   get refs(): any {
  //     return this.$refs;
  //   }
  //   // キャンセル
  //   async clickCancel(): Promise<void> {
  //     if (
  //       !(await this.refs.confirm.open(
  //         "確認",
  //         "未登録の内容変更がある場合、情報が破棄されます。よろしいですか？",
  //         {
  //           width: 500,
  //         }
  //       ))
  //     ) {
  //       return;
  //     }
  //     this.$emit("dialog-cancel-click");
  //   }
  //   //登録チェック
  //   async SaveCheck(): Promise<boolean> {
  //     //  // 選択チェック
  //     // if (!Object.keys(this.selected).length) {
  //     //      await this.refs.confirm.open(
  //     //         "エラー",
  //     //         "チェックを入いれてください。",
  //     //         {
  //     //         type: "alert",
  //     //         color: "red",
  //     //         width: 500
  //     //         }
  //     //     );
  //     //     return false;
  //     // }
  //     return true;
  //   }
  //   //登録
  //   async clickSave(): Promise<void> {
  //     //入力チェック
  //     if (!(await this.SaveCheck())) {
  //       return;
  //     }
  //     if (
  //       !(await this.refs.confirm.open(
  //         "確認",
  //         "決定します。よろしいですか？新規/修正画面で登録を行わない場合は情報が破棄されます。",
  //         {
  //           width: 500,
  //         }
  //       ))
  //     ) {
  //       return;
  //     }
  //     //登録内容がが渡ってきたか確認用
  //     //alert(JSON.stringify(this.selected))
  //     this.$emit("dialog-save-click");
  //   }
}
