





























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import StringBox from "@/components/controls/StringBox.vue";
import ConfirmEx from "@/components/ConfirmEx.vue";
import { IOutputDataParams } from "@/types/IOutputDataParams";
import { IOutputDataSelectList } from "@/types/IOutputDataSelectList";
import DropDownListObject from "@/components/controls/DropDownListObject.vue";
import DropDownList from "@/components/controls/DropDownList.vue";
import { ITextValue } from "@/types/ITextValue";
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { IHeader } from "@/types/IHeader";


@Component({
  components: {
    StringBox,
    ConfirmEx,
    DropDownListObject,
    DropDownList,
  },
})

export default class OutputItem extends Vue {

  // パラメータ
  @Prop() outputDataParams!: IOutputDataParams;
  @Prop() outputDataSelectList!: IOutputDataSelectList;
  // @Prop() mainRequired!: boolean;
  @Prop() title!: string;
  @Prop() color!: string;
  @Prop() dplymntItmes!: ITextValue[];

  // 活性状態初期
  sansyouDisable = false;

  //抽出項目用  
  headers!: IHeader[];

  // dplymntItmes: any;

  get refs(): any {
    return this.$refs;
  }
  
  // 画面ロード
  async created() {
    console.log("OutputItem：createdXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX");
    await this.initInfo();
  }

  async initInfo() {

    console.log("start:OutputItem_initInfo");
    // 活性状態制御
    if (this.color === "pink") {
      // 登録ダイアログ
      this.sansyouDisable = true;
      this.headers = [{ text: "抽出項目", value: "text", align: "left" }];
      
      // this.dplymntItmes = this.outputDataParams.selectColumn;
    
    // try {
    //   const apiparams = {
    //     body: {
    //       manipulate: "",
    //       sqlparam: {value: this.outputDataParams.table}
    //     }
    //   };

    //   // 担当paramsセット
    //   apiparams.body.manipulate = "SelectColumn";
    //   console.log("this.outputDataParams.tableXXXXXXXXXXXXXXXXXXXX");
    //   console.log(this.outputDataParams);
    //   // apiparams.body.sqlparam = this.outputDataParams.table;
    //   console.log(apiparams);
    //   const rslt = await API.post("asmapigateway", "/asmOutputData", apiparams);
      // this.outputDataSelectList.selectColums = rslt;

    // }
    // catch (err) {
    //   console.log(err);
    //   return;
    // }
      console.log("end:OutputItem_initInfo");
    }
  }

  /**
   * 入力された値を返す
   */
  get localOutputItem(): IOutputDataParams {

    return this.outputDataParams;
  }

  /**
   * 入力された値を返す
   */
  // get outputParam(): IOutputDataParams {

  //   this.outputItems.param = selectedOutputItems: []

  //   return this.outputItems;

  // }

  /**
   * 入力された値を返す
   */
  // get localNendo(): IManegmentParams {

  //   // 最新年度サインの制御、true = "1"とみなす
  //   if (this.nendoParam.ltsttyrsgn){
  //     console.log("nendoParam.ltsttyrsgn：true");
  //     this.nendoParam.ltsttyrsgn = "1";
  //   }
  //   else {
  //     console.log("nendoParam.ltsttyrsgn：false");
  //     this.nendoParam.ltsttyrsgn = null;
  //   }
  //   return this.nendoParam;
  // }

  // 登録キャンセルイベント
  async clickCancel(): Promise < void > {
    
    let cnclflg = true; 
    if(this.dplymntItmes.length != this.outputDataParams.selectColumn.length){
      cnclflg = false;
    }
    
    if(cnclflg){
      const cnt = this.dplymntItmes.length;
      for(let i = 0 ; i < cnt ; i++ ){
        if(this.dplymntItmes[i].value != this.outputDataParams.selectColumn[i].value){
          cnclflg = false;
          break;
        }
      }
    }
    
    if(!cnclflg){
      if (!(await this.refs.confirm.open("確認", "未設定の内容変更がある場合、情報が破棄されます。よろしいですか？（OK/キャンセル）", {
          width: 500,
        }))) {
        return;
      } else {
        this.outputDataParams.selectColumn = this.dplymntItmes;
      }
    }
    console.log("OutputItem：clickCancel()");
    
    this.$emit("dialog-cancel");
  }

  // 設定イベント
  async clickOk() {
    console.log("OutputItem：clickOk()");
    console.log(this.outputDataParams.selectColumn);
    
    if(this.outputDataParams.selectColumn.length <= 1){
      if (!(await this.refs.confirm.open("エラー", "最低でも1つチェックを入れてください。", {
          width: 500,
        }))) {
        return;
      }
    }
    
    this.$emit("dialog-ok-item", this.localOutputItem);
  }
}
