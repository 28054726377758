var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v("パラメータ設定")]),_c('v-flex',{attrs:{"mb-10":""}}),_c('v-card',[_c('v-toolbar',{staticStyle:{"height":"60px"},attrs:{"color":"light-blue","dark":"","flat":""}},[[_c('v-tabs',{attrs:{"left":""},on:{"change":_vm.tabDataLoding},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item.tab},[_vm._v(_vm._s(item.tab))])}),1)]],2),_c('v-card-text',[_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","persistent":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersNendo,"items":_vm.dessertsNendo},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("年度")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){return _vm.createNewDbData('NENDO')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editDbData(item, 'NENDO')}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteDbData(item, 'NENDO', 'delete')}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","no-click-animation":""},model:{value:(_vm.dialogNendo),callback:function ($$v) {_vm.dialogNendo=$$v},expression:"dialogNendo"}},[_c('ParamNendoEdit',{key:_vm.editData,attrs:{"nendoParam":_vm.editData,"title":_vm.title,"color":_vm.color,"mainRequired":_vm.mainRequired},on:{"dialog-ok":_vm.saveDbData,"dialog-cancel":_vm.cancelDialog}})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","persistent":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersYouken,"items":_vm.dessertsYouken},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("要件区分")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){return _vm.createNewDbData('YOUKEN')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editDbData(item, 'YOUKEN')}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteDbData(item, 'YOUKEN', 'delete')}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","no-click-animation":""},model:{value:(_vm.dialogYouken),callback:function ($$v) {_vm.dialogYouken=$$v},expression:"dialogYouken"}},[_c('ParamYoukenEdit',{key:_vm.editData,attrs:{"youkenParam":_vm.editData,"title":_vm.title,"color":_vm.color,"mainRequired":_vm.mainRequired},on:{"dialog-ok":_vm.saveDbData,"dialog-cancel":_vm.cancelDialog}})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","persistent":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersSyubetu,"items":_vm.dessertsSyubetu},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("種別")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){return _vm.createNewDbData('SYUBETU')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editDbData(item, 'SYUBETU')}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteDbData(item, 'SYUBETU', 'delete')}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","no-click-animation":""},model:{value:(_vm.dialogSyubetu),callback:function ($$v) {_vm.dialogSyubetu=$$v},expression:"dialogSyubetu"}},[_c('ParamSyubetuEdit',{key:_vm.editData,attrs:{"syubetuParam":_vm.editData,"title":_vm.title,"color":_vm.color,"mainRequired":_vm.mainRequired},on:{"dialog-ok":_vm.saveDbData,"dialog-cancel":_vm.cancelDialog}})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","persistent":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersSaitaku,"items":_vm.dessertsSaitaku},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("採択")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){return _vm.createNewDbData('SAITAKU')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editDbData(item, 'SAITAKU')}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteDbData(item, 'SAITAKU', 'delete')}}},[_vm._v(" mdi-delete ")])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","no-click-animation":""},model:{value:(_vm.dialogSaitaku),callback:function ($$v) {_vm.dialogSaitaku=$$v},expression:"dialogSaitaku"}},[_c('ParamSaitakuEdit',{key:_vm.editData,attrs:{"saitakuParam":_vm.editData,"title":_vm.title,"color":_vm.color,"mainRequired":_vm.mainRequired},on:{"dialog-ok":_vm.saveDbData,"dialog-cancel":_vm.cancelDialog}})],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","persistent":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersTantouka,"items":_vm.dessertsTantouka},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("担当課")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){return _vm.createNewDbData('TANTOUKA')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editDbData(item, 'TANTOUKA')}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteDbData(item, 'TANTOUKA', 'delete')}}},[_vm._v(" mdi-delete ")])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","no-click-animation":""},model:{value:(_vm.dialogTantouka),callback:function ($$v) {_vm.dialogTantouka=$$v},expression:"dialogTantouka"}},[_c('ParamTantoukaEdit',{key:_vm.editData,attrs:{"tantoukaParam":_vm.editData,"title":_vm.title,"color":_vm.color,"mainRequired":_vm.mainRequired},on:{"dialog-ok":_vm.saveDbData,"dialog-cancel":_vm.cancelDialog}})],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","persistent":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersUketuke,"items":_vm.dessertsUketuke},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("受付場所")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){return _vm.createNewDbData('UKETUKE')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editDbData(item, 'UKETUKE')}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteDbData(item, 'UKETUKE', 'delete')}}},[_vm._v(" mdi-delete ")])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","no-click-animation":""},model:{value:(_vm.dialogUketuke),callback:function ($$v) {_vm.dialogUketuke=$$v},expression:"dialogUketuke"}},[_c('ParamUketukeEdit',{key:_vm.editData,attrs:{"uketukeParam":_vm.editData,"title":_vm.title,"color":_vm.color,"mainRequired":_vm.mainRequired},on:{"dialog-ok":_vm.saveDbData,"dialog-cancel":_vm.cancelDialog}})],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","persistent":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersFusen,"items":_vm.dessertsFusen},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("付箋パラメータ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){return _vm.createNewDbData('FUSEN')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editDbData(item, 'FUSEN')}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteDbData(item, 'FUSEN', 'delete')}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","no-click-animation":""},model:{value:(_vm.dialogFusen),callback:function ($$v) {_vm.dialogFusen=$$v},expression:"dialogFusen"}},[_c('ParamFusenEdit',{key:_vm.editData,attrs:{"fusenParam":_vm.editData,"selectList":_vm.selectFusenList,"title":_vm.title,"color":_vm.color,"mainRequired":_vm.mainRequired},on:{"dialog-ok":_vm.saveDbData,"dialog-cancel":_vm.cancelDialog}})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","persistent":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersKaitou,"items":_vm.dessertsKaitou},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("回答方法")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){return _vm.createNewDbData('KAITOU')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editDbData(item, 'KAITOU')}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteDbData(item, 'KAITOU', 'delete')}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","no-click-animation":""},model:{value:(_vm.dialogKaitou),callback:function ($$v) {_vm.dialogKaitou=$$v},expression:"dialogKaitou"}},[_c('ParamKaitouEdit',{key:_vm.editData,attrs:{"kaitouParam":_vm.editData,"title":_vm.title,"color":_vm.color,"mainRequired":_vm.mainRequired},on:{"dialog-ok":_vm.saveDbData,"dialog-cancel":_vm.cancelDialog}})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","persistent":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersSintyoku,"items":_vm.dessertsSintyoku},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("進捗状況")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){return _vm.createNewDbData('SINTYOKU')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editDbData(item, 'SINTYOKU')}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteDbData(item, 'SINTYOKU', 'delete')}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","no-click-animation":""},model:{value:(_vm.dialogSintyoku),callback:function ($$v) {_vm.dialogSintyoku=$$v},expression:"dialogSintyoku"}},[_c('ParamSintyokuEdit',{key:_vm.editData,attrs:{"sintyokuParam":_vm.editData,"title":_vm.title,"color":_vm.color,"mainRequired":_vm.mainRequired},on:{"dialog-ok":_vm.saveDbData,"dialog-cancel":_vm.cancelDialog}})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","persistent":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersUser,"items":_vm.dessertsUser},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("ユーザー")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){return _vm.createNewDbData('USER')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editDbData(item, 'USER')}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteDbData(item, 'USER', 'delete')}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","no-click-animation":""},model:{value:(_vm.dialogUser),callback:function ($$v) {_vm.dialogUser=$$v},expression:"dialogUser"}},[_c('ParamUserEdit',{key:_vm.editData,attrs:{"userParam":_vm.editData,"selectList":_vm.selectUserList,"title":_vm.title,"color":_vm.color,"mainRequired":_vm.mainRequired},on:{"dialog-ok":_vm.saveDbData,"dialog-cancel":_vm.cancelDialog}})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","persistent":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersGengo,"items":_vm.dessertsGengo},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("元号")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){return _vm.createNewDbData('GENGO')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editDbData(item, 'GENGO')}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteDbData(item, 'GENGO', 'delete')}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","no-click-animation":""},model:{value:(_vm.dialogGengo),callback:function ($$v) {_vm.dialogGengo=$$v},expression:"dialogGengo"}},[_c('ParamGengoEdit',{key:_vm.editData,attrs:{"gengoParam":_vm.editData,"title":_vm.title,"color":_vm.color,"mainRequired":_vm.mainRequired},on:{"dialog-ok":_vm.saveDbData,"dialog-cancel":_vm.cancelDialog}})],1)],1)],1)],1)],1),_c('custom-loading',{attrs:{"isLoading":_vm.isLoading}}),_c('confirm-ex',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }