
















import { Component, Emit } from "vue-property-decorator";
import ControlBaseVue from "@/components/controls/base/ControlBaseVue";
import { ITextValue } from '@/types/ITextValue';

@Component
export default class DropDownListObject extends ControlBaseVue {
  // selected: any = { Text: "", Value: this.localValue };

  // tslint:disable-next-line:typedef
  @Emit()
  input(items: ITextValue[]) {
    console.log(items);
  }


  private get localValue(): ITextValue[] {
    return this.items;
  }

  private set localValue(items: ITextValue[]) {
    this.input(items);
  }

  backgroundcolor: string = this.color(this.required);
  ret!: string;
  color(required: boolean): string {
    if (required === true) {
      return (this.ret = "#FFF59D");
    } else {
      return (this.ret = "#FFFFFF");
    }
  }

  // @Watch("localValue")
  // // tslint:disable-next-line:typedef
  // selectedChange(value: any) {
  //   // console.log(value);
  //   this.localValue = value;
  // }

  // 画面ロード
  async mounted(): Promise<void> {

    // リスト先頭に空欄を追加
    const kuhaku: ITextValue = {value:"",text:""};
    this.items.unshift( kuhaku);

  }

}
