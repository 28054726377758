















import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";
import ControlBaseVue from "@/components/controls/base/ControlBaseVue";

@Component
export default class NumberBox extends ControlBaseVue {
  @Emit() input(value: string) {
    console.log(value)
   }

  // //numberstep: number = this.step(this.attribute.decimalPlaces);

  private get localValue(): string {
    return this.value;
  }

  private set localValue(value: string) {
    this.input(value);
  }

  backgroundcolor: string = this.color(this.required);
  ret!: string;
  color(required: boolean): string{

    if (required === true ) {
      return this.ret = "#FFF59D";
    }
    else{
      return this.ret = "#FFFFFF";
    }
  }

  // // 上下の▲▼でカウントアップ、カウントダウンするstepを小数点以下桁数からセットする
  // ret: number = 1;
  // step(decimalPlace: any): number {
  //   switch (decimalPlace) {
  //     case "1":
  //       this.ret = 0.1;
  //       break;
  //     case "2":
  //       this.ret = 0.01;
  //       break;
  //     case "3":
  //       this.ret = 0.001;
  //       break;
  //     default:
  //       break;
  //   }
  //   return this.ret;
  // }

//   over(value: string): boolean {
//     if (!/^[-]?([1-9]\d*|0)(\.\d+)?$/.test(value)) {
//       return false;
//     }
//     return +value > +this.attribute.max;
//   }

//   under(value: string): boolean {
//     if (!/^[-]?([1-9]\d*|0)(\.\d+)?$/.test(value)) {
//       return false;
//     }
//     return +value < +this.attribute.min;
//   }
 }
