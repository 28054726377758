<template>

    <v-card>
        <v-form ref="form">
            <v-toolbar color="light-blue" dark style="height:60px;">
            <v-toolbar-title >地図表示</v-toolbar-title>
            <v-spacer></v-spacer>
            </v-toolbar>
        <v-card-text>
            <v-container>
                <v-row class="input">
                    <v-col cols=9>
                        <string-box label="住所" v-model="searchaddr"></string-box>
                    </v-col>
                    <v-col cols=2>
                        <v-btn block color="indigo" dark @click="search()">検索</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        </v-form>
        <v-container>  
            <v-img height="100%" width="100%">
              <div id="mapview">
                <l-map id="map" ref="map" style="height: 560px;" :zoom="zoom" :center="center">
                  <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                </l-map>
              </div>
            </v-img>
        </v-container>
        <v-card-actions>
        <v-container> 
            <v-row class="input">
                <v-col cols="2">
                    <v-btn 
                        block 
                        color="indigo" 
                        dark 
                        @click="clickClose()">閉じる</v-btn>
                </v-col> 
            </v-row> 
        </v-container> 
            <v-dialog v-model="dustdialog" persistent height="50" width="50">
    </v-dialog>
    </v-card-actions>

    </v-card>
</template>  

<script>

// leaflet
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { LMap, LTileLayer } from "vue2-leaflet";
import { Auth, Storage, API, graphqlOperation} from "aws-amplify";
import StringBox from "@/components/controls/StringBox.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
// import { IMainSelectList } from "@/types/IMainSelectList";


// デフォルトマーカーのアイコン設定
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


export default {
  name: "mapview",
  components: { LMap, LTileLayer,StringBox },
  data: () => ({
    
    geoinfo : {
        lat:"",
        lon:"",
        addr:""
    },
    // url:"",
    // attribution:"",
    // zoom:"",
    // center:[],
    dustdialog : false,
    props: ["searchaddr"],
    
    
    // Map関連
    // OpenStreetMap
    url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
    attribution: "<a href='https://osm.org/copyright'>OpenStreetMap</a> contributors, <a href='https://creativecommons.org/licenses/by-sa/2.0/'>CC-BY-SA</a>",
    zoom: 13,
    center: [36.29103344284104, 139.37588382713392] // 太田市役所
  }), 
  mounted: async function() {
      console.log("searchaddr"+this.searchaddr);
      
      window.setTimeout(this.mapdraw, 500);
      
  },
   created: async function() {
    if(!(this.$store.state.loginuser.loginusercd.length > 0)){
      await alert("ログイン情報が取得できません。再度ログインを実施してください。");
      window.location.assign(process.env.BASE_URL||"");
    }
    //     this.url = "https://{s}.tile.osm.org/{z}/{x}/{y}.png";
    // this.attribution = "<a href='https://osm.org/copyright'>OpenStreetMap</a> contributors, <a href='https://creativecommons.org/licenses/by-sa/2.0/'>CC-BY-SA</a>";
    // this.zoom = 13;
    // this.center = [36.29103344284104, 139.37588382713392]; // 
    
  },
  updated: async function() {
      console.log("");
      
  },
  methods:{
    clickClose:async function()  {
    
        this.geoinfo.addr = this.searchaddr;
        this.$emit("dialog-close-click",this.geoinfo);
    },
    mapdraw:async function()  {

        const mapOj = this.$refs.map.mapObject;
        mapOj.invalidateSize();
        console.log("searchaddr"+this.searchaddr);
    
    },
  }
};
</script>

<style>
.imageToCircleGreen {
  width:  50px;       /* ※縦横を同値に */
  height: auto;  /* 高さは自動指定 */
  background-size:cover;
  border-radius: 50%;  /* 角丸半径を50%にする(=円形にする) */
  background-position: center ;
  border: 5px solid #00cc00; /* 枠線を付加 */
}
.imageToCircleYellow {
  width:  50px;       /* ※縦横を同値に */
  height: auto;  /* 高さは自動指定 */
  background-size:cover;
  border-radius: 50%;  /* 角丸半径を50%にする(=円形にする) */
  background-position: center ;
  border: 5px solid #ffcc00; /* 枠線を付加 */
}
.imageToCircleRed {
  width:  50px;       /* ※縦横を同値に */
  height: auto;  /* 高さは自動指定 */
  background-size:cover;
  border-radius: 50%;  /* 角丸半径を50%にする(=円形にする) */
  background-position: center ;
  border: 5px solid #ff0000; /* 枠線を付加 */
}
.input{
  height:40pt;
  line-height:20px;
}
.text{
  height:20pt;
}
.tag{
  height:30pt;
}
.button {
  height:30pt;
}

</style>