<template>
  <v-container>

    <h1>位置情報</h1>
    
    <v-flex mb-10></v-flex>
    <v-container>
      <v-toolbar color="light-blue" dark style="height:60px;">
        <v-toolbar-title >検索条件</v-toolbar-title>
      </v-toolbar>
      <v-row class="input">
        <v-col>
          <DropDownList
            label="年度"
            v-model="selectitems.tyr"
            :items="tyritems"
            >
          </DropDownList>
        </v-col>
        <v-col>
          <StringBox
            label="管理番号" 
            v-model="selectitems.admstnb"
            >
          </StringBox>
        </v-col>
      </v-row>
      <v-row class="input">
        <v-col>
          <StringBox
            label="件名"
            v-model="selectitems.pttntitle"
          >
          </StringBox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn block color="#E0F2F1" @click="clear()">クリア</v-btn>
        </v-col>
        <v-col>
          <v-btn block color="indigo" dark @click="search()">検索</v-btn>
        </v-col>
      </v-row>
    </v-container>
      <v-container>  
        <v-img height="100%" width="100%">
          <div id="mapview">
            <l-map id="map" ref="map" style="height: 560px;" :zoom="zoom" :center="center">
              <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            </l-map>
          </div>
        </v-img>
      </v-container>
  <custom-loading :isLoading="isLoading"></custom-loading>
  </v-container>
</template>

<script>

// leaflet
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { LMap, LTileLayer } from "vue2-leaflet";
import { Auth, Storage, API, graphqlOperation} from "aws-amplify";
import { listImages } from "../graphql/queries";
import { onCreateImage } from "../graphql/subscriptions";
import DropDownList from "@/components/controls/DropDownList.vue";
import StringBox from "@/components/controls/StringBox.vue";
import CustomLoading from "@/components/CustomLoading.vue";
// import { IMainSelectList } from "@/types/IMainSelectList";


// デフォルトマーカーのアイコン設定
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


export default {
  name: "mapview",
  isLoading : false,
  components: { LMap, LTileLayer,DropDownList,StringBox,CustomLoading },
  data: () => ({
    tyritems : {
      text: [],
      text2:[],
      value:[]
    },
    selectitems : {
      tyr: "",
      admstnb:"",
      pttntitle:""
    },
    makerList: [],
    makerGroup:"",

    
    
    // Map関連
    // OpenStreetMap
    url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
    attribution: "<a href='https://osm.org/copyright'>OpenStreetMap</a> contributors, <a href='https://creativecommons.org/licenses/by-sa/2.0/'>CC-BY-SA</a>",
    // 国土地理院
    //url: 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png', attribution: "<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>",

    zoom: 13,
    center: [36.29103344284104, 139.37588382713392] // 太田市役所
  }), 
  mounted: async function() {
    // map = L.map;
    await this.initInfo();
    this.clear();
    this.login();
    // this.subscription();
    // this.displayIssue();
  },
   created: async function() {
    if(!(this.$store.state.loginuser.loginusercd.length > 0)){
      await alert("ログイン情報が取得できません。再度ログインを実施してください。");
      window.location.assign(process.env.BASE_URL||"");
    }
    
  },
  methods:{
    //初期情報取得
    async initInfo(){
      this.isLoading = true;
      
      //情報取得参考
      /**
       * ここでAPIを呼び出し、データを下記項目の形式で取得する
       */
      try {
        const apiparams = {
          body: {
            manipulate: "",
            sqlparam: ""
          }
        };
        // paratest
        apiparams.body.manipulate = "SELECTPARA";
        const result = await API.post("asmapigateway", "/asmparameter", apiparams);
        this.tyritems = result.tyritems;
        console.log("tyritemsxxxxxxxxxxxxxxxxxx");
        console.log(this.tyritems);
        
      } catch (e) {
        this.exception(e);
        return;
      }
       
      
      this.isLoading = false;
    },
    clear(){
    
      // 年度パラ 
      const tyrflg = this.tyritems.find(e => e.text2 === "1");
      if(tyrflg!== undefined){
        this.selectitems.tyr = tyrflg.value;
        // this.latestyear = tyrflg.value;
      }
      this.selectitems.admstnb = "";
      this.selectitems.pttntitle = "";
      
      this.clearicon();
      
    },
    clearicon(){
      
      if(this.makerGroup){
        const mapOj = this.$refs.map.mapObject;
        mapOj.removeLayer(this.makerGroup);
      }



    },
    async search(){
      this.isLoading = '';
      this.isLoading = true;
      this.clearicon();
      // 入力された条件に画像データを検索する。
      console.log(this.selectitems);
      try {
        const apiparams = {
          body: {
            manipulate: "",
            sqlparam: {}
          }
        };
        // paratest
        apiparams.body.manipulate = "SELECTIMAGEINFO";
        apiparams.body.sqlparam = this.selectitems;
        const imageinfo = await API.post("asmapigateway", "/asmMap", apiparams);
        console.log("imageinfoxxxxxxxxxxxxxxxxxx");
        console.log(imageinfo);
        
        this.displayIssue(imageinfo);
        
      } catch (e) {
        this.exception(e);
        return;
      }
      this.isLoading = false;
    },
    displayIssue: async function (info) {
      
      // this.isLoading = true;
      try{

        // const issues = await API.graphql(graphqlOperation(
        //   listImages, {limit: 20}
        // ))
        
      const map = this.$refs.map.mapObject;
      console.log("mapzzzzzzzzzzzzzzzzz");
      console.log(map);
      
      
      
      // const imgPath = "https://asmimage24124-dev.s3-ap-northeast-1.amazonaws.com/";
      //   issues.data.listImages.items.forEach( function(item){
      //     const icon = L.icon({ className:"imageToCircleGreen", iconUrl: imgPath + item.filename, iconRetinaUrl: imgPath + item.filename, iconAnchor: [25, 50], popupAnchor: [0, -50]});
      //     //画像をアイコンとして地図に配置する
      //     //L.marker([ item.latitude,item.longitude ], { icon: icon} ).addTo(map).bindPopup("<b>タイトル<b><br/><img src='" + imgPath + item.filename + "' style='width:150px;' onclick='window.open(\"" + imgPath + item.filename + "\")' /><br/>説明");
      //     //マーカーを地図に配置する
      //     L.marker([ item.latitude,item.longitude ] ).addTo(map).bindPopup("<b>駐輪場<b><br/><img src='" + imgPath + item.filename + "' style='width:150px;' onclick='window.open(\"" + imgPath + item.filename + "\")' /><br/>駐輪場の状況");
      //   });
      
      const cnt = info.length;
      this.makerList = [];
      for(let i = 0 ; i < cnt ; i++){
        
        // const s3key = info[i].admstnb + '/' + 'thumbnail/' + info[i].imgurl;
        const s3key = info[i].admstnb + '/' + info[i].imgurl;
        let imgPath;
        await Storage.get(s3key, { download: false }).then(res => {
          // this.editItem.image = String(res);
          // const imgPath = "https://asmimage24124-dev.s3-ap-northeast-1.amazonaws.com/";
          imgPath = String(res);

        }).catch(err => console.log(err));
        
        
        const icon = L.icon({ className:"imageToCircleGreen", 
                      iconUrl: imgPath,
                      iconRetinaUrl: imgPath,
                      iconAnchor: [25, 50],
                      popupAnchor: [0, -50]
                    });
                    
          //画像をアイコンとして地図に配置する
        // L.marker([ info[i].let,info[i].lon ]).addTo(map).bindPopup("<b>管理番号:" + info[i].admstnb + "<b><br/>タイトル:"+ info[i].imgtitle +"<b><br/><img src='" + imgPath + "' style='width:250px;' onclick='window.open(\"" + imgPath + "\")' /><br/>住所:"+ info[i].addr);
        const aaa = L.marker([ info[i].let,info[i].lon ]).bindPopup("<b>管理番号:" + info[i].admstnb + "<b><br/>タイトル:"+ info[i].imgtitle +"<b><br/><img src='" + imgPath + "' style='width:250px;' onclick='window.open(\"" + imgPath + "\")' /><br/>住所:"+ info[i].addr);
        //マーカーを地図に配置する
        // L.marker([ item.latitude,item.longitude ] ).addTo(map).bindPopup("<b>駐輪場<b><br/><img src='" + imgPath + item.filename + "' style='width:150px;' onclick='window.open(\"" + imgPath + item.filename + "\")' /><br/>駐輪場の状況");

        this.makerList.push(aaa);
        this.makerGroup = L.layerGroup(this.makerList);
        this.makerGroup.addTo(map);
        
        // const aaa = L.layerGroup(makerList);
        
      }
      console.log('L.markerxxxxxxxxxxxxxxxxx');
      console.log(L.marker);

        
      }catch(err){
        if (err) {
          console.log(err, err.stack); // an error occurred
          console.log("エラー"); // an error occurred
          // alert("displayIssueエラー："+JSON.stringify(err)); // an error occurred
        }
      }

    },
    subscription: async function () {
      try{
        //alert("sub 開始");
        API.graphql(
          graphqlOperation(onCreateImage, {limit: 20})
        ).subscribe({
          next: (eventData) => {
            alert(JSON.stringify(eventData.value.data.onCreateImage));
          }
        })
        //alert("sub 完了");
      }catch(err){
        if (err) {
          console.log(err, err.stack); // an error occurred
          console.log("エラー"); // an error occurred
          alert(JSON.stringify(err)); // an error occurred
        }
      }
    },
    selectFile() {
      if(this.$refs.input != undefined){
        this.$refs.input.click();
      }
    }
    // ,
    // updateMap(){
    //   this.displayIssue();
    // }
  }
};
</script>

<style>
.imageToCircleGreen {
  width:  50px;       /* ※縦横を同値に */
  height: auto;  /* 高さは自動指定 */
  background-size:cover;
  border-radius: 50%;  /* 角丸半径を50%にする(=円形にする) */
  background-position: center ;
  border: 5px solid #00cc00; /* 枠線を付加 */
}
.imageToCircleYellow {
  width:  50px;       /* ※縦横を同値に */
  height: auto;  /* 高さは自動指定 */
  background-size:cover;
  border-radius: 50%;  /* 角丸半径を50%にする(=円形にする) */
  background-position: center ;
  border: 5px solid #ffcc00; /* 枠線を付加 */
}
.imageToCircleRed {
  width:  50px;       /* ※縦横を同値に */
  height: auto;  /* 高さは自動指定 */
  background-size:cover;
  border-radius: 50%;  /* 角丸半径を50%にする(=円形にする) */
  background-position: center ;
  border: 5px solid #ff0000; /* 枠線を付加 */
}
.input{
  height:40pt;
  line-height:20px;
}
.text{
  height:20pt;
}
.tag{
  height:30pt;
}
.button {
  height:30pt;
}

</style>