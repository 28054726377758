




























































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import StringBox from "@/components/controls/StringBox.vue";
import ConfirmEx from "@/components/ConfirmEx.vue";
import { IOutputDataParams } from "@/types/IOutputDataParams";
import { IHeader } from "@/types/IHeader";
import DropDownList from "@/components/controls/DropDownList.vue";
import { IOutputDataSelectList } from "@/types/IOutputDataSelectList";
import DropDownListObject from "@/components/controls/DropDownListObject.vue";

@Component({
    components: {
        StringBox,
        ConfirmEx,
        DropDownList,
        DropDownListObject,
    },
})

export default class OutputCondition extends Vue {

    // パラメータ
    @Prop() outputDataParams!: IOutputDataParams;
    @Prop() outputDataSelectList!: IOutputDataSelectList;
    // @Prop() mainRequired!: boolean;
    @Prop() title!: string;
    @Prop() color!: string;
    @Prop() bkWhereColumn1!: string;
    @Prop() bkWhereColumn2!: string;
    @Prop() bkWhereColumn3!: string;
    @Prop() bkWhereColumn4!: string;
    @Prop() bkWhereColumn5!: string;
    @Prop() bkWhereValue1!: string;
    @Prop() bkWhereValue2!: string;
    @Prop() bkWhereValue3!: string;
    @Prop() bkWhereValue4!: string;
    @Prop() bkWhereValue5!: string;
    @Prop() bkWhereCondition1!: string;
    @Prop() bkWhereCondition2!: string;
    @Prop() bkWhereCondition3!: string;
    @Prop() bkWhereCondition4!: string;
    @Prop() bkWhereCondition5!: string;
    @Prop() bkWhereAndor1!: string;
    @Prop() bkWhereAndor2!: string;
    @Prop() bkWhereAndor3!: string;
    @Prop() bkWhereAndor4!: string;
    @Prop() bkWhereAndor5!: string;

    // 活性状態初期
    sansyouDisable = false;
    
    disabledValue1 = false;
    disabledValue2 = false;
    disabledValue3 = false;
    disabledValue4 = false;
    disabledValue5 = false;
    
    // //v-select用
    // outputDataSelectList: IOutputDataSelectList = {
    //     tableList: [],
    //     selectColums: [],
    //     whereConditions: [],
    //     whereAndors: [],
    //     orderSorts: []
    // };
    /**
     * refsの定義
     */
    get refs(): any {
        return this.$refs;
    }
    
    async change(nm: string){
        if(nm === '1'){
            if( this.outputDataParams.whereCondition1 === "IS NULL" ||
                this.outputDataParams.whereCondition1 === "IS NOT NULL"){
                this.disabledValue1 = true;
                this.outputDataParams.whereValue1 = "";
            } else {
                this.disabledValue1 = false;
            }
        }
        if(nm === '2'){
            if( this.outputDataParams.whereCondition2 === "IS NULL" ||
                this.outputDataParams.whereCondition2 === "IS NOT NULL"){
                this.disabledValue2 = true;
                this.outputDataParams.whereValue2 = "";
            } else {
                this.disabledValue2 = false;
            }
        }
        if(nm === '3'){
            if( this.outputDataParams.whereCondition3 === "IS NULL" ||
                this.outputDataParams.whereCondition3 === "IS NOT NULL"){
                this.disabledValue3 = true;
                this.outputDataParams.whereValue3 = "";
            } else {
                this.disabledValue3 = false;
            }
        }
        if(nm === '4'){
            if( this.outputDataParams.whereCondition4 === "IS NULL" ||
                this.outputDataParams.whereCondition4 === "IS NOT NULL"){
                this.disabledValue4 = true;
                this.outputDataParams.whereValue4 = "";
            } else {
                this.disabledValue4 = false;
            }
        }
        if(nm === '5'){
            if( this.outputDataParams.whereCondition5 === "IS NULL" ||
                this.outputDataParams.whereCondition5 === "IS NOT NULL"){
                this.disabledValue5 = true;
                this.outputDataParams.whereValue5 = "";
            } else {
                this.disabledValue5 = false;
            }
        }
    }

    // 画面ロード
    async created() {

        console.log("OutputCondition：created");
        await this.initInfo();
    }

    initInfo() {
        console.log("outputDataParams]:::::::::::::::::");
        console.log(this.outputDataParams);
        console.log(this.outputDataParams.table);

        console.log("start:OutputCondition_initInfo");
        // 活性状態制御
        if (this.color === "pink") {
            // 登録ダイアログ
            this.sansyouDisable = true;
            this.outputDataSelectList.whereConditions = [
                { text: 'と等しい', value: '=' },
                { text: 'と等しくない', value: '<> ' },
                { text: '以上', value: '>=' },
                { text: '以下', value: '<= ' },
                { text: 'を含む', value: 'LIKE' },
                { text: 'を含まない', value: 'NOT LIKE' },
                { text: 'より大きい', value: '>' },
                { text: 'より小さい', value: '<' },
                { text: '空である', value: 'IS NULL' },
                { text: '空でない', value: 'IS NOT NULL' },
            ];
            this.outputDataSelectList.whereAndors = [
                { text: 'AND', value: 'AND' },
                { text: 'OR', value: 'OR' },
            ];
        }
        console.log("end:OutputCondition_initInfo");
    }

    /**
     * 入力された値を返す
     */
    get localOutputItem(): IOutputDataParams {

        return this.outputDataParams;
    }

    /**
     * 入力された値を返す
     */
    // get localNendo(): IManegmentParams {

    //   // 最新年度サインの制御、true = "1"とみなす
    //   if (this.nendoParam.ltsttyrsgn){
    //     console.log("nendoParam.ltsttyrsgn：true");
    //     this.nendoParam.ltsttyrsgn = "1";
    //   }
    //   else {
    //     console.log("nendoParam.ltsttyrsgn：false");
    //     this.nendoParam.ltsttyrsgn = null;
    //   }
    //   return this.nendoParam;
    // }

    // 登録キャンセルイベント
    async clickCancel(): Promise < void > {
        
        let flg = true; 
        if( this.outputDataParams.whereColumn1 != this.bkWhereColumn1 ||
            this.outputDataParams.whereColumn2 != this.bkWhereColumn2 ||
            this.outputDataParams.whereColumn3 != this.bkWhereColumn3 ||
            this.outputDataParams.whereColumn4 != this.bkWhereColumn4 ||
            this.outputDataParams.whereColumn5 != this.bkWhereColumn5 ||
            this.outputDataParams.whereValue1 != this.bkWhereValue1 ||
            this.outputDataParams.whereValue2 != this.bkWhereValue2 ||
            this.outputDataParams.whereValue3 != this.bkWhereValue3 ||
            this.outputDataParams.whereValue4 != this.bkWhereValue4 ||
            this.outputDataParams.whereValue5 != this.bkWhereValue5 ||
            this.outputDataParams.whereCondition1 != this.bkWhereCondition1 ||
            this.outputDataParams.whereCondition2 != this.bkWhereCondition2 ||
            this.outputDataParams.whereCondition3 != this.bkWhereCondition3 ||
            this.outputDataParams.whereCondition4 != this.bkWhereCondition4 ||
            this.outputDataParams.whereCondition5 != this.bkWhereCondition5 ||
            this.outputDataParams.whereAndor1 != this.bkWhereAndor1 ||
            this.outputDataParams.whereAndor2 != this.bkWhereAndor2 ||
            this.outputDataParams.whereAndor3 != this.bkWhereAndor3 ||
            this.outputDataParams.whereAndor4 != this.bkWhereAndor4 ||
            this.outputDataParams.whereAndor5 != this.bkWhereAndor5)
        {
        flg = false;
        }
        console.log(flg);

        if(!flg){
            if (!(await this.refs.confirm.open("確認", "未設定の内容変更がある場合、情報が破棄されます。よろしいですか？（OK/キャンセル）", {
                width: 500,
            }))) {
                return;
            } else {
                this.outputDataParams.whereColumn1 = this.bkWhereColumn1;
                this.outputDataParams.whereColumn2 = this.bkWhereColumn2;
                this.outputDataParams.whereColumn3 = this.bkWhereColumn3;
                this.outputDataParams.whereColumn4 = this.bkWhereColumn4;
                this.outputDataParams.whereColumn5 = this.bkWhereColumn5;
                this.outputDataParams.whereValue1 = this.bkWhereValue1;
                this.outputDataParams.whereValue2 = this.bkWhereValue2;
                this.outputDataParams.whereValue3 = this.bkWhereValue3;
                this.outputDataParams.whereValue4 = this.bkWhereValue4;
                this.outputDataParams.whereValue5 = this.bkWhereValue5;
                this.outputDataParams.whereCondition1 = this.bkWhereCondition1;
                this.outputDataParams.whereCondition2 = this.bkWhereCondition2;
                this.outputDataParams.whereCondition3 = this.bkWhereCondition3;
                this.outputDataParams.whereCondition4 = this.bkWhereCondition4;
                this.outputDataParams.whereCondition5 = this.bkWhereCondition5;
                this.outputDataParams.whereAndor1 = this.bkWhereAndor1;
                this.outputDataParams.whereAndor2 = this.bkWhereAndor2;
                this.outputDataParams.whereAndor3 = this.bkWhereAndor3;
                this.outputDataParams.whereAndor4 = this.bkWhereAndor4;
                this.outputDataParams.whereAndor5 = this.bkWhereAndor5;
            }
        }
        console.log("OutputCondition：clickCancel()");
        
        this.$emit("dialog-cancel");
    }

    // 設定イベント
    async clickOk() {
        
        if (!(await this.okcheck())) {
          return;
        }
        
        console.log("OutputCondition：clickOk()");
        this.$emit("dialog-ok-condition", this.localOutputItem);
    }
    
    async okcheck(): Promise<boolean> {
        if (this.outputDataParams.whereColumn1 == "" &&
            this.outputDataParams.whereColumn2 == "" &&
            this.outputDataParams.whereColumn3 == "" &&
            this.outputDataParams.whereColumn4 == "" &&
            this.outputDataParams.whereColumn5 == "" &&
            this.outputDataParams.whereValue1 == "" &&
            this.outputDataParams.whereValue2 == "" &&
            this.outputDataParams.whereValue3 == "" &&
            this.outputDataParams.whereValue4 == "" &&
            this.outputDataParams.whereValue5 == "" &&
            this.outputDataParams.whereCondition1 == "" &&
            this.outputDataParams.whereCondition2 == "" &&
            this.outputDataParams.whereCondition3 == "" &&
            this.outputDataParams.whereCondition4 == "" &&
            this.outputDataParams.whereCondition5 == "" &&
            this.outputDataParams.whereAndor1 == "" &&
            this.outputDataParams.whereAndor2 == "" &&
            this.outputDataParams.whereAndor3 == "" &&
            this.outputDataParams.whereAndor4 == "" &&
            this.outputDataParams.whereAndor5 == "") {
          await this.refs.confirm.open(
            "エラー",
            "最低でも1つの条件を指定してください。",
            {
              type: "alert",
              color: "red",
              width: 500,
            }
          );
          return false;
        }
        
        let okcheckflg = true;
        if (this.outputDataParams.whereAndor1 != ""){
            if (this.outputDataParams.whereColumn2 == "" ||
                this.outputDataParams.whereValue2 == "" ||
                this.outputDataParams.whereCondition2 == "")
            {
                okcheckflg = false;    
            }
        }
        if (this.outputDataParams.whereAndor2 != ""){
            if (this.outputDataParams.whereColumn3 == "" ||
                this.outputDataParams.whereValue3 == "" ||
                this.outputDataParams.whereCondition3 == "")
            {
                okcheckflg = false;    
            }
        }
        if (this.outputDataParams.whereAndor3 != ""){
            if (this.outputDataParams.whereColumn4 == "" ||
                this.outputDataParams.whereValue4 == "" ||
                this.outputDataParams.whereCondition4 == "")
            {
                okcheckflg = false;    
            }
        }
        if (this.outputDataParams.whereAndor4 != ""){
            if (this.outputDataParams.whereColumn5 == "" ||
                this.outputDataParams.whereValue5 == "" ||
                this.outputDataParams.whereCondition5 == "")
            {
                okcheckflg = false;    
            }
        }
        
        if (!okcheckflg) {
          await this.refs.confirm.open(
            "エラー",
            "不要なAND指定・OR指定を削除してください。",
            {
              type: "alert",
              color: "red",
              width: 500,
            }
          );
          return false;
        }
        
        if (this.outputDataParams.whereColumn1 != "" ||
            this.outputDataParams.whereValue1 != "" ||
            this.outputDataParams.whereCondition1 != "")
        {
            if (this.outputDataParams.whereColumn1 == "" ||
                this.outputDataParams.whereValue1 == "" ||
                this.outputDataParams.whereCondition1 == "")
            {
                okcheckflg = false;
                if((this.outputDataParams.whereCondition1 === "IS NULL" ||
                    this.outputDataParams.whereCondition1 === "IS NOT NULL" ) &&
                    this.outputDataParams.whereColumn1 != "")
                {
                    okcheckflg = true;
                }
            }    
        }
        
        
        if (this.outputDataParams.whereColumn2 != "" ||
            this.outputDataParams.whereValue2 != "" ||
            this.outputDataParams.whereCondition2 != "")
        {
            if (this.outputDataParams.whereColumn2 == "" ||
                this.outputDataParams.whereValue2 == "" ||
                this.outputDataParams.whereCondition2 == "")
            {
                okcheckflg = false;
                if((this.outputDataParams.whereCondition2 === "IS NULL" ||
                    this.outputDataParams.whereCondition2 === "IS NOT NULL" ) &&
                    this.outputDataParams.whereColumn2 != "")
                {
                    okcheckflg = true;
                }
            }    
        }
        if (this.outputDataParams.whereColumn3 != "" ||
            this.outputDataParams.whereValue3 != "" ||
            this.outputDataParams.whereCondition3 != "")
        {
            if (this.outputDataParams.whereColumn3 == "" ||
                this.outputDataParams.whereValue3 == "" ||
                this.outputDataParams.whereCondition3 == "")
            {
                okcheckflg = false;
                if((this.outputDataParams.whereCondition3 === "IS NULL" ||
                    this.outputDataParams.whereCondition3 === "IS NOT NULL" ) &&
                    this.outputDataParams.whereColumn3 != "")
                {
                    okcheckflg = true;
                }
            }    
        }
        if (this.outputDataParams.whereColumn4 != "" ||
            this.outputDataParams.whereValue4 != "" ||
            this.outputDataParams.whereCondition4 != "")
        {
            if (this.outputDataParams.whereColumn4 == "" ||
                this.outputDataParams.whereValue4 == "" ||
                this.outputDataParams.whereCondition4 == "")
            {
                okcheckflg = false;
                if((this.outputDataParams.whereCondition4 === "IS NULL" ||
                    this.outputDataParams.whereCondition4 === "IS NOT NULL" ) &&
                    this.outputDataParams.whereColumn4 != "")
                {
                    okcheckflg = true;
                }
            }    
        }
        if (this.outputDataParams.whereColumn5 != "" ||
            this.outputDataParams.whereValue5 != "" ||
            this.outputDataParams.whereCondition5 != "")
        {
            if (this.outputDataParams.whereColumn5 == "" ||
                this.outputDataParams.whereValue5 == "" ||
                this.outputDataParams.whereCondition5 == "")
            {
                okcheckflg = false;
                if((this.outputDataParams.whereCondition5 === "IS NULL" ||
                    this.outputDataParams.whereCondition5 === "IS NOT NULL" ) &&
                    this.outputDataParams.whereColumn5 != "")
                {
                    okcheckflg = true;
                }
            }    
        }
        
        if (!okcheckflg) {
          await this.refs.confirm.open(
            "エラー",
            "条件を見直してください。",
            {
              type: "alert",
              color: "red",
              width: 500,
            }
          );
          return false;
        }
        
        return true;
    }
    
    async inputcheck(Column: string,Value: string, Condition: string){
        
        if(Column != "" || Value != "" || Condition != ""){
            if(Column == "" || Value == "" || Condition == ""){
                if((Condition === "IS NULL" || Condition === "IS NOT NULL") && Column != ""){
                    return true;
                }else{
                    return false;
                    
                }
            }
        }
    }
         
    
}
