
























































































































import Vue from "vue";
import { Component, Watch, Prop, Emit } from "vue-property-decorator";
import { IMainParams } from "@/types/IMainParams";
import { IFusenParams } from "@/types/IFusenParams";
import { IFusenSelectList } from "@/types/IFusenSelectList";
import DropDownList from "@/components/controls/DropDownList.vue";
import StringBox from "@/components/controls/StringBox.vue";
import FusenBox from "@/components/controls/FusenBox.vue";
import ConfirmEx from "@/components/ConfirmEx.vue";
import CustomLoading from "@/components/CustomLoading.vue";
import AsmVue from "@/views/AsmVue";
import { API, graphqlOperation, Auth } from 'aws-amplify';

@Component({
 components: {
   DropDownList,
   StringBox,
   FusenBox,
   ConfirmEx }
})

export default class FusenEdit extends AsmVue {
    disabled = true;
    isLoading = false;
    selected: any = { Text: "", Value: "" };
     

    //パラメータ
    @Prop() tgredsplmnsnDisabled!: boolean;
    @Prop() fusenParams!: IFusenParams;
    @Prop() selectList!: IFusenSelectList;

    get refs(): any {
        return this.$refs;
    }
    

    // キャンセル
    async clickCancel(): Promise<void> {
        if ( !await this.refs.confirm.open(
                "確認", 
                "未登録の内容変更がある場合、情報が破棄されます。よろしいですか？",
                {
                width: 500
                })
            ) {
            return;
        }

        this.$emit("dialog-cancel-click");
    }  

    // //登録チェック
    // async SaveCheck(): Promise<boolean> {

    //      // 選択チェック
    //     if (this.fusenParams.tgredcd==="" && this.fusenParams.tgyllwcd ===""&& this.fusenParams.tgblucd ===""&& this.fusenParams.tgorngcd ===""&& this.fusenParams.tgprplcd ==="") {
    //          await this.refs.confirm.open(
    //             "エラー",
    //             "最低でも１つの付箋情報を設定してください。", 
    //             {
    //             type: "alert",
    //             color: "red",
    //             width: 500
    //             }
    //         );
    //         return false;
    //     }

    //     return true;
    // }

    //登録
    async clickSave(): Promise<void> {
           
        // //入力チェック
        // if(!await this.SaveCheck()){
        // return;
        // }

        if ( !await this.refs.confirm.open(
            "確認", 
            "登録します。よろしいですか？",
            {
                width: 500
            })
            ) {
            return;
            }
        
        //登録内容がが渡ってきたか確認用
        // alert(JSON.stringify(this.fusenParams))
        // console.log(this.fusenParams);

        //登録処理
        /**
         * ここでAPIを呼び出し、付箋、画像マスタ削除処理を行う 引数は「Params」
         */
        try {
          const apiparams = {
            body: {
              manipulate: "",
              sqlparam: {}
            }
          };
        
          // 検索
          apiparams.body.manipulate = "InsertFusen";
          apiparams.body.sqlparam = this.fusenParams;
          console.log(apiparams);
          const rslt = await API.post("asmapigateway", "/asmMainPage", apiparams);
    
        } catch (e) {
        this.exception(e);
        return;
        }

        

        //新規/修正ダイアログ表示用に短縮名をセット
        this.selected = this.selectList.reditems.find((v) => v.value === this.fusenParams.tgredcd);
        this.fusenParams.tgredname = this.selected.text;
        this.selected = this.selectList.yellowitems.find((v) => v.value === this.fusenParams.tgyllwcd);
        this.fusenParams.tgyllwname = this.selected.text;
        this.selected = this.selectList.blueitems.find((v) => v.value === this.fusenParams.tgblucd);
        this.fusenParams.tgbluname = this.selected.text;
        this.selected = this.selectList.orangeitems.find((v) => v.value === this.fusenParams.tgorngcd);
        this.fusenParams.tgorngname = this.selected.text;
        this.selected = this.selectList.purpleitems.find((v) => v.value === this.fusenParams.tgprplcd);
        this.fusenParams.tgprplname = this.selected.text;


        this.$emit("dialog-save-click",this.fusenParams);

    }

    //赤付箋選択時、特記事項の制御
    selectfusen(value: string){
        
        if(value===''){
            
            this.fusenParams.tgredsplmnsn = "";
            this.tgredsplmnsnDisabled = true;
        }
        else{

            this.tgredsplmnsnDisabled = false;
        }

    }
}

