import Vue from "vue";
import router from "@/router";
export default class AsmVue extends Vue {
  exception(e: any) {
    let status = "500";
    if (
      e !== undefined &&
      e.response !== undefined &&
      e.response.status !== undefined
    ) {
      status = e.response.status;
    }
    // eslint-disable-next-line no-console
    console.log(e);
    const path = `/${status}`;
    router.push(path);
  }
}
