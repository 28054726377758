










































import Vue from "vue";
import { Component, Prop} from "vue-property-decorator";
import StringBox from "@/components/controls/StringBox.vue";
import { IManegmentParams } from "@/types/IManegmentParams";
import ConfirmEx from "@/components/ConfirmEx.vue";

@Component({
  components: {
    StringBox,
    ConfirmEx,
  },
})
export default class ParamYoukenEdit extends Vue {

  // パラメータ
  @Prop() youkenParam!: IManegmentParams;
  @Prop() mainRequired!: boolean;
  @Prop() title!: string;
  @Prop() color!: string;
  
  // タブタイプ
  tabType = "YOUKEN";
  
  // 活性状態初期
  cndtcdReadonly = false;
  cndtnmReadonly = false;
  
  // refsの定義
  get refs(): any {
    
    return this.$refs;
  }
  
  // 画面ロード
  async created() {
    
    console.log("ParamYoukenEdit：created");
    await this.initInfo();
  }
  
  initInfo(){
    
    console.log("start:ParamYoukenEdit_initInfo");
    // 活性状態制御
    if (this.color === "pink"){
      // 登録ダイアログ
      this.cndtcdReadonly = false;
      this.cndtnmReadonly = false;
    }
    else if(this.color === "light-blue"){
      // 変更ダイアログ
      this.cndtcdReadonly = true;
      this.cndtnmReadonly = false;
    }
    console.log("end:ParamYoukenEdit_initInfo");
  }

  /**
   * 入力された値を返す
   */
  get localYouken(): IManegmentParams {
    
    return this.youkenParam;
  }

  // 登録キャンセルイベント
  async clickCancel(): Promise<void> {
    
    if (
      !(await this.refs.confirm.open("確認", "未登録の内容変更がある場合、情報が破棄されます。よろしいですか？", {
        width: 500,
      }))
    ) {
      return;
    }
    console.log("ParamYoukenEdit：clickCancel()");
    this.$emit("dialog-cancel", this.tabType);
  }
  
  // 登録イベント
  clickOk() {
    
    console.log("ParamYoukenEdit：clickOk()");
    this.$emit("dialog-ok", this.localYouken, this.tabType, this.color);
  }
}
