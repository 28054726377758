



























































import Vue from "vue";
import { Component, Watch, Prop, Emit } from "vue-property-decorator";
import ConfirmEx from "@/components/ConfirmEx.vue";
import { IMainParams } from "@/types/IMainParams";
import { IHeader } from "@/types/IHeader";
import StringBox from "@/components/controls/StringBox.vue";
import AsmVue from "@/views/AsmVue";

@Component({
 components: {
   StringBox,
   ConfirmEx }
})

export default class HistoryDisplay extends AsmVue {
    disabled = true;

    //パラメータ
    @Prop() mainParams!: IMainParams;
    @Prop() headers!: IHeader[];
    @Prop() items!: IMainParams[];
    @Prop() selected: any = [];

    get refs(): any {
        return this.$refs;
    }

    // キャンセル
    async clickCancel(): Promise<void> {
        
        this.$emit("dialog-cancel-click");
    } 


}

