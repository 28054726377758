









































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { IOutputDataParams } from "@/types/IOutputDataParams";
import { IOutputDataSelectList } from "@/types/IOutputDataSelectList";
import { API, graphqlOperation, Auth } from 'aws-amplify';
import DropDownListObject from "@/components/controls/DropDownListObject.vue";
import DropDownList from "@/components/controls/DropDownList.vue";
import OutputItem from "@/components/OutputItem.vue";
import OutputCondition from "@/components/OutputCondition.vue";
import OutputOrder from "@/components/OutputOrder.vue";
import ConfirmEx from "@/components/ConfirmEx.vue";
import CustomLoading from "@/components/CustomLoading.vue";
import { ITextValue } from "@/types/ITextValue";
import { IHeader } from "@/types/IHeader";
import { Parser } from "json2csv";

@Component({
  components: {
    DropDownListObject,
    OutputItem,
    OutputCondition,
    OutputOrder,
    ConfirmEx,
    DropDownList,
    CustomLoading
  }
})

export default class OutputData extends Vue {

  //画面制御用
  dialogOutputItem = false;
  dialogOutputCondition = false;
  dialogOutputOrder = false;
  isconditionshow = true;
  isresultshow = false;
  dialog = false;
  //date!: string;
  items!: ITextValue;
  headers!: IHeader[];
  isLoading = false;
  title!: string;
  color!: string;
  selectTable: ITextValue[] = [
    { value: '', text: '' }];
  rslt: any;
  dplymntItmes!: ITextValue[];
  
  // 抽出条件バックアップのため
  // 上手くいかなかったので、格好悪いけども・・・
  bkWhereColumn1!: string;
  bkWhereColumn2!: string;
  bkWhereColumn3!: string;
  bkWhereColumn4!: string;
  bkWhereColumn5!: string;
  bkWhereValue1!: string;
  bkWhereValue2!: string;
  bkWhereValue3!: string;
  bkWhereValue4!: string;
  bkWhereValue5!: string;
  bkWhereCondition1!: string;
  bkWhereCondition2!: string;
  bkWhereCondition3!: string;
  bkWhereCondition4!: string;
  bkWhereCondition5!: string;
  bkWhereAndor1!: string;
  bkWhereAndor2!: string;
  bkWhereAndor3!: string;
  bkWhereAndor4!: string;
  bkWhereAndor5!: string;
  
  // 並び順バックアップのため
  // 格好悪いけども・・・
  bkOrderColumn1!: string;
  bkOrderColumn2!: string;
  bkOrderColumn3!: string;
  bkOrderColumn4!: string;
  bkOrderColumn5!: string;
  bkOrderSort1!: string;
  bkOrderSort2!: string;
  bkOrderSort3!: string;
  bkOrderSort4!: string;
  bkOrderSort5!: string;
  
  //初期化（起動時）
  //V-model
  outputDataParams: IOutputDataParams = {
    table: "",
    // 抽出項目
    selectColumn: [{text: '',value:''}],
    // 抽出条件1
    whereColumn1: "",
    // 抽出条件2
    whereColumn2: "",
    // 抽出条件3
    whereColumn3: "",
    // 抽出条件4
    whereColumn4: "",
    // 抽出条件5
    whereColumn5: "",
    // 抽出コード値1
    whereValue1: "",
    // 抽出コード値2
    whereValue2: "",
    // 抽出コード値3
    whereValue3: "",
    // 抽出コード値4
    whereValue4: "",
    // 抽出コード値5
    whereValue5: "",
    // 抽出条件右1
    whereCondition1: "",
    // 抽出条件右2
    whereCondition2: "",
    // 抽出条件右3
    whereCondition3: "",
    // 抽出条件右4
    whereCondition4: "",
    // 抽出条件右5
    whereCondition5: "",
    // 抽出条件AndOr1
    whereAndor1: "",
    // 抽出条件AndOr2
    whereAndor2: "",
    // 抽出条件AndOr3
    whereAndor3: "",
    // 抽出条件AndOr4
    whereAndor4: "",
    // 抽出条件AndOr5
    whereAndor5: "",
    // 抽出順項目1
    orderColumn1: "",
    // 抽出順項目2
    orderColumn2: "",
    // 抽出順項目3
    orderColumn3: "",
    // 抽出順項目4
    orderColumn4: "",
    // 抽出順項目5
    orderColumn5: "",
    // 出力順1
    orderSort1: "",
    // 出力順2
    orderSort2: "",
    // 出力順3
    orderSort3: "",
    // 出力順4
    orderSort4: "",
    // 出力順5
    orderSort5: "",
  };

  //v-select用
  outputDataSelectList: IOutputDataSelectList = {
    tableList: [],
    selectColums: [],
    whereConditions: [],
    whereAndors: [],
    orderSorts: []
  };

  get refs(): any {
    return this.$refs;
  }

  // 画面ロード
  async created() {

    if (!(this.$store.state.loginuser.loginusercd.length > 0)) {
      await alert("ログイン情報が取得できません。再度ログインを実施してください。");
      window.location.assign(process.env.BASE_URL || "");
    }

  }

  async mounted() {
    // 初期設定
    await this.initInfo();
  }
  /**
   * パラメータ情報取得
   */
  async initInfo() {

    // this.isLoading = true;

    try {

      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: ""
        }
      };

      // 担当paramsセット
      apiparams.body.manipulate = "SelectSansyou";
      console.log(apiparams);
      this.outputDataSelectList.tableList = await API.post("asmapigateway", "/asmOutputData", apiparams);
      
    }
    catch (err) {
      console.log(err);
      return;
    }

    // this.isLoading = false;
  }

  async outputDataClick(btntype: string) {

    console.log("btntype：" + btntype);
    
    // ボタンごとにダイアログを分岐
    if (btntype === "OutputItem") {
        //入力チェック
        if (!await this.Check()) {
          this.isLoading = false;
          return;
        }
        
      try {
        const apiparams = {
          body: {
            manipulate: "",
            sqlparam: {value: this.outputDataParams.table}
          }
        };
        apiparams.body.manipulate = "SelectColumn";
        console.log(apiparams);
        const rsltitems = await API.post("asmapigateway", "/asmOutputData", apiparams);
        this.outputDataSelectList.selectColums = rsltitems;
      }
      catch (err) {
        console.log(err);
        return;
      }
      
      this.dplymntItmes = this.outputDataParams.selectColumn;
      console.log(this.dplymntItmes);
      console.log(this.outputDataParams.selectColumn);
      console.log("dplymntItmes：dplymntItmesｘｘｘｘｘｘｘｘｘｘｘｘｘ");
      this.title = "抽出項目設定";
      this.color = "pink";
      this.dialogOutputItem = true;
      
    }
    else if (btntype === "OutputCondition") {
      //入力チェック
      if (!await this.Check()) {
        this.isLoading = false;
        return;
      }

      this.title = "抽出条件設定";
      this.color = "pink";
      this.dialogOutputCondition = true;
      if(this.outputDataParams.whereColumn1 != undefined)this.bkWhereColumn1 = this.outputDataParams.whereColumn1;
      if(this.outputDataParams.whereColumn2 != undefined)this.bkWhereColumn2 = this.outputDataParams.whereColumn2;
      if(this.outputDataParams.whereColumn3 != undefined)this.bkWhereColumn3 = this.outputDataParams.whereColumn3;
      if(this.outputDataParams.whereColumn4 != undefined)this.bkWhereColumn4 = this.outputDataParams.whereColumn4;
      if(this.outputDataParams.whereColumn5 != undefined)this.bkWhereColumn5 = this.outputDataParams.whereColumn5;
      if(this.outputDataParams.whereValue1 != undefined)this.bkWhereValue1 = this.outputDataParams.whereValue1;
      if(this.outputDataParams.whereValue2 != undefined)this.bkWhereValue2 = this.outputDataParams.whereValue2;
      if(this.outputDataParams.whereValue3 != undefined)this.bkWhereValue3 = this.outputDataParams.whereValue3;
      if(this.outputDataParams.whereValue4 != undefined)this.bkWhereValue4 = this.outputDataParams.whereValue4;
      if(this.outputDataParams.whereValue5 != undefined)this.bkWhereValue5 = this.outputDataParams.whereValue5;
      if(this.outputDataParams.whereCondition1 != undefined)this.bkWhereCondition1 = this.outputDataParams.whereCondition1;
      if(this.outputDataParams.whereCondition2 != undefined)this.bkWhereCondition2 = this.outputDataParams.whereCondition2;
      if(this.outputDataParams.whereCondition3 != undefined)this.bkWhereCondition3 = this.outputDataParams.whereCondition3;
      if(this.outputDataParams.whereCondition4 != undefined)this.bkWhereCondition4 = this.outputDataParams.whereCondition4;
      if(this.outputDataParams.whereCondition5 != undefined)this.bkWhereCondition5 = this.outputDataParams.whereCondition5;
      if(this.outputDataParams.whereAndor1 != undefined)this.bkWhereAndor1 = this.outputDataParams.whereAndor1;
      if(this.outputDataParams.whereAndor2 != undefined)this.bkWhereAndor2 = this.outputDataParams.whereAndor2;
      if(this.outputDataParams.whereAndor3 != undefined)this.bkWhereAndor3 = this.outputDataParams.whereAndor3;
      if(this.outputDataParams.whereAndor4 != undefined)this.bkWhereAndor4 = this.outputDataParams.whereAndor4;
      if(this.outputDataParams.whereAndor5 != undefined)this.bkWhereAndor5 = this.outputDataParams.whereAndor5;
    }
    else if (btntype === "OutputOrder") {
      //入力チェック
      if (!await this.Check()) {
        this.isLoading = false;
        return;
      }
      
      if(this.outputDataParams.orderColumn1 != undefined)this.bkOrderColumn1 = this.outputDataParams.orderColumn1;
      if(this.outputDataParams.orderColumn2 != undefined)this.bkOrderColumn2 = this.outputDataParams.orderColumn2;
      if(this.outputDataParams.orderColumn3 != undefined)this.bkOrderColumn3 = this.outputDataParams.orderColumn3;
      if(this.outputDataParams.orderColumn4 != undefined)this.bkOrderColumn4 = this.outputDataParams.orderColumn4;
      if(this.outputDataParams.orderColumn5 != undefined)this.bkOrderColumn5 = this.outputDataParams.orderColumn5;
      if(this.outputDataParams.orderSort1 != undefined)this.bkOrderSort1 = this.outputDataParams.orderSort1;
      if(this.outputDataParams.orderSort2 != undefined)this.bkOrderSort2 = this.outputDataParams.orderSort2;
      if(this.outputDataParams.orderSort3 != undefined)this.bkOrderSort3 = this.outputDataParams.orderSort3;
      if(this.outputDataParams.orderSort4 != undefined)this.bkOrderSort4 = this.outputDataParams.orderSort4;
      if(this.outputDataParams.orderSort5 != undefined)this.bkOrderSort5 = this.outputDataParams.orderSort5;
      this.title = "並び替え条件設定";
      this.color = "pink";
      this.dialogOutputOrder = true;
    }
  }

  /**
   * 各ダイアログのキャンセルイベント
   */
  cancelDialog(btnType: string) {
    console.log("cancelDialog：" + btnType);
    // タブを閉じる
    this.dialogOutputItem = false;
    this.dialogOutputCondition = false;
    this.dialogOutputOrder = false;
  }

  /**
   * 各ダイアログのOKイベント
   */
  okDialog(returnEditData: IOutputDataParams) {

    // タブを閉じる
    console.log(returnEditData);
    this.dialogOutputItem = false;
    this.dialogOutputCondition = false;
    this.dialogOutputOrder = false;
  }

  //検索
  async resultshowClick() {

    this.isLoading = true;

    //入力チェック
    if (!await this.Check()) {
      this.isLoading = false;
      return;
    }

    console.log("this.outputDataParamsXXXXXXXXXXXXXX");
    console.log(this.outputDataParams);

    try {
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };

      // 担当paramsセット
      console.log("ExecuteSqlXXXXXXXXXXXXXX");
      apiparams.body.manipulate = "ExecuteSql";
      apiparams.body.sqlparam = this.outputDataParams;
      console.log(apiparams);
      this.rslt = await API.post("asmapigateway", "/asmOutputData", apiparams);
      console.log(this.rslt);
      
      const cntchk = this.rslt.length;
      
      const headersDef = [{ text: "", value: "", align: "left" , width: 200}];
      if(cntchk > 0){
        const tmprry = Object.keys(this.rslt[0]);
        const cnt = tmprry.length;
        this.headers = headersDef;
        for(let i = 0 ; i < cnt ; i++ ){
          console.log(i);
          const elmnt = tmprry[i];
          console.log(elmnt);
          if(i != 0){
            this.headers.push({ text: "", value: "", align: "left" , width: 200});
          }
          this.headers[i].text = elmnt;
          this.headers[i].value = elmnt;
        }
      }else{
        this.headers = headersDef;
        this.headers[0].text = "抽出なし";
      }
      console.log(this.headers);
      
      this.items = this.rslt;
      
    }
    catch (err) {
      console.log(err);
      this.errMessage('エラー','データが取得できません。設定を見直してください。','red');
      this.isLoading = false;
      return;
      
    }
    //検索条件を非表示・検索結果を表示
    this.isconditionshow = false;
    this.isresultshow = true;

    this.isLoading = false;
  }
  
  async outputCSV(){
    
    
    console.log(this.rslt.length);
    if(this.rslt.length === 0 ){
      await this.errMessage('エラー','抽出結果がないためファイルを作成できません。','red');
      return;
    } else {
      await this.errMessage('確認','CSVファイルを作成します。','blue');
      this.isLoading = true;
    }
    
    // 抽出結果をCSVとして出力
    // 項目名の準備
    const fields = Object.keys(this.rslt[0]);
    // const jsontocsv = parse(csvtest,fields);
    const json2csvParser = new Parser({ fields,withBOM: true });
    const list = json2csvParser.parse(this.rslt);
    console.log("jsontocsvxxxxxxxxxxxxx");
    
    const blob = new Blob([list],{type:"text/csv"});
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    const findnm = this.outputDataParams.table;
    
    const findtables = this.outputDataSelectList.tableList.filter(function(item, index){
      if (item.value != '') return true;
    });
    const csvnm = findtables.find(e => e.value === findnm);
    let shtNm;
    if(csvnm!== undefined){
      shtNm = csvnm.text;
    } else {
      shtNm = "EUC出力"
    }
    link.download = shtNm + '.csv';
    
    this.isLoading = false;
    
    link.click();
    
  }


  //検索
  async conditionshow() {

    this.isLoading = true;

    //入力チェック
    // if (!await this.Check()) {
    //   this.isLoading = false;
    //   return;
    // }

    //検索条件を非表示・検索結果を表示
    this.isconditionshow = true;
    this.isresultshow = false;

    this.isLoading = false;
  }
  
  //初期化
  async paramClear() {
    
    // this.outputDataSelectList.tableList = [];
    this.outputDataSelectList.selectColums = [];
    // this.outputDataSelectList.whereConditions = [];
    // this.outputDataSelectList.whereAndors = [];
    // this.outputDataSelectList.orderSorts = [];

    //初期化
    // 参照
    this.outputDataParams.table = "";
    // 抽出項目
    this.outputDataParams.selectColumn = [{text: '',value:''}];
    // 抽出条件1
    this.outputDataParams.whereColumn1 = "";
    // 抽出条件2
    this.outputDataParams.whereColumn2 = "";
    // 抽出条件3
    this.outputDataParams.whereColumn3 = "";
    // 抽出条件4
    this.outputDataParams.whereColumn4 = "";
    // 抽出条件5
    this.outputDataParams.whereColumn5 = "";
    // 抽出コード値1
    this.outputDataParams.whereValue1 = "";
    // 抽出コード値2
    this.outputDataParams.whereValue2 = "";
    // 抽出コード値3
    this.outputDataParams.whereValue3 = "";
    // 抽出コード値4
    this.outputDataParams.whereValue4 = "";
    // 抽出コード値5
    this.outputDataParams.whereValue5 = "";
    // 抽出条件右1
    this.outputDataParams.whereCondition1 = "";
    // 抽出条件右2
    this.outputDataParams.whereCondition2 = "";
    // 抽出条件右3
    this.outputDataParams.whereCondition3 = "";
    // 抽出条件右4
    this.outputDataParams.whereCondition4 = "";
    // 抽出条件右5
    this.outputDataParams.whereCondition5 = "";
    // 抽出条件AndOr1
    this.outputDataParams.whereAndor1 = "";
    // 抽出条件AndOr2
    this.outputDataParams.whereAndor2 = "";
    // 抽出条件AndOr3
    this.outputDataParams.whereAndor3 = "";
    // 抽出条件AndOr4
    this.outputDataParams.whereAndor4 = "";
    // 抽出条件AndOr5
    this.outputDataParams.whereAndor5 = "";
    // 抽出順項目1
    this.outputDataParams.orderColumn1 = "";
    // 抽出順項目2
    this.outputDataParams.orderColumn2 = "";
    // 抽出順項目3
    this.outputDataParams.orderColumn3 = "";
    // 抽出順項目4
    this.outputDataParams.orderColumn4 = "";
    // 抽出順項目5
    this.outputDataParams.orderColumn5 = "";
    // 出力順1
    this.outputDataParams.orderSort1 = "";
    // 出力順2
    this.outputDataParams.orderSort2 = "";
    // 出力順3
    this.outputDataParams.orderSort3 = "";
    // 出力順4
    this.outputDataParams.orderSort4 = "";
    // 出力順5
    this.outputDataParams.orderSort5 = "";

  }

  // 入力チェック
  async Check(): Promise < boolean > {

    //抽出実行の場合

    //参照元が空欄の場合
    if(this.outputDataParams.table === "") {
      await this.refs.confirm.open(
        "エラー",
        "参照元を選択してください。", {
          type: "alert",
          color: "red",
          width: 500
        }
      );
      return false;
    }
    return true;
  }
  
  async errMessage(ttl: string,str: string,clr: string){

    await this.refs.confirm.open(
      ttl,
      str, 
      {
        type: "alert",
        color: clr,
        width: 500
      }
    );
    return;
  }
  
  
}
