























































































import Vue from "vue";
import { Component, Prop} from "vue-property-decorator";
import StringBox from "@/components/controls/StringBox.vue";
import { IManegmentParams } from "@/types/IManegmentParams";
import ConfirmEx from "@/components/ConfirmEx.vue";

@Component({
  components: {
    StringBox,
    ConfirmEx,
  },
})
export default class ParamGengoEdit extends Vue {

  // パラメータ
  @Prop() gengoParam!: IManegmentParams;
  @Prop() mainRequired!: boolean;
  @Prop() title!: string;
  @Prop() color!: string;
  
  // タブタイプ
  tabType = "GENGO";
  
  // 活性状態初期
  nmercdReadonly = false;
  strtymdtReadonly = false;
  endymdtReadonly = false;
  nmeralphReadonly = false;
  nmershtnReadonly = false;
  nmerReadonly = false;
  
  // 活性状態初期
  get refs(): any {
    return this.$refs;
  }

  // 画面ロード
  async created() {
    
    console.log("ParamGengoEdit：created");
    await this.initInfo();
  }
  
  async initInfo(){
    
    console.log("start:ParamGengoEdit_initInfo");
    // 活性状態制御
    if (this.color === "pink"){
      // 登録ダイアログ
      this.nmercdReadonly = false;
      this.strtymdtReadonly = false;
      this.endymdtReadonly = false;
      this.nmeralphReadonly = false;
      this.nmershtnReadonly = false;
      this.nmerReadonly = false;
    }
    else if(this.color === "light-blue"){
      // 変更ダイアログ
      this.nmercdReadonly = true;
      this.strtymdtReadonly = false;
      this.endymdtReadonly = false;
      this.nmeralphReadonly = false;
      this.nmershtnReadonly = false;
      this.nmerReadonly = false;
    }
    console.log("end:ParamGengoEdit_initInfo");
  }

  /**
   * 入力された値を返す
   */
  get localGengo(): IManegmentParams {

    return this.gengoParam;
  }

  // 登録キャンセルイベント
  async clickCancel(): Promise<void> {
    
    if (
      !(await this.refs.confirm.open("確認", "未登録の内容変更がある場合、情報が破棄されます。よろしいですか？", {
        width: 500,
      }))
    ) {
      return;
    }
    console.log("ParamGengoEdit：clickCancel()");
    this.$emit("dialog-cancel", this.tabType);
  }
  
  // 登録イベント
  clickOk() {
    this.$emit("dialog-ok", this.localGengo, this.tabType, this.color);
  }
}
