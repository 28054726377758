

































































import Vue from "vue";
import { Component, Prop} from "vue-property-decorator";
import StringBox from "@/components/controls/StringBox.vue";
import { IManegmentParams } from "@/types/IManegmentParams";
import { IUserParamsSelectList } from "@/types/IUserParamsSelectList";
import DropDownList from "@/components/controls/DropDownList.vue";
import ConfirmEx from "@/components/ConfirmEx.vue";

@Component({
  components: {
    StringBox,
    DropDownList,
    ConfirmEx,
  },
})
export default class ParamUserEdit extends Vue {

  // パラメータ
  @Prop() userParam!: IManegmentParams;
  @Prop() selectList!: IUserParamsSelectList;
  @Prop() mainRequired!: boolean;
  @Prop() title!: string;
  @Prop() color!: string;
  
  // タブタイプ
  tabType = "USER";
  
  // 活性状態初期
  usercdReadonly = false;
  chrgdepcdReadonly = false;
  usernmReadonly = false;
  pswdReadonly = false;
  
  // 活性状態初期
  get refs(): any {
    return this.$refs;
  }

  // 画面ロード
  async created() {
    
    console.log("ParamUserEdit：created");
    await this.initInfo();
  }
  
  async initInfo(){
    
    console.log("start:ParamUserEdit_initInfo");
    // 活性状態制御
    if (this.color === "pink"){
      // 登録ダイアログ
      this.usercdReadonly = false;
      this.chrgdepcdReadonly = false;
      this.usernmReadonly = false;
      this.pswdReadonly = false;
    }
    else if(this.color === "light-blue"){
      // 変更ダイアログ
      console.log("getTabtype：U");
      this.usercdReadonly = true;
      this.chrgdepcdReadonly = true;
      this.usernmReadonly = false;
      this.pswdReadonly = false;
    }
    console.log("end:ParamUserEdit_initInfo");
  }

  /**
   * 入力された値を返す
   */
  get localUser(): IManegmentParams {

    return this.userParam;
  }

  // 登録キャンセルイベント
  async clickCancel(): Promise<void> {
    
    if (
      !(await this.refs.confirm.open("確認", "未登録の内容変更がある場合、情報が破棄されます。よろしいですか？", {
        width: 500,
      }))
    ) {
      return;
    }
    console.log("ParamUserEdit：clickCancel()");
    this.$emit("dialog-cancel", this.tabType);
  }
  
  // 登録イベント
  clickOk() {
    this.$emit("dialog-ok", this.localUser, this.tabType, this.color);
  }
}
