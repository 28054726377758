

















































import Vue from "vue";
import { Component, Prop} from "vue-property-decorator";
import StringBox from "@/components/controls/StringBox.vue";
import { IManegmentParams } from "@/types/IManegmentParams";
import ConfirmEx from "@/components/ConfirmEx.vue";

@Component({
  components: {
    StringBox,
    ConfirmEx,
  },
})
export default class ParamNendoEdit extends Vue {

  // パラメータ
  @Prop() nendoParam!: IManegmentParams;
  @Prop() mainRequired!: boolean;
  @Prop() title!: string;
  @Prop() color!: string;
  
  // タブタイプ
  tabType = "NENDO";
  
  // 活性状態初期
  tyrReadonly = false;
  dsplytyrReadonly = false;
  ltsttyrsgnReadonly = false;
  
  /**
   * refsの定義
   */
  get refs(): any {
    
    return this.$refs;
  }

  // 画面ロード
  async created() {
    
    console.log("ParamNendoEdit：created");
    await this.initInfo();
  }
  
  initInfo(){
    
    console.log("start:ParamNendoEdit_initInfo");
    // 活性状態制御
    if (this.color === "pink"){
      // 登録ダイアログ
      this.tyrReadonly = false;
      this.dsplytyrReadonly = false;
      this.ltsttyrsgnReadonly = false;
    }
    else if(this.color === "light-blue"){
      // 変更ダイアログ
      this.tyrReadonly = true;
      this.dsplytyrReadonly = false;
      this.ltsttyrsgnReadonly = false;
    }
    console.log("end:ParamNendoEdit_initInfo");
  }

  /**
   * 入力された値を返す
   */
  get localNendo(): IManegmentParams {

    // 最新年度サインの制御、true = "1"とみなす
    if (this.nendoParam.ltsttyrsgn){
      console.log("nendoParam.ltsttyrsgn：true");
      this.nendoParam.ltsttyrsgn = "1";
    }
    else {
      console.log("nendoParam.ltsttyrsgn：false");
      this.nendoParam.ltsttyrsgn = null;
    }
    return this.nendoParam;
  }

  // 登録キャンセルイベント
  async clickCancel(): Promise<void> {
    
    if (
      !(await this.refs.confirm.open("確認", "未登録の内容変更がある場合、情報が破棄されます。よろしいですか？", {
        width: 500,
      }))
    ) {
      return;
    }
    console.log("ParamNendoEdit：clickCancel()");
    
    this.$emit("dialog-cancel", this.tabType);
  }
  
  // 登録イベント
  clickOk() {
    
    console.log("ParamNendoEdit：clickOk()");
    this.$emit("dialog-ok", this.localNendo, this.tabType, this.color);
  }
}
