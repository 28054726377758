










































import Vue from "vue";
import { Component, Prop} from "vue-property-decorator";
import StringBox from "@/components/controls/StringBox.vue";
import { IManegmentParams } from "@/types/IManegmentParams";
import ConfirmEx from "@/components/ConfirmEx.vue";

@Component({
  components: {
    StringBox,
    ConfirmEx,
  },
})
export default class ParamSaitakuEdit extends Vue {

  // パラメータ
  @Prop() saitakuParam!: IManegmentParams;
  @Prop() mainRequired!: boolean;
  @Prop() title!: string;
  @Prop() color!: string;

  
  // タブタイプ
  tabType = "SAITAKU";
  
  // 活性状態初期
  adptcdReadonly = false;
  adptnmReadonly = false;
  
  // refsの定義
  get refs(): any {
    
    return this.$refs;
  }

  // 画面ロード
  async created() {
    
    console.log("ParamSaitakuEdit：created");
    await this.initInfo();
  }
  
  async initInfo(){
    
    console.log("start:ParamSaitakuEdit_initInfo");
    // 活性状態制御
    if (this.color === "pink"){
      // 新規登録
      this.adptcdReadonly = false;
      this.adptnmReadonly = false;
    }
    else if(this.color === "light-blue"){
      // 更新
      this.adptcdReadonly = true;
      this.adptnmReadonly = false;
    }
    console.log("end:ParamSaitakuEdit_initInfo");
  }

  /**
   * 入力された値を返す
   */
  get localSaitaku(): IManegmentParams {

    return this.saitakuParam;
  }

  // 登録キャンセルイベント
  async clickCancel(): Promise<void> {
    
    if (
      !(await this.refs.confirm.open("確認", "未登録の内容変更がある場合、情報が破棄されます。よろしいですか？", {
        width: 500,
      }))
    ) {
      return;
    }
    console.log("ParamSaitakuEdit：clickCancel()");
    this.$emit("dialog-cancel", this.tabType);
  }
  
  // 登録イベント
  clickOk() {
    
    console.log("ParamSaitakuEdit：clickOk()");
    this.$emit("dialog-ok", this.localSaitaku, this.tabType, this.color);
  }
}
