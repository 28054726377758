















































































































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import axios from "axios";
import { ITextValue } from "@/types/ITextValue";
import { ISearchParams } from "@/types/ISearchParams";
import { IHeader } from "@/types/IHeader";
import { IMainSelectList } from "@/types/IMainSelectList";
import { IFusenSelectList } from "@/types/IFusenSelectList";
import { IMainParams } from "@/types/IMainParams";
import { IMainDisabled } from "@/types/IMainDisabled";
import DropDownList from "@/components/controls/DropDownList.vue";
import StringBox from "@/components/controls/StringBox.vue";
import DateJaBox from "@/components/controls/DateJaBox.vue";
import MultiSelectBox from "@/components/controls/MultiSelectBox.vue";
import ConfirmEx from "@/components/ConfirmEx.vue";
import CustomLoading from "@/components/CustomLoading.vue";
import MainPageEdit from "@/components/MainPageEdit.vue";
import { IGengo } from "@/types/IGengo";
import AsmVue from "@/views/AsmVue";
import { Storage,API, graphqlOperation, Auth } from 'aws-amplify';



@Component({
 components: {
   DropDownList,
   StringBox,
   DateJaBox,
   MultiSelectBox,
   ConfirmEx,
   CustomLoading,
   MainPageEdit }
})

export default class MainPage extends AsmVue {

  // 一覧画面 ***********************************
  
  //画面制御用
  isconditionshow = true;
  isresultshow = false;
  dialog = false;
  //date!: string;
  ischrgdepcddisabled = false;
  isLoading = false;
  latestyear: any;
  
  //暫定
  loginchrgdepcd!: string;
  loginchrgdepcdauth!: string;
  fusen!: string;
  maxSEQ!: string;
  
  // 検索と履歴更新検索を把握する変数
  // 検索：L,更新履歴検索：H
  srchFlg!: string;
 
   //初期化
  //V-model
  searchParams: ISearchParams= {
    tyrfrom:"", 
    tyrto:"", 
    admstnb:"", 
    chrgdepcd:"", 
    admar:"",
    rprsnone:"",
    pttntitle:"",
    pttncont:"",
    prgrssapprccdfrom:"",
    prgrssapprccdto:"",
    kndscd:"",
    accptplcd:"",
    accptymdtfrom:"", 
    accptymdtto:"", 
    cptymdtfrom:"",
    cptymdtto:"", 
    answerymdtfrom:"", 
    answerymdtto:"" 
    };
  
  //v-select用
  selectList: IMainSelectList={
    tyritems:  [],
    cndtcditems:  [],
    chrgdepcditems:  [],
    prgrssapprccditems:  [],
    kndscditems:  [],
    accptplcditems:  [],
    answermthdcditems:  [],
    adptcditems:  [],
    gengos: [],
    reditems:  [],
    yellowitems:  [],
    blueitems:  [],
    orangeitems:  [],
    purpleitems:  [],
  }

  fusenselectList: IFusenSelectList={
    reditems:  [],
    yellowitems:  [],
    blueitems:  [],
    orangeitems:  [],
    purpleitems:  []
  }
    
  //v-data-table
  headers!: IHeader[];
  items!: IMainParams [];



  // 編集画面 ***********************************
  title!: string;
  color!: string;

  mainParams: IMainParams = {
    tyr: "",
    admstnb: "",
    cndtcd: "",
    accptymdt: "",
    accptnb: "",
    accptplcd: "",
    admar: "",
    rprsnone: "",
    pstnm: "",
    pttntitle: "",
    pttncont: "",
    adptcd: "",
    kndscd: "",
    chrgdepcd: [],
    estmtsep: 0,
    whlep: 0,
    actvtep: 0,
    prgrssrate: 0,
    prgrssapprccd: "",
    crrpdymdt: "",
    abandymdt: "",
    worktyp: "",
    cptymdt: "",
    answerymdt: "",
    answermthdcd: "",
    answerdys: "",
    chrgrmrks: "",
    othsrmrks: "",
    ttlgotsdflg: "",
    supdtdttm: "",
    supdtdep: "",
    supdtnm: "",
    SEQ: "",
    updtst: "",
    tgredcd: "",
    tgredname: "",
    tgyllwcd: "",
    tgyllwname: "",
    tgblucd: "",
    tgbluname: "",
    tgorngcd: "",
    tgorngname: "",
    tgprplcd: "",
    tgprplname: "",
    tgredsplmnsn: "",
    updateflg: ""
  };

  mainDisabled: IMainDisabled ={
    tyrDisabled: false,
    admstnbDisabled: false,
    cndtcdDisabled: false,
    accptymdtDisabled: false,
    accptnbDisabled: false,
    accptplcdDisabled: false,
    admarDisabled: false,
    rprsnoneDisabled: false,
    pstnmDisabled: false,
    pttntitleDisabled: false,
    pttncontDisabled: false,
    adptcdDisabled: false,
    kndscdDisabled: false,
    chrgdepcdDisabled: false,
    estmtsepDisabled: false,
    whlepDisabled: false,
    actvtepDisabled: false,
    prgrssrateDisabled: false,
    prgrssapprccdDisabled: false,
    crrpdymdtDisabled: false,
    abandymdtDisabled: false,
    worktypDisabled: false,
    cptymdtDisabled: false,
    answerymdtDisabled: false,
    answermthdcdDisabled: false,
    answerdysDisabled: false,
    chrgrmrksDisabled: false,
    othsrmrksDisabled: false,
    ttlgotsdflgDisabled: false,
    tagBtnDisabled: false,
    tgredcdDisabled: false,
    tgyllwcdDisabled: false,
    tgblucdDisabled: false,
    tgorngcdDisabled: false,
    tgprplcdDisabled: false,
    imageBtnDisabled: false,
    mapBtnDisabled: false,
    CopyBtnDisabled: false,
    HistoryBtnDisabled: false,
    delBtnisabled: false,
    cancelBtnDisabled: false,
    saveBtnDisabled: false,
  }
  mainRequired= true;
  chrgdepcdRequired= true;


 // ********************************************

   get refs(): any {
    return this.$refs;
  }

  // *********************************************
  // 一覧画面
  // *********************************************

  // 画面ロード
  async created() {
    if(!(this.$store.state.loginuser.loginusercd.length > 0)){
      await alert("ログイン情報が取得できません。再度ログインを実施してください。");
      window.location.assign(process.env.BASE_URL||"");
    }
    
    // window.addEventListener('beforeunload', (event) => {
      
    //   alert('123');
    //   console.log("window.addEventListener('beforeunload', (event) =>");
    //   event.preventDefault();
    //   event.returnValue = '';
    //   alert('123');
      
    // });
    // window.addEventListener('beforeunload', this.removepage);

  }
  // async removepage(e: any){
  //   console.log('1234567890123456789012456789');
    
  //   e.preventDefault();
  //   e.returnValue = '';
  // } 
  
  
  async mounted(){
    //初期情報取得
    await this.initInfo();
    //検索条件初期設定
    this.searchinit()
  }

  //初期情報取得
  async initInfo(){
    this.isLoading = true;
    
    //情報取得参考
    /**
     * ここでAPIを呼び出し、データを下記項目の形式で取得する
     */
    try {
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: ""
        }
      };
      
      // paratest
      apiparams.body.manipulate = "SELECTPARA";
      this.selectList = await API.post("asmapigateway", "/asmparameter", apiparams);
      console.log(this.selectList);
      
    } catch (e) {
      this.exception(e);
      return;
    }

    
    // 付箋パラ
    this.fusenselectList.reditems = this.selectList.reditems;
    this.fusenselectList.yellowitems = this.selectList.yellowitems;
    this.fusenselectList.blueitems = this.selectList.blueitems;
    this.fusenselectList.orangeitems = this.selectList.orangeitems;
    this.fusenselectList.purpleitems = this.selectList.purpleitems;    
    
  }
    
  //検索条件初期設定
  async searchinit(){
    
    // 年度パラ 
    const result = this.selectList.tyritems.find(e => e.text2 === "1");
    if(result!== undefined){
      this.searchParams.tyrfrom = result.value;
      this.searchParams.tyrto = result.value;
      this.latestyear = result.value;
    }
    
    this.selectList.chrgdepcditems.shift();
    //担当課 ログインユーザーの担当課コードにより制御
    this.loginchrgdepcdauth = this.$store.state.loginuser.loginathryclsfy;
    this.loginchrgdepcd = this.$store.state.loginuser.loginchrgdepcd;
    if (this.loginchrgdepcdauth == "0"){
        this.searchParams.chrgdepcd = "";
        this.ischrgdepcddisabled = false;
    }
    else{
      this.searchParams.chrgdepcd = this.loginchrgdepcd;
      this.ischrgdepcddisabled = true;
    }
    
    //上記以外は空欄
    this.searchParams.admstnb = "";
    this.searchParams.cndtcd =  "" ;
    this.searchParams.admar = "";
    this.searchParams.rprsnone = "";
    this.searchParams.pttntitle = "";
    this.searchParams.pttncont = "";
    this.searchParams.prgrssapprccdfrom = "";
    this.searchParams.prgrssapprccdto = "";
    this.searchParams.kndscd = "";
    this.searchParams.accptplcd = "";
    this.searchParams.accptymdtfrom = "";
    this.searchParams.accptymdtto = "";
    this.searchParams.cptymdtfrom = "";
    this.searchParams.cptymdtto = "";
    this.searchParams.answerymdtfrom = "";
    this.searchParams.answerymdtto = "";
    
    this.isLoading = false;
    
    
    
  }

  //検索条件入力チェック
  async Check(flg: string): Promise<boolean> {

    //更新履歴検索の場合
    if(flg == "history"){

      //年度、管理番号が空欄の場合エラー
      if(this.searchParams.tyrfrom ==="" || this.searchParams.tyrto ==="" || this.searchParams.admstnb === ""){
        await this.refs.confirm.open(
            "エラー",
            "年度、管理番号を入力してください。", 
            {
              type: "alert",
              color: "red",
              width: 500
            }
          );
        return false;
      }
      //年度のfromとtoが異なる場合エラー
      if(this.searchParams.tyrfrom != this.searchParams.tyrto){
        await this.refs.confirm.open(
            "エラー",
            "年度は単年度を指定してください。", 
            {
              type: "alert",
              color: "red",
              width: 500
            }
          );
        return false;
      }
      
    }
    
    //管理番号半角数値以外エラー
      if (this.searchParams.admstnb != "" && this.searchParams.admstnb?.match(/[^0-9]/g)) {
          await this.refs.confirm.open(
            "エラー",
            "管理番号は半角数値で入力してください。", 
            {
              type: "alert",
              color: "red",
              width: 500
            }
          );
          return false;
      }

    //管理番号9桁以外エラー
    if(this.searchParams.admstnb != "" && this.searchParams.admstnb?.length != 9){
      await this.refs.confirm.open(
            "エラー",
            "管理番号は9桁で入力してください。", 
            {
              type: "alert",
              color: "red",
              width: 500
            }
          );
          return false;
    }

  return true;

  }

  //検索
  async search(){
    
    this.srchFlg = "L";
    this.isLoading = true;

    //入力チェック
    if(!await this.Check("search")){
      this.isLoading = false;
      return;
    }
    //検索処理
    /**
     * ここでAPIを呼び出し、データを検索処理を行う
     */
    try {
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
    
      // 検索
      apiparams.body.manipulate = "SelectSearch";
      apiparams.body.sqlparam = this.searchParams;
      console.log(apiparams);
      const rslt = await API.post("asmapigateway", "/asmMainPage", apiparams);

      // mast用
      this.headers = [
        {text: '更新処理', value: 'actions', align: "left", width:100 },
        {text: "年度", value: "tyr", align: "left", width:100 },
        {text: "管理番号",  value: "admstnb", align: "left", width:100 },
        {text: "要件区分", value: "cndtcd", align: "left", width:100  },
        {text: "受付年月日", value: "accptymdt", align: "left", width:120 },
        {text: "受付番号", value: "accptnb", align: "left", width:100 },
        {text: "受付場所", value: "accptplcd", align: "left" , width:180},
        {text: "行政区・団体", value: "admar", align: "left", width:180 },
        {text: "代表者", value: "rprsnone", align: "left", width:180 },
        {text: "肩書", value: "pstnm", align: "left", width:150 },
        {text: "件名", value: "pttntitle", align: "left", width:250 },
        {text: "内容", value: "pttncont", align: "left", width:250 },
        {text: "採択等", value: "adptcd", align: "left", width:100 },
        {text: "種別", value: "kndscd", align: "left", width:100 },
        {text: "担当課", value: "chrgdepcd", align: "left", width:100 },
        {text: "見積事業費", value: "estmtsep", align: "right", width:120 },
        {text: "全体事業費", value: "whlep", align: "right", width:120 },
        {text: "実績事業費", value: "actvtep", align: "right", width:120 },
        {text: "進捗率", value: "prgrssrate", align: "left", width:100 },
        {text: "進捗状況", value: "prgrssapprccd", align: "left", width:100 },
        {text: "着工年月日", value: "crrpdymdt", align: "left", width:120 },
        {text: "取下年月日", value: "abandymdt", align: "left", width:120 },
        {text: "工種別", value: "worktyp", align: "left", width:100 },
        {text: "完了年月日", value: "cptymdt", align: "left", width:120 },
        {text: "回答年月日", value: "answerymdt", align: "left", width:120 },
        {text: "回答方法", value: "answermthdcd", align: "left", width:100 },
        {text: "回答日数", value: "answerdys", align: "left", width:100 },
        {text: "備考（担当課用）", value: "chrgrmrks", align: "left", width:250 },
        {text: "備考（行政センター用）", value: "othsrmrks", align: "left", width:250 },
        {text: "集計対象外", value: "ttlgotsdflg", align: "left", width:120 },
        {text: "更新月日", value: "supdtdttm", align: "left", width:100 },
        {text: "更新課", value: "supdtdep", align: "left", width:150 },
        {text: "更新者", value: "supdtnm", align: "left", width:150 },
        ]
      
      
      
      
      this.items = rslt;
      console.log(this.items);
      
      this.isresultshow = false;
      
    } catch (e) {
      this.exception(e);
      return;
    }

    //検索条件を非表示・検索結果を表示
    this.isconditionshow = false;
    this.isresultshow = true;

    this.isLoading = false;

  }

  //更新履歴検索
  async historysearch(){
 
    this.srchFlg = "H";
    this.isLoading = true;

    //入力チェック
    if(!await this.Check("history")){
      this.isLoading = false;
      return;
    }

    //更新履歴検索処理
     /**
     * ここでAPIを呼び出し、データを検索処理を行う
     */
    try {
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
    
      // 検索
      apiparams.body.manipulate = "SelectRirekiSearch";
      apiparams.body.sqlparam = this.searchParams;
      console.log(apiparams);
      const rslt = await API.post("asmapigateway", "/asmMainPage", apiparams);

      //rireki用
      this.headers = [
        {text: '更新処理', value: 'actions', align: "left", width:100 },
        {text: "年度", value: "tyr", align: "left", width:100 },
        {text: "管理番号",  value: "admstnb", align: "left", width:100 },
        {text: "要件区分", value: "cndtcd", align: "left", width:100  },
        {text: "受付年月日", value: "accptymdt", align: "left", width:120 },
        {text: "受付番号", value: "accptnb", align: "left", width:100 },
        {text: "受付場所", value: "accptplcd", align: "left" , width:180},
        {text: "行政区・団体", value: "admar", align: "left", width:180 },
        {text: "代表者", value: "rprsnone", align: "left", width:180 },
        {text: "肩書", value: "pstnm", align: "left", width:150 },
        {text: "件名", value: "pttntitle", align: "left", width:250 },
        {text: "内容", value: "pttncont", align: "left", width:250 },
        {text: "採択等", value: "adptcd", align: "left", width:100 },
        {text: "種別", value: "kndscd", align: "left", width:100 },
        {text: "担当課", value: "chrgdepcd", align: "left", width:100 },
        {text: "見積事業費", value: "estmtsep", align: "right", width:120 },
        {text: "全体事業費", value: "whlep", align: "right", width:120 },
        {text: "実績事業費", value: "actvtep", align: "right", width:120 },
        {text: "進捗率", value: "prgrssrate", align: "left", width:100 },
        {text: "進捗状況", value: "prgrssapprccd", align: "left", width:100 },
        {text: "着工年月日", value: "crrpdymdt", align: "left", width:120 },
        {text: "取下年月日", value: "abandymdt", align: "left", width:120 },
        {text: "工種別", value: "worktyp", align: "left", width:100 },
        {text: "完了年月日", value: "cptymdt", align: "left", width:120 },
        {text: "回答年月日", value: "answerymdt", align: "left", width:120 },
        {text: "回答方法", value: "answermthdcd", align: "left", width:100 },
        {text: "回答日数", value: "answerdys", align: "left", width:100 },
        {text: "備考（担当課用）", value: "chrgrmrks", align: "left", width:250 },
        {text: "備考（行政センター用）", value: "othsrmrks", align: "left", width:250 },
        {text: "集計対象外", value: "ttlgotsdflg", align: "left", width:120 },
        {text: "更新月日", value: "supdtdttm", align: "left", width:100 },
        {text: "更新課", value: "supdtdep", align: "left", width:150 },
        {text: "更新者", value: "supdtnm", align: "left", width:150 },
        {text: "ＳＥＱ", value: "SEQ", align: "left", width:100 },
        {text: "更新状態", value: "updtst", align: "left", width:100 },
        ]
      this.items = rslt;
      
    } catch (e) {
      this.exception(e);
      return;
    }

   //検索条件を非表示・検索結果を表示
    this.isconditionshow = false;
    this.isresultshow = true;
    this.isLoading = false;

  }

  //検索条件表示
  conditionshow(){

    //検索条件を表示・検索結果を非表示
    this.isconditionshow = true;
    this.isresultshow = false;

  }

  //追加処理
  async addClick(): Promise<void> {
    this.isLoading = true;

    //タイトル
    this.title = "新規登録";

    //バー背景色
    this.color = "pink"
    // alert("バー背景色まで");

    //編集フラグセット
    this.mainParams.updateflg = "I"
    // alert("編集フラグセットまで");

    //最新年度セット
    this.mainParams.tyr= this.latestyear;

    // 活性状態設定
    this.mainDisabled = {
      tyrDisabled: false,
      admstnbDisabled: false,
      cndtcdDisabled: false,
      accptymdtDisabled: false,
      accptnbDisabled: false,
      accptplcdDisabled: false,
      admarDisabled: false,
      rprsnoneDisabled: false,
      pstnmDisabled: false,
      pttntitleDisabled: false,
      pttncontDisabled: false,
      adptcdDisabled: false,
      kndscdDisabled: false,
      chrgdepcdDisabled: false,
      estmtsepDisabled: false,
      whlepDisabled: false,
      actvtepDisabled: false,
      prgrssrateDisabled: false,
      prgrssapprccdDisabled: false,
      crrpdymdtDisabled: false,
      abandymdtDisabled: false,
      worktypDisabled: false,
      cptymdtDisabled: false,
      answerymdtDisabled: false,
      answermthdcdDisabled: false,
      answerdysDisabled: false,
      chrgrmrksDisabled: false,
      othsrmrksDisabled: false,
      ttlgotsdflgDisabled: false,
      tagBtnDisabled: false,
      tgredcdDisabled: false,
      tgyllwcdDisabled: false,
      tgblucdDisabled: false,
      tgorngcdDisabled: false,
      tgprplcdDisabled: false,
      imageBtnDisabled: false,
      mapBtnDisabled: false,
      CopyBtnDisabled: true,
      HistoryBtnDisabled: true,
      delBtnisabled: true,
      cancelBtnDisabled: false,
      saveBtnDisabled: false,
    }

    //担当課必須
    if(this.loginchrgdepcdauth ==="0" || this.loginchrgdepcdauth ==="1" ||this.loginchrgdepcdauth ==="2"){
      this.chrgdepcdRequired = true;
    }
    else{
      this.chrgdepcdRequired = false;
    }
    
    this.isLoading = false;

    // データ編集ダイアログ（追加）表示
    this.dialog = true;

  }

  //編集処理
  async editItem(item: any): Promise<void> {
    
    this.isLoading = true;
    //タイトル
    this.title = "修正・参照";

    //バー背景色
    this.color = "light-blue"

    // パラメータ取得
    const jsonData: string = JSON.stringify(item);
    const data: any = JSON.parse(jsonData);
    console.log(jsonData);
    console.log(data);
    data.srchFlg = this.srchFlg;
 
    //情報管理情報取得(パラメータ 年度,管理番号)
     /**
     * ここでAPIを呼び出し、該当データを取得する
     */
    try {
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
    
      // 検索
      apiparams.body.manipulate = "UpdateSlect";
      apiparams.body.sqlparam = data;
      console.log(apiparams);
      const rslt = await API.post("asmapigateway", "/asmMainPage", apiparams);
      // this.mainParams = res.data;

      console.log(rslt);
      this.mainParams = rslt[0];

    } catch (e) {
      this.exception(e);
      return;
    }

    // 編集フラグセット
    // this.mainParams.updateflg = "U"

    //同一管理番号の最大SEQ取得
     /**
     * ここでAPIを呼び出し、最大SEQを取得する
     */

    try {
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
      // 検索
      
      apiparams.body.manipulate = "UpdateSlectMaxSeq";
      apiparams.body.sqlparam = this.mainParams;
      console.log(apiparams);
      const rslt = await API.post("asmapigateway", "/asmMainPage", apiparams);
      // // 
      // this.mainParams = res.data;
      
    
      // MASTのみ作成されておりRIREKIが作成されていない場合は、
      // 新規登録時にタブ移動等を実施した可能性がある。
      // そのデータを参照しようとした場合はメッセージを表示し削除する。
      
      console.log('rsltxxxxxxxxxxxxxxxxx!!!!!!!!!!!!!!!')
      console.log(rslt);
      if(rslt[0].cnt === 0){
        if (
          !(await this.refs.confirm.open("確認", "選択したデータは登録が正しくされていないため展開が出来ません。削除してもよろしいですか。", {
            width: 500,
          }))
        ) {
          this.isLoading = false;
          return;
        }
        
      }

      

      this.maxSEQ = rslt[0].MaxSEQ;

      // this.maxSEQ = "00001"
    } catch (e) {
      this.exception(e);
      return;
    }

    //活性状態設定
    if (this.mainParams.SEQ === this.maxSEQ ){
      //編集フラグセット
      this.mainParams.updateflg = "U"

      this.mainDisabled ={
        tyrDisabled: true,
        admstnbDisabled: true,
        cndtcdDisabled: false,
        accptymdtDisabled: false,
        accptnbDisabled: false,
        accptplcdDisabled: false,
        admarDisabled: false,
        rprsnoneDisabled: false,
        pstnmDisabled: false,
        pttntitleDisabled: false,
        pttncontDisabled: false,
        adptcdDisabled: false,
        kndscdDisabled: false,
        chrgdepcdDisabled: false,
        estmtsepDisabled: false,
        whlepDisabled: false,
        actvtepDisabled: false,
        prgrssrateDisabled: false,
        prgrssapprccdDisabled: false,
        crrpdymdtDisabled: false,
        abandymdtDisabled: false,
        worktypDisabled: false,
        cptymdtDisabled: false,
        answerymdtDisabled: false,
        answermthdcdDisabled: false,
        answerdysDisabled: false,
        chrgrmrksDisabled: false,
        othsrmrksDisabled: false,
        ttlgotsdflgDisabled: false,
        tagBtnDisabled: false,
        tgredcdDisabled: false,
        tgyllwcdDisabled: false,
        tgblucdDisabled: false,
        tgorngcdDisabled: false,
        tgprplcdDisabled: false,
        imageBtnDisabled: false,
        mapBtnDisabled: false,
        CopyBtnDisabled: false,
        HistoryBtnDisabled: false,
        delBtnisabled: false,
        cancelBtnDisabled: false,
        saveBtnDisabled: false,
      }
    }
    else{

        //編集フラグセット
        this.mainParams.updateflg = "S"

        this.mainDisabled ={
        tyrDisabled: true,
        admstnbDisabled: true,
        cndtcdDisabled: true,
        accptymdtDisabled: true,
        accptnbDisabled: true,
        accptplcdDisabled: true,
        admarDisabled: true,
        rprsnoneDisabled: true,
        pstnmDisabled: true,
        pttntitleDisabled: true,
        pttncontDisabled: true,
        adptcdDisabled: true,
        kndscdDisabled: true,
        chrgdepcdDisabled: true,
        estmtsepDisabled: true,
        whlepDisabled: true,
        actvtepDisabled: true,
        prgrssrateDisabled: true,
        prgrssapprccdDisabled: true,
        crrpdymdtDisabled: true,
        abandymdtDisabled: true,
        worktypDisabled: true,
        cptymdtDisabled: true,
        answerymdtDisabled: true,
        answermthdcdDisabled: true,
        answerdysDisabled: true,
        chrgrmrksDisabled: true,
        othsrmrksDisabled: true,
        ttlgotsdflgDisabled: true,
        tagBtnDisabled: true,
        tgredcdDisabled: false,
        tgyllwcdDisabled: false,
        tgblucdDisabled: false,
        tgorngcdDisabled: false,
        tgprplcdDisabled: false,
        imageBtnDisabled: true,
        mapBtnDisabled: true,
        CopyBtnDisabled: true,
        HistoryBtnDisabled: false,
        delBtnisabled: true,
        cancelBtnDisabled: false,
        saveBtnDisabled: true,
      }
    }

    //担当課必須
    if(this.loginchrgdepcdauth ==="0" || this.loginchrgdepcdauth ==="1" ||this.loginchrgdepcdauth ==="2"){
      this.chrgdepcdRequired = true;
    }
    else{
      this.chrgdepcdRequired = false;
    }

    //付箋表示
    if(this.mainParams.tgredsplmnsn !="" && this.mainParams.tgredsplmnsn != undefined){
       await this.refs.confirm.open(
            "付箋特記事項",
            this.mainParams.tgredsplmnsn, 
            {
              type: "alert",
              width: 500
            }
          );
    }

    // 付箋活性状態設定
    if(this.mainParams.tgredcd!="" && this.mainParams.tgredcd != undefined){ 
      this.mainDisabled.tgredcdDisabled = true;
    }
    if(this.mainParams.tgyllwcd!="" && this.mainParams.tgredcd != undefined){ 
      this.mainDisabled.tgyllwcdDisabled = true;
    }
    if(this.mainParams.tgblucd!="" && this.mainParams.tgredcd != undefined){ 
      this.mainDisabled.tgblucdDisabled = true;
    }
    if(this.mainParams.tgorngcd!="" && this.mainParams.tgredcd != undefined){ 
      this.mainDisabled.tgorngcdDisabled = true;
    }
    if(this.mainParams.tgprplcd!="" && this.mainParams.tgredcd != undefined){ 
      this.mainDisabled.tgprplcdDisabled = true;
    }
    
    this.isLoading = false;
    
    
    this.isresultshow = false;

    // データ編集ダイアログ（編集）表示
    this.dialog = true;
  }

 // *********************************************
  // 編集画面
  // *********************************************

   //入力チェック
  async Checkadmstnb(): Promise<boolean> {

    //年度が空欄の場合エラー
      if(this.mainParams.tyr ==="" ){
        await this.refs.confirm.open(
            "エラー",
            "年度を入力してください。", 
            {
              type: "alert",
              color: "red",
              width: 500
            }
          );
        return false;
      }

  return true;

  }
  
  //管理番号取得
  async clickadmstnb() {

    //入力チェック
    if(!await this.Checkadmstnb()){
      return;
    }
    this.isLoading = true;

    //履歴マスタ 指定した年度の管理番号取得（パラメータ：年度）
     /**
     * ここでAPIを呼び出し、管理番号を取得する
     */
    try {

      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
      apiparams.body.manipulate = "SelectMaxMstnb";
      apiparams.body.sqlparam = this.mainParams;
      console.log(apiparams);
      const rslt = await API.post("asmapigateway", "/asmMainPage", apiparams);
      console.log("rsltxxxxxxxxxxxxxxxxxxxxxxxxxx");
      console.log(rslt);
      this.mainParams.admstnb = rslt[0].maxadmstnb;
      
    } catch (e) {
      this.exception(e);
      return;
    }

    this.isLoading = false;

  }

  //キャンセル
  async clickCancel(Params: IMainParams): Promise<void> {

    //新規処理の場合
    if(Params.updateflg==="I"){

      //基本、履歴に存在しない場合、付箋、画像マスタを削除
      /**
      * ここでAPIを呼び出し、付箋、画像マスタ削除処理を行う 引数は「Params」
      */
      try {
    
        //   const result: any = await axios.post(
        //     `https://0l1dlxeyz1.execute-api.ap-northeast-1.amazonaws.com/xxxxxxx`,
        //     {
        //       key1:this.mainParams
        //       
        //     })
    
    } catch (e) {
      this.exception(e);
      return;
    }
  }

  //ダイアログクローズ処理
  this.closemain();

  if(Params.updateflg==="U"){
    this.isconditionshow = true;
  }



  }

  // 削除
  async clickdelete(Params: IMainParams): Promise<void> {

    this.isLoading = true;
    
    // 削除処理
    /**
     * ここでAPIを呼び出し、削除処理を行う 引数は「Params」
     */
    try {
      const apiparams = {
        body: {
          manipulate: "",
          modeflg: "",
          sqlparam: {}
        }
      };
      apiparams.body.sqlparam = Params;
      apiparams.body.modeflg = 'A';
      apiparams.body.manipulate = "SELECTIMAGE";
      const deleteinfo = await API.post("asmapigateway", "/asmMainPage", apiparams);
      
      console.log("deleteinfoxxxxxxxxxxxxxxxxxxx");
      console.log(deleteinfo);
      const cnt = deleteinfo.length;
      for(let i = 0; i < cnt; i++){
        if(deleteinfo[i].image !== undefined){
          const originalpath = this.mainParams.admstnb + '/' +deleteinfo[i].image;
          console.log(i + originalpath);
          Storage.remove(originalpath)
          .then(result => {console.log("imagedelete");
          }).catch(err => console.log(err));
          
          const thumbnailpath = this.mainParams.admstnb + '/' + 'thumbnail/' +deleteinfo[i].image;
          Storage.remove(thumbnailpath)
          .then(result => {console.log("thumbnaildelete");
          }).catch(err => console.log(err));
        }
      }
      const dirpath = this.mainParams.admstnb;
      Storage.remove(String(dirpath))
      .then(result => {console.log("imagedelete");
      }).catch(err => console.log(err));
      

      apiparams.body.manipulate = "DeleteMast";
      console.log('DeleteMast!!!!!!!!!!!!!!!!');
      console.log(apiparams);
      const rslt = await API.post("asmapigateway", "/asmMainPage", apiparams);
    
    } catch (e) {
      this.exception(e);
      return;
    }


    // 削除完了
    await this.refs.confirm.open(
            "完了",
            "削除が完了しました。", 
            {
              type: "alert",
              width: 500
            }
          );

    //ダイアログクローズ処理
    this.closemain();
    
    this.conditionshow();

    //検索処理
    this.search()

    this.isLoading = false;
 
  }

  //登録
  async clickSave(Params: IMainParams): Promise<void> {
    
    this.isLoading = true; 

    //登録内容がが渡ってきたか確認用
    // alert(JSON.stringify(Params))

    // 登録処理
     /**
     * ここでAPIを呼び出し、登録処理を行う引数は「Params」
     */
    //mainParams.updateflgにてサービス側で新規か修正を判断する
   
    try {
      Params.supdtdep = this.$store.state.loginuser.loginchrgdepcd;
      Params.supdtnm = this.$store.state.loginuser.loginusercd;
      
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
      apiparams.body.manipulate = "InsertMast";
      apiparams.body.sqlparam = Params;
      console.log(apiparams);
      await API.post("asmapigateway", "/asmMainPage", apiparams);
      
      } catch (e) {
        this.exception(e);
        return;
      }

    // 登録完了
    await this.refs.confirm.open(
            "完了",
            "登録が完了しました。", 
            {
              type: "alert",
              width: 500
            }
          );

    //ダイアログクローズ処理
    this.closemain();

    //新規登録の場合
    if(this.mainParams.updateflg ==="I"){
      
      //検索条件クリア
      this.searchinit()

      //検索条件に新規登録した年度、管理番号をセット
      this.searchParams.tyrfrom= this.mainParams.tyr
      this.searchParams.tyrto= this.mainParams.tyr
      this.searchParams.admstnb= this.mainParams.admstnb
    } 

    //検索処理
    this.search();

    this.isLoading = false;
  }

  //ダイアログクローズ処理
  closemain() {
    this.dialog = false;
    this.$nextTick(() => {
      this.mainParams = Object.assign(
        {},
        { tyr: "",
          admstnb: "",
          cndtcd: "",
          accptymdt: "",
          accptnb: "",
          accptplcd: "",
          admar: "",
          rprsnone: "",
          pstnm: "",
          pttntitle: "",
          pttncont: "",
          adptcd: "",
          kndscd: "",
          chrgdepcd: [],
          estmtsep: 0,
          whlep: 0,
          actvtep: 0,
          prgrssrate: 0,
          prgrssapprccd: "",
          crrpdymdt: "",
          abandymdt: "",
          worktyp: "",
          cptymdt: "",
          answerymdt: "",
          answermthdcd: "",
          answerdys: "",
          chrgrmrks: "",
          othsrmrks: "",
          ttlgotsdflg: "",
          supdtdttm: "",
          supdtdep: "",
          supdtnm: "",
          SEQ: "",
          updtst: "",
          tgredcd: "",
          tgredname: "",
          tgyllwcd: "",
          tgyllwname: "",
          tgblucd: "",
          tgbluname: "",
          tgorngcd: "",
          tgorngname: "",
          tgprplcd: "",
          tgprplname: "",
          updateflg: "" }
      );
    });

  }

   /**
   * ライフサイクルフック（Updated）
   */
  updated() {
    // ダイアログスクロール位置リセット対応
    const elements = document.getElementsByClassName("v-dialog--active");
    if (!elements || !elements.length) {
      return;
    }
    elements[0].scrollTop = 0;
  }
}



